import { api } from '@/services/interceptors';
import { ElMessage } from 'element-plus';
import i18n from '../i18n';

export default {
  state: {
    reviews: [],
    userReviewsInfos: null,
    profil: null
  },
  getters: {
    getReviews(state) {
      return state.reviews;
    },
    getUserReviewsInfos(state) {
      return state.userReviewsInfos;
    },
    getUserReviewsProfil(state) {
      return state.profil;
    }
  },
  actions: {
    reviews({ commit }) {
      return new Promise((resolve) => {
        api.get('api/auth/reviews').then((res) => {
            commit('STORAGE_REVIEWS', res.data.reviews);
            commit('STORAGE_USER_INFOS_REVIEWS', res.data.reviews_infos);
            resolve();
        });
      })
    },
    createReview({ commit }, payload) {
        commit('STORE_NOTHING');
        return new Promise((resolve, reject) => {
            api.post('api/auth/reviews', payload).then(() => {
                resolve();
            }).catch((err) => {
              if (err.response.data.pseudo_not_available) {
                ElMessage({
                  message: i18n.global.t('settings.pseudo_already_used'),
                  type: 'warning',
                  grouping: true
                });
              } else {
                ElMessage({
                  message: i18n.global.t('settings.catch_update_account'),
                  type: 'warning',
                  grouping: true
                })
              }
              reject();
            })
        })
    },
    likeReview({ commit }, payload) {
      commit('STORE_NOTHING');
      return new Promise((resolve, reject) => {
          api.post('/api/auth/reviews/like/set', payload).then(() => {
              resolve();
          }).catch(() => {
              reject();
          })
      })
  },
    updateReview({ commit, dispatch }, payload) {
        commit('STORE_NOTHING');
        return new Promise((resolve) => {
            api.put('api/auth/reviews', payload).then(() => {
              dispatch('reviews');
              resolve();
            });
        })
      },
    deleteReview({ commit, dispatch }, payload) {
      commit('STORE_NOTHING');
      api.delete('api/auth/reviews/' + payload).then(() => {
        dispatch('reviews');
      });
    },
    reviewProfil({ commit }, payload) {
      return new Promise((resolve) => {
        api.post('api/auth/reviews/profil', payload).then((res) => {
          commit('STORAGE_USER_REVIEWS_PROFIL', res.data.reviews_profil);
          resolve();
        });
      })
    },
  },
  mutations: {
    STORAGE_REVIEWS(currentState, reviews) {
      currentState.reviews = reviews;
    },
    STORAGE_USER_INFOS_REVIEWS(currentState, reviews) {
      currentState.userReviewsInfos = reviews;
    },
    STORAGE_USER_REVIEWS_PROFIL(currentState, profil) {
      currentState.profil = profil;
    },
    STORE_NOTHING() { }
  }
};