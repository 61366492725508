export default {
  "commons": {
    "refuse_match_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odmítnout shodu"])},
    "date_expire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum vypršení platnosti"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
    "buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nákup a prodej"])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výměna"])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocktrade"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Země"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
    "bid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nabídka"])},
    "ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeptejte se na"])},
    "giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dárce"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taker"])},
    "report_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahlásit chybu"])},
    "account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ účtu"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objednávky"])},
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuální objednávky"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Množství"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpět na"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zavřít"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovit"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Použít"])},
    "product_origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Původ produktu"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovat na"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrďte"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připojení"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odhlásit se"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoření účtu"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhledávání a obchodování"])},
    "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Předplatné"])},
    "price_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena je uvedena včetně DPH"])},
    "dark_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noční režim"])},
    "light_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přehledný režim"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historie transakcí"])},
    "portfolio_gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "matchs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zápasy"])},
    "transactions_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historie transakcí"])},
    "account_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení účtu"])},
    "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivováno"])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktivováno"])},
    "alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznámení"])},
    "alerts_clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazat vše"])},
    "alerts_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prozatím bez oznámení"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte si"])},
    "no_copy_paste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nelze kliknout pravým tlačítkem myši"])},
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodání"])},
    "secure3d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrola transakce"])},
    "cancel_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušit"])},
    "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DPH"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkem"])},
    "too_many_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadáváte příliš mnoho požadavků"])},
    "see_products_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dosáhli jste denního limitu pro konzultace. Zkontrolujte svůj účet, abyste již nebyli omezeni."])},
    "check_inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkontrolujte si svůj vstup:"])},
    "generic_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba, zkuste to později"])},
    "login_retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znovu se přihlaste"])},
    "email_phone_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Před provedením této akce ověřte svůj e-mail a telefonní číslo"])},
    "not_authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nejste oprávněni provádět tuto akci"])},
    "service_unaivalable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Služba není k dispozici, kontaktujte prosím zákaznický servis"])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informační kanál"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivní"])},
    "inputs_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkontrolujte, zda jsou správně vyplněna všechna požadovaná pole"])},
    "lang": {
      "lang_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte si jazyk"])},
      "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR"])},
      "fr_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francouzský"])},
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CS"])},
      "en_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglicky"])},
      "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ES"])},
      "es_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Španělská stránka"])},
      "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT"])},
      "it_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italské stránky"])},
      "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PL"])},
      "pl_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polské stránky"])},
      "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DE"])},
      "de_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Německá stránka"])},
      "cs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CS"])},
      "cs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Česká"])},
      "da": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DA"])},
      "da_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dánská stránka"])},
      "el": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EL"])},
      "el_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Řecká"])},
      "hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HU"])},
      "hu_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maďarská stránka"])},
      "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JA"])},
      "ja_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japonská stránka"])},
      "ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KO"])},
      "ko_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korejský"])},
      "pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PT"])},
      "pt_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugalské stránky"])},
      "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UK"])},
      "ru_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruská stránka"])},
      "sk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SK"])},
      "sk_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenská"])},
      "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZH"])},
      "zh_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čínské stránky"])}
    },
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahlášení problému"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kupující"])},
    "seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodejce"])},
    "code_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaslaný kód"])},
    "code_time_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vezměte prosím na vědomí, že kód je platný po dobu 15 minut."])},
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Články"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesionální"])},
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednotlivé stránky"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peněženka"])},
    "my_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peněženka"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všechny stránky"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit"])},
    "error_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkontrolujte svůj vstup nebo kontaktujte zákaznický servis"])},
    "copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopírovat"])},
    "copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspěšná kopie"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtr :"])},
    "orders_alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upozornění"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min:"])},
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max:"])},
    "error_503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akce byla zrušena, protože byla překročena čekací doba. Zkuste to prosím znovu"])}
  },
  "major": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro návštěvu těchto stránek musíte být starší 18 let"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro vstup si prosím zkontrolujte svůj věk."])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jsem starší 18 let"])},
    "nok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jsem mladší 18 let"])},
    "conditions_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přístupem na tyto stránky vyjadřujete svůj souhlas s tím, že"])},
    "conditions_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["podmínky používání"])},
    "conditions_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a ."])},
    "conditions_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zásady ochrany osobních údajů."])}
  },
  "home": {
    "revolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revoluce sama o sobě"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference pro nákup, prodej a obchodování s lihovinami"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ať už jste milovník whisky, sběratel koňaku nebo profesionál, který hledá dokonalost, platforma The Exchange Platform je tu pro vás. Optimální zabezpečení, naprostá transparentnost a nízké poplatky: objevte obchodování s lihovinami s naprostým klidem."])},
    "secure_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezpečné transakce"])},
    "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snížené poplatky 2,5 % včetně DPH."])},
    "verified_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% ověřené účty zdarma"])},
    "title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ať už jste jednotlivec nebo profesionál, prozkoumejte náš souhrn více než 70 zdrojů cen tisíců referenčních lihovin!"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořte si bezplatný účet bez předplatného"])},
    "buy_and_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nákup a prodej: vaše transakce jsou bezpečné a transparentní."])},
    "buy_and_sell_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burzovní platforma usnadňuje průběžný nákup a prodej. Získejte snadný přístup ke všem dostupným nabídkám díky našim standardizovaným referencím, které usnadňují porovnávání a zaručují transparentnost. Najděte to, co hledáte, nebo vyjádřete svůj zájem: TEP vám umožní porovnat vaše potřeby s vyhledáváním protistran, ať už nakupujete, nebo prodáváte. Šetřete čas, optimalizujte své transakce a obchodujte vždy za nejlepší cenu. Díky intuitivnímu rozhraní a bezpečným transakcím TEP zjednodušuje obchodování s lihovinami a nabízí rychlé, spolehlivé a efektivní zkušenosti jak pro amatéry, tak pro profesionály."])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výměna lahví: bezpečná a zaručená bez rizika protistrany."])},
    "swap_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEP je jedinou platformou, která nabízí možnost výměny lahví v naprostém bezpečí díky našim řešením se zajištěnou hotovostí nebo skladovým depozitem. Tento systém zaručuje úplnou ochranu: neexistuje žádné riziko protistrany, protože peníze jsou zajištěny u našeho poskytovatele platebních služeb až do potvrzení příslušných dodávek lahví. Tato funkce přináší revoluci na trhu s lihovinami, protože zajišťuje nebývalou transparentnost a spolehlivost. Ať už jste sběratel nebo znalec, služba Exchange vám umožní rozšířit nebo optimalizovat vaši sbírku bez obav o bezpečnost transakcí. Vyměňujte své lihoviny s naprostým klidem a v moderním a bezpečném prostředí."])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block Trade: Snadné zabezpečení externích transakcí"])},
    "blocktrade_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block Trade, exkluzivní funkce TEP, umožňuje zajistit nákupní/prodejní transakce na několik kliknutí. Díky této inovaci je pro každou transakci vygenerován jedinečný odkaz, který zaručuje spolehlivé a bezpečné provedení. Ať už nakupujete nebo prodáváte, Block Trade eliminuje rizika spojená s přímými externími burzami tím, že poskytuje transparentní a bezpečný rámec. Zahrnuje také pokročilé nástroje pro potvrzení podmínek transakce, ověření účtů a zajištění hladkého provedení v každé fázi. Objevte nový způsob obchodování s lihovinami s klidem a spolehlivostí, které TEP nabízí."])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Správa portfolia: revoluce pro sběratele a investory."])},
    "portfolio_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro zkušenější nabízí burzovní platforma jedinečný nástroj pro správu portfolia. Tato funkce umožňuje sledovat pozice a jejich hodnotu v reálném čase pomocí živých cen z platformy v kombinaci s našimi podrobnými historickými údaji. Už žádné nekonečné otázky o hodnotě vaší sbírky! Díky bezprecedentně objektivnímu a agnostickému pohledu můžete sledovat vývoj svých aktiv a činit informovaná rozhodnutí. Ať už hodnotíte svou sbírku, nebo plánujete strategické transakce, nástroj TEP pro správu portfolia změní vaše zkušenosti tím, že vám poskytne naprostou transparentnost a optimální kontrolu nad vašimi destiláty."])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historické ceny: bezkonkurenční transparentnost"])},
    "price_history_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na TEP jsme sestavili nejkomplexnější historii cen všech možných lihovin, s údaji sahajícími až do roku 2002! Díky agregaci 70 různých zdrojů doplněných o naše vlastní transakce nabízíme transparentní a agnostický pohled na realizované ceny v minulosti. Všechny tyto údaje jsou pečlivě standardizovány a zpřístupněny v 18 různých měnách, což našim uživatelům umožňuje porovnávat a analyzovat tržní trendy v celosvětovém měřítku. Tato jedinečná funkce je nepostradatelným nástrojem pro sběratele, investory a profesionály, kteří chtějí činit informovaná rozhodnutí, ať už jde o nákup, prodej nebo jen o vyhodnocení svého portfolia lihovin."])},
    "title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Záruka důvěry"])},
    "secure_transactions_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platby probíhají prostřednictvím našeho poskytovatele platebních služeb Mangopay, což zajišťuje hladký průběh transakcí, nehrozí, že by peníze zmizely, nikdy je protistraně nepošlete."])},
    "low_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nejnižší poplatky na trhu"])},
    "low_fees_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Máme nejnižší poplatky na trhu, 2,5 % včetně DPH pro kupujícího i prodávajícího, žádné poplatky za uvedení nebo rezervaci. Platíte pouze v případě, že dojde k efektivní transakci."])},
    "anonymous_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonymita před transakcí"])},
    "anonymous_transactions_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ať už jste jednotlivec, nebo profesionál, naše platforma je díky své předtransakční anonymitě řešením všech dnešních problémů."])},
    "verified_accounts_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Díky kontrole registrace naším poskytovatelem platebních služeb před ověřením účtu se do platformy nedostane žádný fantomový účet, podvodník ani bot. Sledovatelnost a bezpečnost jsou naprosto dokonalé."])},
    "title_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nabídka šitá na míru vašim potřebám"])},
    "join_community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připojte se ke komunitě a vyhledávejte, spravujte a obchodujte s lihovinami!"])},
    "search": {
      "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ lihovin"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte si"])},
      "bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lahve"])},
      "search_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker, název, EAN, klíčová slova..."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhledávání"])},
      "search_bottle_bottler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhledávání v rámci"])}
    },
    "without_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obchodování je k dispozici bez předplatného!"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ať už jste amatér, sběratel nebo profesionál, platforma The Exchange je pro vás jako stvořená: optimální bezpečnost, naprostá transparentnost a nízké transakční náklady, 70 zdrojů historických cen pokrývajících tisíce referenčních lahví."])},
    "create_account_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objevte obchodování s lihovinami s naprostým klidem."])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsfeed: vaše bezplatné centrum zpráv o lihovinách"])},
    "newsfeed_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prostřednictvím informačního kanálu TEP můžete sledovat nejnovější zprávy z oboru, novinky, významné události a trendy na trhu. Tato funkce umožňuje sledovat vše, co se děje ve světě lihovin, v reálném čase, s informacemi z nejdůležitějších a nejuznávanějších stránek v tomto odvětví. Newsfeed vám poskytuje kompletní, agnostický přehled o novinkách, takže můžete mít přehled o vývoji trhu na jednom místě."])},
    "blog_and_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog a recenze: váš prostor pro analýzu a objevování."])},
    "blog_and_review_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prozkoumejte bezplatný degustační obsah s blogem a recenzí TEP. Díky recenzím od odborníků a nadšenců z oboru vám Blog & Review poskytuje přístup k jedinečným pohledům a důvěryhodným doporučením. Ať už chcete objevit nové láhve, pochopit trendy na trhu nebo rozšířit své znalosti, tento kanál je navržen tak, aby obohatil vaše zkušenosti se světem lihovin."])},
    "events_browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prohlížeč akcí: váš průvodce akcemi zaměřenými na lihoviny"])},
    "events_browser_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Díky bezplatnému Prohlížeči událostí TEP už nikdy nezmeškáte žádné důležité setkání ve světě lihovin. Dozvíte se o exkluzivních veletrzích a akcích, ať jste kdekoli. Díky pečlivě aktualizovanému výběru vám Events Browser přináší ty nejdůležitější události v oboru, ať už jste sběratel, investor, nebo jen vášnivý nadšenec. Naplánujte si výlety a užijte si jedinečné zážitky kolem lihovin."])}
  },
  "newsfeed": {
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog a recenze"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informační kanál"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Události"])},
    "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovení filtrů"])},
    "event_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
    "sort_asc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Řazení podle abecedy"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Země"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Měsíc"])},
    "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ lihovin"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhledávání..."])},
    "events_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrování událostí"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum zahájení"])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum ukončení"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na adrese"])},
    "events_show_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nadcházející akce"])},
    "events_show_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nadcházející akce"])}
  },
  "subscriptions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roční předplatné"])},
    "price_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roční kurzy"])},
    "price_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Měsíční kurzy"])},
    "free_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 měsíc zdarma při roční platbě"])},
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speciální nabídka"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesionální nabídka"])},
    "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prémiové historické ceny"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro uživatele, kteří chtějí kompletní historické údaje bez časového zpoždění."])},
    "all_taxs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])},
    "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["měsíčně"])},
    "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["za rok"])},
    "per_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["podle druhu lihoviny"])},
    "references_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přístup ke všem referencím"])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historické ceny bez časového zpoždění"])},
    "advanced_stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokročilé statistiky"])},
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlásit se k odběru lihovin"])},
    "choice_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výběr lihovin :"])},
    "wine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vína"])},
    "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další stránky"])},
    "complementary_module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doplňkový modul"])},
    "portfolio_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro zkušené sběratele, kteří chtějí více údajů a skutečný nástroj pro správu."])},
    "portfolio_subtitle_pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připojte se ke komunitě odborníků a analyzujte, spravujte a obchodujte s lihovinami!"])},
    "advanced_gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokročilá správa portfolia"])},
    "choice_this_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte si tuto nabídku"])},
    "add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat do košíku"])},
    "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Košík"])},
    "see_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Měsíční platba"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roční platba"])},
    "sub_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dílčí součet"])},
    "next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další odběr vzorků"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkem"])},
    "include_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DPH (20 %)"])},
    "payment_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přejděte k platbě"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat"])},
    "already_in_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toto předplatné je již ve vašem košíku"])},
    "already_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Již jste předplatitelem"])},
    "cart_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váš košík je prázdný"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zpět na předplatné"])},
    "contact_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktní informace"])},
    "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fakturace"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Způsob platby"])},
    "payment_method_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakce na platformě The Exchange probíhají prostřednictvím našeho poskytovatele platebních služeb Mangopay."])},
    "banking_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreditní karta"])},
    "card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo karty"])},
    "card_fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jméno na platební kartě"])},
    "card_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum ukončení platnosti (MM/RR)"])},
    "card_code_security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezpečnostní kód"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokončete svůj nákup"])},
    "soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Již brzy"])},
    "recap_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přehled plateb"])},
    "buy_commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nákupní provize"])},
    "buy_commission_tva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DPH Nákupní provize"])},
    "already_sub_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Předplatitelé"])}
  },
  "payment": {
    "cvx_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověřovací kód karty (na zadní straně karty, zpravidla 3 číslice."])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušení bylo zohledněno"])},
    "cancel_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušení se nezdařilo, kontaktujte prosím zákaznický servis"])}
  },
  "login": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vítejte na burzovní platformě"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Získejte přístup k bezproblémovému, bezpečnému a standardizovanému obchodnímu prostředí."])},
    "save_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit mé přihlášení"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlaste se"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapomněli jste své heslo?"])},
    "forgot": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovení hesla"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obdržíte e-mail s pokyny, jak obnovit své heslo."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte prosím svůj e-mail"])}
    },
    "reset": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovení hesla"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obdržíte e-mail s pokyny, jak obnovit své heslo."])},
      "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nové heslo"])},
      "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzení hesla"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověřovací kód"])}
    },
    "doubleFA": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvojí ověření pravosti"])},
      "code_reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte prosím kód, který jste obdrželi e-mailem nebo telefonicky"])}
    },
    "password_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše heslo musí obsahovat 7 znaků s alespoň jedním velkým písmenem."])},
    "wrong_values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte prosím správně všechna pole"])},
    "wrong_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte prosím platný kód"])}
  },
  "register": {
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoření účtu"])},
    "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civility"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Křestní jméno"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
    "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum narození"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Státní příslušnost"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Země"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa"])},
    "address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo ulice"])},
    "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poštovní směrovací číslo"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo"])},
    "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzení hesla"])},
    "mangopay_accept_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijímám"])},
    "mangopay_accept_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["podmínky používání"])},
    "mangopay_accept_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mangopay."])},
    "tep_accept_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijímám"])},
    "tep_accept_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["podmínky používání"])},
    "tep_accept_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a"])},
    "tep_accept_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zásady ochrany osobních údajů"])},
    "tep_accept_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de Burzovní platforma."])},
    "newsletter_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přihlaste se k odběru newsletteru"])},
    "newsletter_enter_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte svůj e-mail"])},
    "create_and_continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit si bezplatný účet a pokračovat"])},
    "end_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokončete svou registraci"])},
    "verify_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Získejte ověřený účet"])},
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název společnosti"])},
    "company_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úplná adresa"])},
    "company_zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poštovní směrovací číslo"])},
    "company_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "company_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Země"])},
    "company_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesionální e-mail"])},
    "company_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ společnosti"])},
    "company_siret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo společnosti"])},
    "company_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo DPH"])},
    "company_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje společnost"])},
    "company_siret_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["123 456 789 00010"])},
    "step": {
      "step_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obecné informace"])},
      "step_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzení 2FA"])},
      "step_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrola KYC"])},
      "step_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Údaje o bance"])},
      "step_1_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informace"])},
      "step_4_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Údaje o bance"])}
    },
    "step_0": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Získejte přístup k bezproblémovému, bezpečnému a standardizovanému obchodnímu prostředí."])},
      "usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte si způsob použití"])},
      "account_no_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ účtu nelze po dokončení registrace změnit."])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednotlivci"])},
      "professionnal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesionální"])},
      "individual_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet pro osobní použití"])},
      "professionnal_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověřený profesionální účet"])}
    },
    "step_1": {
      "general_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obecné informace"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte všechna níže uvedená pole"])},
      "personnal_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soukromý účet"])},
      "business_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firemní účet"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte si"])},
      "business_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesionální kategorie"])},
      "business_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Právní forma"])},
      "business_part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výhodní vlastníci, kteří drží více než 25 % ?"])},
      "business_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uveďte podrobnosti o dalších skutečných vlastnících, kteří vlastní více než 25 % akcií."])},
      "add_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat"])},
      "birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Narodil se na"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pobyt v"])},
      "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" a státní příslušnost"])},
      "password_requirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo musí obsahovat alespoň 8 znaků, včetně velkého písmene, malého písmene, číslice a speciálního znaku."])}
    },
    "step_2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzení e-mailu a telefonního čísla"])},
      "subtitle_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte prosím kód, který jste obdrželi e-mailem"])},
      "subtitle_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte prosím kód, který jste obdrželi telefonicky"])},
      "email_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byl vám zaslán e-mail."])},
      "phone_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byla vám zaslána SMS zpráva."])},
      "no_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neobdrželi jste kód?"])},
      "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslat na"])},
      "validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzeno"])},
      "await_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čeká se na potvrzení"])},
      "code_err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte prosím platný kód"])}
    },
    "before_kyc": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gratulujeme, k mnoha funkcím webu již máte přístup zdarma!"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcete si nyní nechat ověřit svou totožnost, abyste si mohli vychutnat všechny zážitky?"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Získejte ověřený účet"])},
      "continue_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Získejte bezplatný ověřený účet"])}
    },
    "step_3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověření vaší identity"])},
      "document_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte si typ dokumentu"])},
      "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport"])},
      "identity_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikační karta"])},
      "import_file_passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import fotografie z pasu"])},
      "import_file_identity_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import fotografie obou stran průkazu totožnosti"])},
      "choice_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte soubor"])},
      "condition_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přehledný a čitelný soubor"])},
      "condition_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velikost souboru max. 7 Mb"])},
      "condition_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formát JPG, JPEG nebo PNG"])},
      "validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslat k ověření"])},
      "verification_timing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průměrná doba ověření je přibližně 24 hodin."])},
      "active_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omezené funkce"])},
      "description_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokončete proces registrace a získejte přístup ke všem funkcím."])},
      "documents_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zasílané dokumenty"])},
      "verification_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V současné době probíhá kontrola"])},
      "verification_in_progress_restriction_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Můžete vyhledávat láhve a prohlížet si historické ceny."])},
      "verification_in_progress_restriction_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatím nelze přistupovat k transakční části ani odebírat předplatné."])},
      "send_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odesláno na"])},
      "kbis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výpis z Kbis nebo rovnocenný dokument, který není starší než tři měsíce."])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopie stanov"])},
      "passport_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cestovní pas nebo občanský průkaz, obě strany"])},
      "document_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument byl odeslán:"])},
      "documents_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslané dokumenty:"])},
      "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odmítnuto"])},
      "refused_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Důvod odmítnutí:"])},
      "IDENTITY_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osobní identita"])},
      "REGISTRATION_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firemní identita"])},
      "ARTICLES_OF_ASSOCIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stanovy"])},
      "SHAREHOLDER_DECLARATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prohlášení akcionáře"])},
      "ADDRESS_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověření adresy"])},
      "payment_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přejděte k platbě"])},
      "error_card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neplatné číslo karty"])},
      "error_card_fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jméno a příjmení na neplatné kartě"])},
      "error_card_expiration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neplatné datum vypršení platnosti"])},
      "error_card_cvx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neplatný kód kreditní karty"])},
      "title_payment_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KYC poplatky pro společnosti"])},
      "kyc_documents_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumenty byly úspěšně odeslány. Validace proběhne do 24 hodin."])},
      "ubo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prohlášení skutečných vlastníků"])},
      "verification_timing_individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průměrná doba ověření je jen několik minut"])},
      "kyc_explain_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pořizujte jasné a ostré fotografie:"])},
      "kycexplain_content_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dbejte na to, aby byl občanský průkaz nebo pas plně viditelný, bez stínů a odlesků, a aby byly všechny údaje čitelné."])},
      "kyc_explain_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odesílat lze v kompatibilním formátu:"])},
      "kycexplain_content_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Použijte formát JPEG, JPG nebo PNG s dostatečným rozlišením, aby byly informace dobře viditelné."])},
      "kyc_explain_title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podívejte se na celý dokument :"])},
      "kycexplain_content_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ujistěte se, že všechny potřebné stránky jsou zahrnuty samostatně."])},
      "documents_min_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musíte přidat 2 doklady totožnosti"])},
      "documents_min_passeport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je třeba přidat účtenku za pas."])},
      "refuse_generic_kyc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byl překročen název vašeho souboru nebo maximální velikost 7 MB na dokument."])}
    },
    "step_4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Údaje o bance"])},
      "why_enter_banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte své bankovní údaje a získejte peníze z prodeje."])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše údaje budou ověřeny naším oddělením služeb zákazníkům. Po ověření vašich bankovních údajů obdržíte e-mail."])},
      "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "iban_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XX11 0000 0000 0000 0000 000"])},
      "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC/ABA"])},
      "bic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC, směrovací číslo..."])},
      "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
      "region_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte prosím"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslat"])},
      "mangopay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakce na platformě The Exchange probíhají prostřednictvím našeho poskytovatele platebních služeb Mangopay."])},
      "banking_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše bankovní údaje byly uloženy"])},
      "banking_already_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše bankovní údaje již byly odeslány"])}
    },
    "success": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gratulujeme! Váš účet je nyní ověřen."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prohlížejte si láhve"])},
      "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přístup k mému portfoliu"])},
      "unlock_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neomezený aktivní režim"])},
      "unlock_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyní máte přístup k celé řadě funkcí."])}
    },
    "us_phone_regulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhlasím se zasíláním zpráv z burzovní platformy na výše uvedené telefonní číslo. Mohou být účtovány poplatky za přenos dat, pro odhlášení odpovězte STOP."])},
    "us_phone_regulation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokud odmítnete SMS zprávy 2FA, nebudete moci svůj účet potvrdit. Po potvrzení svého telefonního čísla prostřednictvím našeho systému SMS 2FA se můžete vždy odhlásit."])},
    "birth_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Místo narození"])},
    "birth_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Země narození"])},
    "company_siret_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo SIRET"])}
  },
  "search": {
    "barcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naskenujte nebo zadejte čárový kód"])},
    "last_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nedávné vyhledávání"])},
    "delete_all_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazat vše"])},
    "find_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zobrazený produkt"])},
    "find_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zobrazené produkty"])},
    "total_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt nalezený pomocí tohoto vyhledávání"])},
    "total_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkty nalezené pomocí tohoto vyhledávání"])},
    "total_product_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nalezený produkt"])},
    "total_products_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nalezené produkty"])},
    "empty_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chybí vám láhev?"])},
    "filter_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrování podle"])},
    "see_composite_ticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit vše"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nákup"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodej"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taker"])},
    "giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dárce"])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blokové obchodování"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
    "no_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné láhve odpovídající vašemu vyhledávání."])},
    "history_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podívejte se na historii vyhledávání"])},
    "no_auth_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořením účtu odemknete neomezený přístup"])},
    "no_auth_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaregistrujte se zdarma"])},
    "connect_access_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro tuto funkci je vyžadováno přihlášení"])},
    "empty": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chybějící láhev"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název láhve"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V případě potřeby uveďte podrobnosti o chybějící láhvi."])},
      "description_placeholder_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Můžete popsat, kde jsou důvody pro odmítnutí"])},
      "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volitelná stránka"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADRESA URL"])},
      "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.thexchangeplatform.io"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslat"])},
      "missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné výsledky."])}
    },
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuální objednávky"])},
    "bottler_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stránka stáčírny"])},
    "search_explain_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Můžete použít kombinace klíčových slov."])},
    "search_explain_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Při vyhledávání se nerozlišují velká a malá písmena."])},
    "search_explain_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Není třeba uvádět \"roky\"."])},
    "search_explain_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napsání obecné kategorie lihovin (rum, whisky, koňak atd.) nepřinese žádné výsledky."])},
    "search_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkontrolujte, zda máte ve vyhledávacím řádku zadáno alespoň jedno klíčové slovo."])}
  },
  "orders": {
    "swap_taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap Taker"])},
    "swap_giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap Giver"])},
    "account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ účtu"])},
    "current_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuální objednávky"])},
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Směr"])},
    "creation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum vytvoření"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceny"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Množství"])},
    "update_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit nákupní příkaz"])},
    "update_sell_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit příkaz k prodeji"])},
    "update_block_trade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit blokové obchodování"])},
    "update_swap_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit příkaz ke směně"])},
    "display_deleted_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit smazané příkazy"])},
    "wish_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chtěl bych obdržet"])},
    "possess_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastním"])},
    "in_exchange_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["výměnou za"])},
    "that_trade_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za co bych chtěl směnit"])},
    "update_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objednávka byla úspěšně upravena"])},
    "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspěšně vytvořená objednávka"])},
    "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celková částka :"])},
    "total_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet objednávek :"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název, cena..."])}
  },
  "match": {
    "refuse_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Důvod odmítnutí"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odmítnout"])}
  },
  "product_page": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat"])},
    "no_bottles_found_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K tomuto odkazu nebyla nalezena žádná láhev."])},
    "add_to_portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat do portfolia"])},
    "btn_buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nákup/prodej"])},
    "btn_swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výměna"])},
    "btn_blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blokové obchodování"])},
    "product_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informace o lahvích"])},
    "ticker_composite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Složení tiketů"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
    "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ lihovin"])},
    "distillery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distillerie"])},
    "bottler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lahvárna"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Věk"])},
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Značka"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abv"])},
    "vintage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vintage"])},
    "bottling_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rok stáčení"])},
    "bottle_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obsah:"])},
    "num_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet lahví"])},
    "num_bottles_produced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet vyrobených lahví"])},
    "base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Základna"])},
    "production_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Způsob výroby"])},
    "distillery_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav lihovaru"])},
    "classification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klasifikace"])},
    "empty_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádná objednávka na tuto láhev"])},
    "composite_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Složení tiketů"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivní"])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neaktivní"])},
    "search_add_bottle_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhledávání a přidávání lahví do grafu"])},
    "isbn_ticker_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN, ticker nebo název produktu"])},
    "bottle_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hledaná láhev nebyla nalezena"])},
    "trades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodej"])},
    "sources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdroje:"])},
    "empty_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro tento odkaz není k dispozici žádný obrázek"])},
    "cask_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo sudu"])},
    "show_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazení historických cen"])},
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Živé objednávky"])},
    "number_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet lahví"])},
    "orders": {
      "create_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit nákupní příkaz"])},
      "create_sell_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoření příkazu k prodeji"])},
      "create_taker_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoření příkazu k výměně"])},
      "create_giver_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoření příkazu swapového dárce"])},
      "create_blocktrade_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoření blokového obchodu"])},
      "weight_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váhová jednotka"])},
      "size_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velikost jednotky"])},
      "buy_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nákup"])},
      "sell_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodej"])},
      "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit objednávku"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednotlivé stránky"])},
      "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro"])},
      "mix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mix"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V současné době nejsou k dispozici žádné objednávky."])},
      "buy_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nákupní příkaz"])},
      "buy_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nákupní cena"])},
      "buy_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Množství"])},
      "buy_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opce"])},
      "buy_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naplánovat datum a čas ukončení?"])},
      "buy_create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoření nákupního příkazu"])},
      "sell_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příkaz k prodeji"])},
      "sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Množství"])},
      "sell_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hmotnost"])},
      "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Délka"])},
      "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šířka"])},
      "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výška"])},
      "sell_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat fotografie"])},
      "sell_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opce"])},
      "sell_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naplánovat datum a čas ukončení?"])},
      "sell_create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoření příkazu k prodeji"])},
      "success_create_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspěšně vytvořená objednávka"])},
      "success_create_dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váš požadavek byl zpracován. Brzy budete kontaktováni"])},
      "ft_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pravidla, která je třeba dodržovat jako"])},
      "buy_ft_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhněte se zbytečnému zahlcení knihy objednávek"])},
      "buy_ft_1_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Své příkazy můžete omezit na skutečné transakce."])},
      "buy_ft_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhněte se opakovaným odmítnutím"])},
      "buy_ft_2_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opakované odmítnutí shody může být vnímáno jako zlomyslné nebo nezodpovědné. Ujistěte se, že transakce uzavíráte pouze v případě, že je hodláte dokončit."])},
      "buy_ft_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respektujte dobu zotavení"])},
      "buy_ft_3_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opakované nevyzvednutí zásilek včas může vést k nepříjemnostem pro prodávajícího a dalším nákladům."])},
      "buy_ft_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respektujte integritu knihy objednávek"])},
      "buy_ft_4_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za klamavé praktiky se považuje falšování nebo manipulace s knihou příkazů, deaktivace zájmu po odmítnutí shody atd. Dbejte na to, aby vaše jednání bylo transparentní."])},
      "buy_ft_5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Připravte se na související náklady"])},
      "buy_ft_5_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mohou se na ně vztahovat místní dovozní a celní daně. Při uzavírání transakce se ujistěte, že jste připraveni je zaplatit."])},
      "sell_ft_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poskytování přesných informací"])},
      "sell_ft_1_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ujistěte se, že popis lahve je přesný a pravdivý. To pomůže kupujícím učinit informované rozhodnutí. Uveďte celou přední a zadní fotografii."])},
      "sell_ft_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respektujte podrobnosti nabídky"])},
      "sell_ft_2_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zavážete se prodat láhev, dodržujte tento závazek. Jakákoli nabídka, která nebude dodržena, bude mít pro prodávajícího důsledky."])},
      "sell_ft_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garance kvality balení a jeho obsahu"])},
      "sell_ft_3_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opakované dodávky poškozených zásilek (znehodnocení, rozbití, únik) a nesprávný obsah mají dopad na vás. Dbejte na pečlivé balení lahví."])},
      "sell_ft_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respektujte dodací lhůty"])},
      "sell_ft_4_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opakované nedodání včas může ovlivnit váš přístup do transakční oblasti. Abyste se vyhnuli zpoždění, naplánujte si to odpovídajícím způsobem."])},
      "package_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odkaz na balíček:"])},
      "dispute_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zde můžete podrobně popsat svůj problém, aby se usnadnilo řešení tiketu"])},
      "confirm_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzení o přijetí zásilky"])},
      "confirm_shipment_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzením převzetí zásilky potvrzujete, že výrobek splňuje vaše očekávání."])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiva"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazáno"])},
      "match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shoda"])},
      "match_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čekání na"])},
      "ship_send_pending_buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přeprava"])},
      "ship_send_pending_seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslat na"])},
      "delivery_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Při přepravě"])},
      "delivery_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doručováno na"])},
      "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soudní spory"])},
      "end_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokončeno"])},
      "update_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit"])},
      "shipping_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podrobnosti o dodávkách"])},
      "track_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sledovací číslo"])},
      "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placené stránky"])},
      "track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sledování mé zásilky"])},
      "shipping_details_see": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podívejte se na podrobnosti o dodávkách"])},
      "in_transit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Při dodávkách"])},
      "order_create_block_by_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Před vytvořením nového příkazu prosím přijměte nebo odmítněte své návrhy"])},
      "blocktrade_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odkaz na Blocktrade"])},
      "blocktrade_unique_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jedinečný odkaz pro sdílení"])},
      "blocktrade_secure_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezpečná transakce s naším poskytovatelem plateb Mangopay"])},
      "delete_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat"])},
      "shipping_download_sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stáhněte si dodací list"])},
      "bo_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Při ověřování"])},
      "anonymous_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonymní transakce (3 EUR včetně DPH)"])},
      "max_min_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je třeba nahrát obrázky lahví"])},
      "duplicate_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stejný obrázek nelze vložit více než jednou"])},
      "pictures_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijímány jsou pouze obrázky menší než 7 MB a s příponou .JPG, .JPEG a .png."])},
      "add_min_one_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše objednávka nesmí být platná méně než jednu hodinu"])},
      "empty_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V současné době nejsou k dispozici žádné nákupní objednávky."])},
      "empty_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V současné době nejsou k dispozici žádné prodejní příkazy."])},
      "anonymous_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kupující nebude znát vaši identitu"])},
      "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úvodní kolekce"])},
      "pickup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zásilku si u vás doma vyzvedne společnost UPS"])},
      "pickup_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum odběru"])},
      "pickup_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čas prvního vyzvednutí"])},
      "pickup_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čas posledního vyzvednutí"])},
      "not_match_preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento příkaz neodpovídá preferencím vaší země ani preferencím protistrany"])},
      "load_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat obrázky"])},
      "or_drag_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nebo přetáhnout"])},
      "file_format_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pouze soubory .JPG, .PNG a .JPEG (max. 7 MB na jednu fotografii)."])},
      "pickup_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K dispozici v relačních bodech"])},
      "kyc_not_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro přístup do sekce transakcí je nutné ověřit svou totožnost"])},
      "kyc_redirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkontrolujte mou identitu"])},
      "create_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit upozornění"])},
      "create_alert_explain_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po vytvoření prodejního příkazu obdržíte e-mail."])},
      "create_alert_explain_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po vytvoření nákupního příkazu obdržíte e-mail."])},
      "create_alert_explain_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po vytvoření objednávky obdržíte e-mail."])},
      "create_alert_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcete-li vytvořit upozornění, přejděte na stránku vybraného produktu."])},
      "order_alert_price_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceny (volitelné)"])},
      "order_alert_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše upozornění bylo vytvořeno"])},
      "order_alert_errored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba při vytváření upozornění"])},
      "order_alert_created_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše cena musí být v rozmezí 50 až 30000 EUR nebo 0 EUR."])},
      "order_alert_paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozastavení tohoto oznámení"])},
      "order_alert_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znovu aktivujte toto upozornění"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktivováno"])},
      "blocktrade_link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkopírujte odkaz a sdílejte svou objednávku na Blocktrade"])},
      "blocktrade_countdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váš blokovaný obchod je k dispozici maximálně po dobu 2 hodin. Chcete-li sdílet tento soukromý prodejní příkaz, zkopírujte odkaz kliknutím na tlačítko výše."])},
      "success_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odkaz na Blocktrade byl zkopírován správně"])},
      "wrong_value_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkontrolujte si prosím své vstupní údaje"])},
      "bottle_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena za jednotku (50-30000)"])},
      "package_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozměry balíčku"])},
      "add_bottle_basket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat láhev"])},
      "choose_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum vypršení platnosti"])},
      "duplicate_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stejný odkaz nelze přidat dvakrát"])},
      "maximum_sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dosáhli jste maximálního množství povoleného pro jeden prodej"])},
      "add_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat další zásilku"])},
      "sell_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceník"])},
      "min_pictures_required_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je třeba přidat alespoň"])},
      "min_pictures_required_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" fotografie"])}
    },
    "price_history": {
      "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historické ceny"])},
      "compare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat láhev"])},
      "responsability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafy mají pouze informativní charakter. Nepřebíráme žádnou odpovědnost za případné chyby."])},
      "data_info_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naše historická data agregují"])},
      "data_info_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prodej"])},
      "data_info_11-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prodej"])},
      "data_info_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z"])},
      "data_info_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Různé zdroje."])},
      "data_info_3-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdroj: MZV ČR, s. r. o."])},
      "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Měna"])},
      "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poplatky"])},
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průměr"])},
      "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahoru"])},
      "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bas"])},
      "base_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Základna 100"])},
      "line_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čárový graf"])},
      "candlesticks_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graf svíček"])},
      "chart_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Možnosti grafu"])},
      "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na adrese"])},
      "perf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výkonnost"])},
      "since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z"])},
      "delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceny se zobrazují se zpožděním"])},
      "delay_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["měsíc"])},
      "no_recent_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nejsou k dispozici žádné historické údaje o cenách. Pokud jsme je dosud nezařadili, kontaktujte prosím podporu a požádejte o jejich přidání."])},
      "current_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuální zobrazení"])},
      "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denně"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Měsíční"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roční"])},
      "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Týdenní"])},
      "compare_dialog": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porovnejte s"])},
        "no_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné vybrané láhve"])},
        "same_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tato láhev je již v grafu"])},
        "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dosáhli jste limitu 6 lahví pro graf"])}
      },
      "composite": {
        "display_composite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit složení"])}
      }
    },
    "report": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahlásit chybu"])},
      "error_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ chyby"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte si"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznámky"])},
      "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volitelná stránka"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popište problém přesněji zde..."])},
      "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.thexchangeplatform.io"])},
      "pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snímky obrazovky / obrázky"])},
      "pictures_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximálně 2 obrázky."])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslat"])}
    },
    "shipping_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podrobnosti o dodávkách"])},
    "success_send_error_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váš požadavek byl vzat v úvahu"])},
    "release_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum vydání"])},
    "release_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výstupní ceny"])}
  },
  "matchs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zápasy"])},
    "match_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shoda kupujícího"])},
    "match_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shoda prodávajícího"])},
    "accept_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nákup"])},
    "accept_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijímat a prodávat"])},
    "show_transactions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zobrazit mé ", _interpolate(_named("num_transac")), " aktuální transakce"])},
    "show_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit fotografie"])},
    "buying_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Můj nákupní příkaz"])},
    "selling_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příkaz k prodeji"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kupující"])},
    "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V přípravě"])},
    "buying_order_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nákupní příkaz"])},
    "sold_by_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protistrana"])},
    "selling_order_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Můj prodejní příkaz"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nezobrazují se žádné shody."])}
  },
  "account_verification_details": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoření účtu a funkce dostupné všem"])},
    "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funkce"])},
    "unverified_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neauditovaný účet"])},
    "verified_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověřený účet"])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informační kanál"])},
    "full_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezplatný a neomezený plný přístup"])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historické ceny"])},
    "advanced_visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokročilá vizualizace"])},
    "month_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patnáctiměsíční odklad"])},
    "month_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odloženo o 12 měsíců"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "portfolio_month_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odložené ocenění o 15 měsíců"])},
    "portfolio_month_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odložené ocenění o 12 měsíců"])},
    "tickers_limit_50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50 jedinečných tickerů"])},
    "tickers_limit_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100 jedinečných tickerů"])},
    "database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Databáze"])},
    "tickers_limit_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50 tickerů denně"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obchodování"])},
    "orders_visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit aktivní objednávky"])},
    "orders_not_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádný transakční přístup"])},
    "kyc_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ověření totožnosti je pro fyzické osoby zdarma, pro profesionály je zpoplatněno. KYC provádí náš poskytovatel platebních služeb Mangopay."])},
    "not_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neomezený plný přístup"])}
  },
  "portfolio": {
    "copied_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID zkopírováno"])},
    "folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soubor"])},
    "import_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import souboru CSV nebo Excel"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat"])},
    "bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lahve"])},
    "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte si všechny"])},
    "unselect_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozbalit všechny"])},
    "research_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhledávání lahví"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "to_guide_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jako průvodce,"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stáhnout"])},
    "load_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahrát soubor"])},
    "or_drag_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nebo přetáhnout"])},
    "file_csv_and_xlsx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pouze soubory CSV a XLSX"])},
    "an_import_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["model dovozu"])},
    "columns_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sloupce"])},
    "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat"])},
    "average_holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průměrná doba držení"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přejmenování"])},
    "move_in_another_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přesun do jiné složky"])},
    "rename_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přejmenování složky"])},
    "empty_folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název souboru je vyžadován"])},
    "max_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximální počet znaků nesmí překročit 30"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dovozce"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametry"])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiky"])},
    "column_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sloupce tabulky"])},
    "options_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabulka možností"])},
    "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opce"])},
    "foldAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Složte všechny"])},
    "unfoldAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odhalte všechny"])},
    "show_sell_stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit prodané akcie"])},
    "bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lahve"])},
    "tickers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickery"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informace"])},
    "th_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "th_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Množství"])},
    "th_net_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čistá cena"])},
    "th_gross_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hrubá cena"])},
    "price_with_commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provize"])},
    "th_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poplatky"])},
    "th_storage_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umístění skladu"])},
    "th_holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holding"])},
    "th_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přílohy"])},
    "th_buy_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kde nakupovat"])},
    "add_folder_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat soubor"])},
    "add_bottle_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat láhev"])},
    "name_parent_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název nadřazené složky"])},
    "additional_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další sloupce"])},
    "column_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objednávka sloupce"])},
    "my_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje láhve"])},
    "purchaseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum nákupu"])},
    "addPriceOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat možnosti nastavení ceny"])},
    "sellDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum prodeje"])},
    "swap_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum burzy"])},
    "sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodej"])},
    "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nákup"])},
    "no_bottles_found_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné láhve odpovídající vašemu vyhledávání."])},
    "select_parent_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte nadřazenou složku"])},
    "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název souboru"])},
    "create_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoření účtu"])},
    "modify_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyměnit láhev"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis"])},
    "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V přípravě"])},
    "bottle_on_hold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Držené láhve"])},
    "bottle_on_hold_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lahve ve vašem vlastnictví"])},
    "unique_references_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jedinečné reference"])},
    "on_position_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastněné láhve vynásobené příslušnou nákupní cenou."])},
    "AUM_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastněné láhve vynásobené tržní hodnotou"])},
    "running_pnl_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus valeur en cours, rozdíl mezi tržní hodnotou a nákupní cenou vynásobený množstvím."])},
    "running_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuální výkonnost"])},
    "running_year_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuální roční výnos"])},
    "average_price_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průměrná cena všech lahví"])},
    "average_holding_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průměrná doba, po kterou zůstává držba kupujícího v portfoliu."])},
    "bought_bottles_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše dosud nakoupené láhve"])},
    "sold_bottles_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše dosud prodané láhve"])},
    "total_spent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkové dosavadní výdaje"])},
    "total_sold_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkové prodeje k dnešnímu dni"])},
    "total_pnl_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kumulovaný zisk od prvního dne do dnešního dne"])},
    "total_pnl_period_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realizovaný zisk za zvolené období"])},
    "realized_total_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Návratnost se počítá od prvního dne (zohledňují se pouze prodané lahve)."])},
    "realized_year_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efektivní roční výnos (prodané lahve)"])},
    "unique_references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jedinečné reference"])},
    "on_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počáteční hodnota pozice"])},
    "running_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuální výkonnost"])},
    "running_year_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roční výnos"])},
    "realized_year_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historický roční výnos"])},
    "realized_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historický realizovaný výnos"])},
    "average_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Průměrná cena"])},
    "historical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historie"])},
    "bought_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nakoupené láhve"])},
    "unique_ticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker je jedinečný"])},
    "AUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oceňování"])},
    "running_pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latentní zisk"])},
    "sold_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodané láhve"])},
    "total_spent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkové výdaje"])},
    "total_pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkový realizovaný zisk"])},
    "total_pnl_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realizovaný zisk (období)"])},
    "realized_total_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historický realizovaný výnos"])},
    "total_sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkový prodej"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum nákupu/prodej"])},
    "net_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čistá cena"])},
    "price_without_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceny bez DPH jsou uvedeny v příloze."])},
    "price_with_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceny s poplatky"])},
    "price_with_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceny s DPH"])},
    "price_net": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čistá cena"])},
    "price_gross_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hrubá nákupní cena"])},
    "price_gross_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hrubá prodejní cena"])},
    "storage_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umístění skladu"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Množství"])},
    "boughts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nákup"])},
    "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodej"])},
    "details_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referenční údaje"])},
    "are_you_sure_delete_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opravdu chcete tento soubor odstranit?"])},
    "xlsx_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soubor v aplikaci Excel"])},
    "file_optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipy pro váš soubor Excel"])},
    "displayed_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazené sloupce"])},
    "check_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte si všechny"])},
    "stockage_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Využívaný prostor"])},
    "add_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoření účtu"])},
    "add_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat láhev"])},
    "import_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importovat soubor"])},
    "current_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuální láhev"])},
    "current_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuální láhve"])},
    "current_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuální reference"])},
    "current_references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuální reference"])},
    "current_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuální dokumentace"])},
    "move_to_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přesun do složky"])},
    "move_bottles_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lahve ve složce"])},
    "move_references_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["odkazy v dokumentaci"])},
    "move_bottle_to_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přesun do složky"])},
    "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poplatky"])},
    "holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Držení (den)"])},
    "files_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přílohy"])},
    "buy_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kde nakupovat"])},
    "sell_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Místo prodeje"])},
    "sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodané množství"])},
    "wab_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena WAB"])},
    "wasd_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocenění WASD"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
    "sub_asset_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Třída aktiv"])},
    "see_product_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přejít na produktový list"])},
    "last_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslední cena"])},
    "volatility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatilita"])},
    "uncheck_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zvolte všechny"])},
    "selected_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vybrané láhve"])},
    "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přesun"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat"])},
    "delete_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat láhev"])},
    "tel_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio je v současné době dostupné pouze na PC, ale brzy bude k dispozici i na mobilních zařízeních."])},
    "volatility_portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatilita portfolia"])},
    "volatility_spirits_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatilita lihovin"])},
    "volatility_distillery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatilita lihovarů"])},
    "volatility_vintage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ročníková volatilita"])},
    "volatility_age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Věk volatility"])},
    "portfolio_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio premium již brzy"])},
    "see_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viz podrobnosti"])},
    "go_to_product_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přejít na stránku produktu"])},
    "folder_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořený soubor"])},
    "folder_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soubor byl upraven"])},
    "folder_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soubor byl smazán"])},
    "folder_created_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba při vytváření souboru"])},
    "bottle_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lahve byly přidány"])},
    "bottle_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Láhev byla upravena"])},
    "bottle_move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Láhev byla přesunuta"])},
    "file_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soubor byl úspěšně importován"])},
    "move_modal_title_origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Původní soubor :"])},
    "move_modal_content_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuto složku a všechny lahve v ní budete přesouvat."])},
    "move_folder_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váš soubor byl přesunut"])},
    "folder_contain_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Není možné odstranit, protože v této složce jsou lahve."])}
  },
  "bid": {
    "send_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odeslat nabídku"])}
  },
  "create_order": {
    "file_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povoleny jsou pouze soubory .jpg, .jpeg a .png o velikosti menší než 3 MB."])}
  },
  "payment_process": {
    "transaction_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podrobnosti o transakcích"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přehled objednávek"])},
    "shipping_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přepravní náklady"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daň"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nákup"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodej"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odmítnout"])},
    "sold_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodejce :"])},
    "delivery_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poplatky za doručení"])},
    "selling_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakční poplatky"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celková částka"])},
    "price_tva_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Včetně DPH na"])},
    "shipping_ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poplatky za doručení UPS"])},
    "payment_type_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Způsob platby"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Způsob platby"])},
    "inssurance_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pojištění UPS"])},
    "ups_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS doma"])},
    "ups_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS point relais"])},
    "relay_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K dispozici jsou odběrná místa"])},
    "save_relay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložte si tuto adresu pro budoucí nákupy"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakce se nezdařila. Zkuste to prosím znovu"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakce byla úspěšně dokončena"])},
    "payment_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše platba je ověřována"])},
    "payment_please_wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počkejte prosím několik sekund"])},
    "add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidat do košíku"])},
    "remove_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odebrat z košíku"])}
  },
  "settings": {
    "title_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavení účtu"])},
    "title_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Správa předplatného"])},
    "my_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje předplatné"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Způsob platby"])},
    "invoice_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historie faktur"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Můj účet"])},
    "buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nákup a prodej"])},
    "bank_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Údaje o bance"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezpečnost a 2FA"])},
    "personnal_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informace"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Společnost"])},
    "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civility"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Státní příslušnost"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Křestní jméno"])},
    "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum narození"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonní číslo"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa"])},
    "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poštovní směrovací číslo"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Země"])},
    "other_delivery_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidání další doručovací adresy"])},
    "delivery_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa pro doručování"])},
    "delivery_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smazat adresu pro doručování"])},
    "billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fakturační adresa"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit"])},
    "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odesláno na"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné informace"])},
    "fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Držitel účtu"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odesláno na"])},
    "update_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změňte svůj RIB"])},
    "2fa_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvojí ověření pravosti"])},
    "2fa_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Při každém přihlášení a při každé změně na účtu obdržíte kód."])},
    "2fa_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvojí ověření e-mailem"])},
    "2fa_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvojí ověření pomocí SMS"])},
    "login_history_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nejnovější připojení"])},
    "login_history_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na všech zařízeních si můžete zobrazit historii posledních 10 připojení k účtu."])},
    "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obdržet informační bulletin"])},
    "card_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debetní karta č."])},
    "card_created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidáno na"])},
    "card_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit"])},
    "card_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platnost končí dne"])},
    "card_update_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změna bankovní karty"])},
    "card_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatím jste nezaregistrovali platební kartu."])},
    "delivery_choice_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavte tuto adresu jako výchozí"])},
    "delivery_is_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferovaná adresa pro doručení"])},
    "update_dialog": {
      "title_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změna e-mailové adresy"])},
      "actual_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuální e-mailová adresa"])},
      "new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nová e-mailová adresa"])},
      "title_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změna telefonního čísla"])},
      "actual_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuální telefonní číslo"])},
      "new_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nové telefonní číslo"])},
      "title_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přidání adresy pro doručení"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrace na adrese"])},
      "err_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail nesprávný"])},
      "err_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesprávné telefonní číslo"])},
      "err_email_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuto e-mailovou adresu již máte"])},
      "err_phone_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toto telefonní číslo již máte"])}
    },
    "subscriptions": {
      "last_month_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výdaje v aktuálním měsíci"])},
      "sub_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Předplatitel od"])},
      "never_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nejste předplatitelem"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roční předplatné"])},
      "next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum příštího odběru"])},
      "subscription_term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Předplaceno do"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odhlásit se z odběru"])},
      "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prozkoumejte dostupné odběry historických cen"])},
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odhlásit se z odběru"])},
      "cancel_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše předplatné vyprší dne"])},
      "cancel_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Týká se předplatného"])},
      "cancel_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzení o zrušení"])},
      "reactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovit předplatné"])},
      "reactive_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovat v předplatném"])},
      "reactive_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Další platba bude provedena dne"])},
      "reactive_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Týká se předplatného"])},
      "reactive_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platba bude provedena každý"])},
      "reactive_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["za cenu"])},
      "reactive_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrzení oživení"])},
      "commitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Závazky do"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["měsíc"])},
      "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ans"])},
      "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prémiové historické ceny"])}
    },
    "invoices": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historie faktur"])},
      "download_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stáhněte si všechny"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceny"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
      "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Předplatné"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faktura ke stažení"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placené stránky"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nemáte k dispozici fakturu"])},
      "delivery_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podrobnosti o dodávkách"])},
      "track_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sledování balíčku"])},
      "delivery_estimation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odhadované dodání"])},
      "transaction_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° transakce"])}
    },
    "confirm": {
      "subtitle_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte prosím kód, který jste obdrželi e-mailem"])},
      "subtitle_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte prosím kód, který jste obdrželi telefonicky"])},
      "new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nový e-mail"])},
      "new_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nový telefon"])},
      "code_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte kód, který jste obdrželi e-mailem"])},
      "code_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadejte kód obdržený telefonicky"])}
    },
    "billing_address_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faktura a adresa pro doručení"])},
    "preferences_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše preference byly úspěšně zaregistrovány"])},
    "birth_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Město narození"])},
    "birth_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Země narození"])},
    "preferences_mails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznámení"])},
    "preference_t_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoření nákupního příkazu"])},
    "preference_t_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoření prodejního příkazu"])},
    "preference_t_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoření směnného příkazu"])},
    "preference_t_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoření příkazu k blokovému obchodu"])},
    "preference_t_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kupující odmítl shodu"])},
    "preference_t_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příkaz k prodeji čekající na potvrzení"])},
    "preference_t_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizovat příjem SMS (ověření účtu a/nebo 2FA)."])},
    "preferences": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komunikační preference"])}
    },
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Název"])},
    "relay_ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Štafetový bod UPS"])},
    "buySellPreferences": {
      "buying_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Možnosti nákupu"])},
      "selling_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Možnosti prodeje"])},
      "buying_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přehled o nákupu"])},
      "selling_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přehled o prodeji"])},
      "country_exclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Země, které je třeba vyloučit"])},
      "country_inclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Země, do kterých patří"])},
      "preferences_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkopírujte mé nákupní preference pro prodej"])},
      "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše změny byly zohledněny"])}
    },
    "update_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit informace o účtu"])},
    "wrong_pseudo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkontrolujte své uživatelské jméno"])},
    "catch_update_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nelze aktualizovat své údaje. Pokud problém přetrvává, kontaktujte prosím podporu."])},
    "pseudo_already_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toto uživatelské jméno již není k dispozici, zvolte prosím jiné."])},
    "update_pseudo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit své údaje"])}
  },
  "footer": {
    "social_medias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sociální sítě"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpora"])},
    "legals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Právní"])},
    "help_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centrum nápovědy"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ČASTO KLADENÉ OTÁZKY"])},
    "recruitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nábor"])},
    "bug_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahlášení chyby"])},
    "service_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav služeb"])},
    "legal_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Právní informace"])},
    "cgu_cgv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PODMÍNKY"])},
    "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Správa sušenek"])},
    "tep_footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Společnost registrovaná ve Francii"])}
  },
  "paiment_process": {
    "shipping_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poplatky za doručení"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daně"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nákup"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odmítnout"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přehled objednávek"])},
    "sold_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodávaná část :"])},
    "delivery_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poplatky za doručení"])},
    "selling_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poplatky za prodej"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celková částka"])}
  },
  "transactions": {
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sens"])},
    "dispute_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probíhající soudní spory"])},
    "dispute_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhlásit spor"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné transakce se neprovádějí."])}
  },
  "blog": {
    "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovení filtrů"])},
    "published_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zveřejněno na :"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upraveno na :"])},
    "articles_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrování článků"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebyly nalezeny žádné položky."])}
  },
  "wallet": {
    "available_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostupné množství"])},
    "waiting_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na počkání"])},
    "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celková částka"])},
    "withdraw_money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výběr"])},
    "money_movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pohyby"])},
    "payin_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provedené platby"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žádné transakce se nezobrazují."])},
    "transfert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převod"])},
    "transfert_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Příchozí převod"])},
    "transfert_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odchozí převod"])},
    "payout_seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platba prodávajícího"])},
    "bank_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výběr"])},
    "bank_payout_in_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výběr na můj bankovní účet"])},
    "payout_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výběr dostupných prostředků"])},
    "payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrďte výběr"])},
    "success_payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převod na váš bankovní účet byl úspěšně dokončen"])},
    "failed_payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převod na váš účet se nezdařil. Pokud problém přetrvává, kontaktujte prosím zákaznický servis."])},
    "order_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje peněženka (0 %)"])},
    "available_after_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka zbývající po transakci :"])},
    "payin_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přijaté platby"])},
    "transactions_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přístup k historii obchodování"])},
    "send_iban_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Převádějte peníze přímo do peněženky a vyhněte se poplatkům za platby."])},
    "wallet_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastník :"])},
    "wallet_iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN :"])},
    "wallet_bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC :"])},
    "wallet_await_funds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše finanční prostředky budou k dispozici do 2 až 3 pracovních dnů."])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prvek úspěšně zkopírován"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopírovat"])},
    "wallet_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poskytování peněženky"])},
    "instant_payout_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platba probíhá standardně okamžitým převodem"])},
    "available_amount_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka, která je k dispozici k výběru :"])},
    "banking_account_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankovní účet končící na :"])}
  },
  "filters": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozšířené filtry"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrování:"])},
    "filters_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro vyhledávání bez klíčových slov jsou nutné 2 filtry"])}
  },
  "ratings": {
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hodnocení"])},
    "ratings_phone_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hodnocení :"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznámka"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis"])},
    "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis může obsahovat až 500 znaků"])},
    "create_error_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zkontrolujte, zda je vaše hodnocení v rozmezí 1 až 100 a zda je váš popis kratší než 500 znaků."])},
    "create_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit hodnocení"])},
    "create_btn_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvořit hodnocení"])},
    "see_or_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazit nebo změnit hodnocení"])},
    "update_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Změna ocenění"])},
    "delete_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazat hodnocení"])},
    "rating_explain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcete-li vytvořit hodnocení, přejděte na stránku produktu."])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie, název, skóre..."])},
    "description_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis je neveřejný. Nebude veřejně dostupný"])},
    "not_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["volitelná stránka"])},
    "rating_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimální počet ocenění, kterých nebylo dosaženo pro vytvoření průměru."])},
    "pseudo_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Od 5 do 50 znaků (přijímána jsou písmena a číslice)."])},
    "empty_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro tuto láhev zatím není k dispozici žádné hodnocení."])},
    "ranking_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výchozí bod pro všechny uživatele."])},
    "ranking_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uznává pravidelné přispěvatele."])},
    "ranking_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Označuje pokročilý závazek a odborné znalosti."])},
    "ranking_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skutečný závazek."])},
    "ranking_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uznává vedoucí úlohu v komunitě."])},
    "ranking_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pouze pro velmi aktivní uživatele."])},
    "ranking_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konečný žebříček, pro nejoddanější přispěvatele."])},
    "rewards_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odměny za jednotlivé úrovně budou oznámeny v lednu!"])},
    "pseudo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přezdívka"])},
    "private_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udělejte můj komentář soukromým"])},
    "profil_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "rating_unitary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oceňování"])},
    "rating_multi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hodnocení"])}
  }
}