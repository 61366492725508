export default {
  "commons.buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f3f7d120dc68b16c342c419da56f4eee"])},
  "commons.swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8778f5cc5ee1685fb97ad92e33e63225"])},
  "commons.blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ed66167a271940c0f7c84d4c7c5a43c5"])},
  "commons.portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d4f859a96c13f551a2771b7fc3a78d38"])},
  "commons.country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6c51308c891322544007578076e6c4b6"])},
  "commons.region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f447ac856e7e72435904956e3b15f433"])},
  "commons.bid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e36ba1e187ae2b3ebcfd0a4c68367caf"])},
  "commons.ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a0b271a9d8aa8e7473922164d6a1c03c"])},
  "commons.giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9b5ba48e758c4fa853d3a349300e8710"])},
  "commons.taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4d3efcd0c7f0a10f291b15e2742a7a24"])},
  "commons.report_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["34a6944807f5087c942c010dd6e63847"])},
  "commons.account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de7f250dd15c633f136800b3117cd094"])},
  "commons.orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6c8ade56da922733f270f1f8146c5f3b"])},
  "commons.quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1b27b371c9e7d0298eab533bffae53b7"])},
  "commons.back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7727eb7488be3ef30da590f1f667181e"])},
  "commons.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d12c3b0dcf75614dafb2ac6bdaa77a26"])},
  "commons.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7d206237f9912ea010005db2c20b60ce"])},
  "commons.product_origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3c86aeede3fb7a286645fbef4bcf1d05"])},
  "commons.continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["83d7c4db6d0e7126f00b9381141d0df7"])},
  "commons.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["303a74098e356909ffcf68b9bd4ca1b0"])},
  "commons.date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["44749712dbec183e983dcd78a7736c41"])},
  "commons.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d4700b697a54fbd43a1a613d32694e7f"])},
  "commons.type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a1fa27779242b4902f7ae3bdd5c6d508"])},
  "commons.login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0afbbf2f70d39364971ef07f8fa0790e"])},
  "commons.logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a7e73ea4a0051c570a9e10cab77f0d3a"])},
  "commons.registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["868ff9c2b68f5d60af29307b0bd69833"])},
  "commons.trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e72a204002f6a204f75944f2f09569e4"])},
  "commons.subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["35ec4bb88279e94717311ad558417e48"])},
  "commons.dark_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["63217a09018bfc219aa6c60e151e761a"])},
  "commons.light_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b2fe9a98454adee934137817501550da"])},
  "commons.dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1fc1d292ae27c65edf3c4342c7985bac"])},
  "commons.portfolio_gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["58c21fdb39fab5482162344ed121c3eb"])},
  "commons.matchs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["36995c80b4216534aa7f6239890d041d"])},
  "commons.transactions_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1fc1d292ae27c65edf3c4342c7985bac"])},
  "commons.account_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f64d8ff94dd401c5b98386aef0ecbd96"])},
  "commons.enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c1f480d9d81b4787593ef89d02216786"])},
  "commons.disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6936b619b2c887b2f45d6841af37e096"])},
  "commons.alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a274f4d4670213a9045ce258c6c56b80"])},
  "commons.alerts_clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fa7ad04c20e3f1c0a4278902913cb2bd"])},
  "commons.alerts_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3b6db6b6239b1ae53dc19cc8565a7cad"])},
  "commons.select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["326464577e8669795d7324031e2e3610"])},
  "commons.no_copy_paste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4ec9eb0d3296a5c2637e93bd68d21a0b"])},
  "commons.delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["77efa457f4180b1b64c51a38736515e8"])},
  "commons.secure3d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["392e6f870a8d7f5ebbb479af8018b052"])},
  "commons.cancel_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d12c3b0dcf75614dafb2ac6bdaa77a26"])},
  "commons.vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5efceb91e4d8ca127deb5f52ba5bf8b5"])},
  "commons.total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["96b0141273eabab320119c467cdcaf17"])},
  "commons.too_many_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b5b3cab4deaf37cc5bcae21585a6ab99"])},
  "commons.see_products_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["75f1618281e172742cf762145b6f1fb6"])},
  "commons.check_inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14d1043b1a2aba9ec0ff1ca0f7444a80"])},
  "commons.generic_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["518481a928a67ad50b863ca368de2542"])},
  "commons.login_retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5d7d574cfd644a34f74df62cb7a75422"])},
  "commons.email_phone_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12c8aa599f31178bd19cb3967eca39e2"])},
  "commons.not_authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bf9f40db92d31dc5a0bc6e0fc69cfecd"])},
  "commons.service_unaivalable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["21ec5b4543e9e3fcd09e03bf1e27a12e"])},
  "commons.newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ad497a59cdd8e2b20cddc69d74dfeec4"])},
  "commons.lang.lang_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9e7fe31fbf53f586110d47c4274e235b"])},
  "commons.lang.fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11aedd0e432747c2bcd97b82808d24a0"])},
  "commons.lang.fr_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e0545693906575b04aa1650abd60faba"])},
  "commons.lang.en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aa85f1840e282d8a8304dbc2c0d7c9b2"])},
  "commons.lang.en_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b82673617c50d2c2b79893656315994a"])},
  "commons.lang.es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["04c19fa1e772ab66f0aad2efe61f25cd"])},
  "commons.lang.es_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6a77de34cb21ce61732b71a27825c63a"])},
  "commons.lang.it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cd32106bcb6de321930cf34574ea388c"])},
  "commons.lang.it_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["389962c9dec540ab28d04517e82c3996"])},
  "commons.lang.pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9b7d173b068dc4d5517bfae92d676437"])},
  "commons.lang.pl_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9bd9a68a77db2c9613d0283f77d69c78"])},
  "commons.lang.de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3a52f3c22ed6fcde5bf696a6c02c9e73"])},
  "commons.lang.de_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f558ae4a08959c6c239fceb77c144cb4"])},
  "major.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6ff45168d180259b86674ab4d562766e"])},
  "major.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c555f9fffa93ab053ebc0c73e819da33"])},
  "major.ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["629242d46d91c3a8cc6149bee6318074"])},
  "major.nok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["58b3a7bbcf4b80258fc39ea03b6c46f5"])},
  "major.conditions_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dc4b217307cefb3a1631554ab8d5ca99"])},
  "major.conditions_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6ccce8eb77855a6f071f3fc4273118db"])},
  "major.conditions_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["94f0caf06c45f85ed554e7b1d29b5a33"])},
  "major.conditions_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7bf84adf2399c1176ec638bc5c41794a"])},
  "home.revolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["105ac1bd7f81c718814b12be32362e72"])},
  "home.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5a2d6bee4d25ca3b36a009e61326b336"])},
  "home.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e4d58f90f7fa8ec43f5eea0fce0507b6"])},
  "home.secure_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["79e1a05b67e222ab476a1d6e48276b0f"])},
  "home.fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e4c06ed44d942a56b8cbc4aaa1fb73ac"])},
  "home.verified_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a2a984e232a84c284e94f56b21fd1f6c"])},
  "home.title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["689e8d1aa1556f607e55e4cebb9731c9"])},
  "home.create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0d95a9f53aee172b83620002a57a5f22"])},
  "home.buy_and_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e2c3136e985602021dc0dc71408cb5b6"])},
  "home.buy_and_sell_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["94576bea5f660126d02cb8d5d4d7e485"])},
  "home.swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["296482e0ca53342eb6cd9c0521eecad1"])},
  "home.swap_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b23181681257f91fa6fff975212d43db"])},
  "home.blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8536ee3c8a57844759c1f7e1591a0bfe"])},
  "home.blocktrade_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c3498ac5dbda6975ea0ea115dfdceddb"])},
  "home.portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["77d675d4c29ae0f16f6bfeacfc15653a"])},
  "home.portfolio_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["63570ac90ace1f31c6c2e37b9aee8fcd"])},
  "home.price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["08a39c47f96b7797100074262d1afadd"])},
  "home.price_history_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1ae08d374bedc70d6630e93751ce9f37"])},
  "home.title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9775a55e98ff902ccd48315afad518c3"])},
  "home.secure_transactions_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cf0c8b679e67c5f7d9c33c8a2b677c69"])},
  "home.low_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["59c320af0c98ebcfc18ee175c1ff4e76"])},
  "home.low_fees_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ea67dc03546cdafb67f64b14fc70ee05"])},
  "home.anonymous_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dbe57d7300eb282421cd0032f86aabb2"])},
  "home.anonymous_transactions_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["87febb0ba8eeaa9d2dc4243aeeaabc0b"])},
  "home.verified_accounts_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8b5faf17c42d40b6d4a6c20c3905459c"])},
  "home.title_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["62aead001e1e8cf4eb5a556704dbc92e"])},
  "home.join_community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3218566f5f4cfe4abf2f12056b473a05"])},
  "home.search.spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["06097d25bed368b631f8d418b4e32df7"])},
  "home.search.select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["326464577e8669795d7324031e2e3610"])},
  "home.search.bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["51eacccd3bf1f858deb93dcf849a9722"])},
  "home.search.search_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16ff229715024516fa5b179d56080db3"])},
  "home.search.search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3a8a7a8a2dbbd97c1c1519c74198112d"])},
  "newsfeed.blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5380ab63287106832a9fa1f8b589f546"])},
  "newsfeed.news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ad497a59cdd8e2b20cddc69d74dfeec4"])},
  "newsfeed.events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6af499a4d247a9204ac34206d4c3b8af"])},
  "subscriptions.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["75ae58015d8cc07a93d23d0df72da48c"])},
  "subscriptions.price_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["82ba862486a678b95d6d9b0bedfcbceb"])},
  "subscriptions.price_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ed6d6b7b97d26d31cbb32708ef126cb4"])},
  "subscriptions.free_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8767640c5d8443b9f28f7c0b9006fc2f"])},
  "subscriptions.individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5b1d603fa3017ada202724f98f3aae10"])},
  "subscriptions.pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7736de6c77c87e6407f30cb0a4ad2ed8"])},
  "subscriptions.premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["351e2d571432386dda1cd6f8fe557db1"])},
  "subscriptions.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["31d68dd616862059d861467e2aa086ac"])},
  "subscriptions.all_taxs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f01390a0b796aab97beed915df1cdd63"])},
  "subscriptions.per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ab18f662e0efd094e48d108fb460ddc3"])},
  "subscriptions.per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["21c8912034b7cc41e6bab3f1b23ebf8c"])},
  "subscriptions.per_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d9ed0c6407859a443c8fafbfab8e6314"])},
  "subscriptions.references_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["54058ef98cb3343392f42dadbef988e1"])},
  "subscriptions.price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8c31cc2fffaa1e81dd3bfed692445dea"])},
  "subscriptions.advanced_stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b9cc21c0786dbf340854f4caa4e662c3"])},
  "subscriptions.subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c81f2655d79427855842a4a02a76ed13"])},
  "subscriptions.choice_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bb8688727a92270185c3d110904c6bcc"])},
  "subscriptions.wine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5c7e3dff99060226591c7670dc1622c5"])},
  "subscriptions.others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a71a87d44a628b0b1093fa9e145edcbd"])},
  "subscriptions.complementary_module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fe86a08520566754e94b36e435f05ca4"])},
  "subscriptions.portfolio_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["734c77a5f939b5148e2f17b5bd95c52c"])},
  "subscriptions.portfolio_subtitle_pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3cee1897fba407e669aa015daf5fe60e"])},
  "subscriptions.advanced_gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["db1d4719f1640f45d50fc9a5b5b20e93"])},
  "subscriptions.choice_this_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["939a048cb33949e61ca5455e40da1dbf"])},
  "subscriptions.add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b6f0b163c605d974c0a6817e15f99973"])},
  "subscriptions.cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["505cd2a0d0498236e601044fbc304a77"])},
  "subscriptions.see_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["84cc67584d348e30667fbc631ba7822a"])},
  "subscriptions.month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["54aba0f3d182bce53763b2e1ae7ef5eb"])},
  "subscriptions.year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["33f0c416d722b3de80ea61a056c0fc1f"])},
  "subscriptions.sub_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["337d603f0de9ac6daa5e6eca752d04bc"])},
  "subscriptions.next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["27e92ae2a9e8e70cadb32a82a97a26f1"])},
  "subscriptions.total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["96b0141273eabab320119c467cdcaf17"])},
  "subscriptions.include_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a4789682a67a57e1dc70876ec6f2ca10"])},
  "subscriptions.payment_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d5a849412fc6ed74d55a8c386c7d44b3"])},
  "subscriptions.delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ccfd9851b50aaa671513b50d158ed579"])},
  "subscriptions.already_in_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b7ce5d3eb17152a8a0add3813f600fac"])},
  "subscriptions.already_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["934e6ba20a1b8af00772423a63cb0468"])},
  "subscriptions.cart_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a6880929e1f3246bdc5bd81d4d526a1b"])},
  "subscriptions.back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e4d4a2e42a8baec39194e1aaa6cf32a4"])},
  "subscriptions.contact_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["777100e6ff19200d6e7ca71566e1456e"])},
  "subscriptions.billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ad04f7da0d0ebf71e846914e95a0a697"])},
  "subscriptions.update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["898c079d2e62a4b1971dfbad9d860838"])},
  "subscriptions.payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d799b4181e238845d7e4842325adcf4e"])},
  "subscriptions.payment_method_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0ceb2ee7af613c2afece2d0b25598ed4"])},
  "subscriptions.banking_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9b1d76fefe038208688b2eba58c42de2"])},
  "subscriptions.card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3edcd07509b21c6aa68226ca86300de9"])},
  "subscriptions.card_fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["49defc704425f1304d5601e6c1cddb3d"])},
  "subscriptions.card_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3993691d4365c52b454eab8e7fa79563"])},
  "subscriptions.card_code_security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b66bf8fd0bc432522ee6635acdb2fa55"])},
  "subscriptions.pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c36af9bc556fb1249db950af61dc271c"])},
  "subscriptions.soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["98c8a3e7613673903ca78f16e78972c4"])},
  "payment.cvx_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3eea94f2e4c5d650710c3808e01fc18d"])},
  "payment.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ced5b6e309375487a871892ae4fde381"])},
  "payment.cancel_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6328430e78a4fa8d38b9434dc7c42e8c"])},
  "login.welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ab82055e4fc9c3e1b47a4564f0b57ee8"])},
  "login.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["63628a13dd0af27e0366ca63c8d79983"])},
  "login.save_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["45254684389e6c0cd20ab59ea0e4ab2f"])},
  "login.login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["04fc0f6ce574d7aab7b0157bcdc846f8"])},
  "login.forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e84c9e325deb9c9399718439767ac0c5"])},
  "login.forgot.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4c49e84d4866e67fd39bab6dce8068b6"])},
  "login.forgot.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4c7956dcdfb2b62158aa12f8a8a293e8"])},
  "login.forgot.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f1c20d4536b03e9c4aa48783522e56e4"])},
  "login.reset.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4c49e84d4866e67fd39bab6dce8068b6"])},
  "login.reset.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4c7956dcdfb2b62158aa12f8a8a293e8"])},
  "login.reset.new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d383d855bd80e28cc2e73cf59db36ee1"])},
  "login.reset.confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9fe66d12fd275e6a3c9b68f86be3a25c"])},
  "login.reset.code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["27653ce2ce375ee5cf2e288ecfc04646"])},
  "login.doubleFA.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3821c27193ee07956cf91c38fee6920c"])},
  "login.doubleFA.code_reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dafb47aadda7918211db8c1298f6172c"])},
  "register.register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["868ff9c2b68f5d60af29307b0bd69833"])},
  "register.civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1ebbf11138d257442b6f358a7ff58d34"])},
  "register.firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["36017636c3c8fe809630502d2e0f04ff"])},
  "register.lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d4700b697a54fbd43a1a613d32694e7f"])},
  "register.birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7f5a59abd4a77dfa4bb00e0f47fb22e2"])},
  "register.nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d10df7f7a001325ecc88fb157e89836e"])},
  "register.region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["768e0b8ed4c703449f11ae6960e26268"])},
  "register.country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6c51308c891322544007578076e6c4b6"])},
  "register.address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50d46c2f993f4c6ab66c095555dcc401"])},
  "register.address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f4d5851df049ec75389dbd493abc4fdd"])},
  "register.zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["db17b3566c9be74a5dd2eec0852616cd"])},
  "register.city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["92e2da12c19a4943821044eb760eea09"])},
  "register.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1e884e3078d9978e216a027ecd57fb34"])},
  "register.phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["519f00cd2b5bf153a51dd4f9e8290b26"])},
  "register.password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b8f6a0049eeb602be9a5be0fde2006af"])},
  "register.confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1edd6f495ddb82f9cce5c574f2ce473a"])},
  "register.mangopay_accept_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["83b27c4f67861dda9135c21eec0b1f26"])},
  "register.mangopay_accept_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6ccce8eb77855a6f071f3fc4273118db"])},
  "register.mangopay_accept_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["caaa9206c34715da73fc80adac6b2949"])},
  "register.tep_accept_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["83b27c4f67861dda9135c21eec0b1f26"])},
  "register.tep_accept_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6ccce8eb77855a6f071f3fc4273118db"])},
  "register.tep_accept_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bc5548671519dd3e80b009dbcb882c98"])},
  "register.tep_accept_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c68cf21049c913d4237f51639b0d3a5c"])},
  "register.tep_accept_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6dfde536a4d38aab9053a5714b950f28"])},
  "register.newsletter_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a98243bf95b9c7458d0c78dc37d8888a"])},
  "register.newsletter_enter_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d59ed62281b8f663e40f815d1cb4db77"])},
  "register.create_and_continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c11da93b6d9300c5312a45e883e1f859"])},
  "register.end_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["009173be3def16a118aa7f7c24ad4089"])},
  "register.verify_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b4022cf0ef94e9744e6790cb44b20429"])},
  "register.company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c70d8f11601edb255a1dfad66cb563ff"])},
  "register.company_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["39e6dc26332b4465f68cba7fb90ef243"])},
  "register.company_zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cc1bc9121e453527dea64f2f4976f51f"])},
  "register.company_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["92e2da12c19a4943821044eb760eea09"])},
  "register.company_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6c51308c891322544007578076e6c4b6"])},
  "register.company_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d2d43ce3f8517eb442672205e8f40fc3"])},
  "register.company_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9f78a882252a6921c851cc51b04a9bbb"])},
  "register.company_siret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ff8fd90122b84251f31598eb2190a99f"])},
  "register.company_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["428a98e7f1e24a5d1fda213083da99d5"])},
  "register.company_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f641c39343d4ddd47fa88e02b62ac521"])},
  "register.company_siret_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aa87438cf42d501995ae332dc7be2f97"])},
  "register.step.step_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["be20941850e9439bf577ea583ecbf0e1"])},
  "register.step.step_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d3050758e04cf731b8ca16172d2f2b91"])},
  "register.step.step_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8aa2639c111d38e74c7fed7f43467e75"])},
  "register.step.step_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["def70303ad6b4252a2374131b9e9f1c5"])},
  "register.step_0.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["63628a13dd0af27e0366ca63c8d79983"])},
  "register.step_0.usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1589a064b91a7293835d848e2461fe43"])},
  "register.step_0.account_no_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["43371f77f1b691bb2a880e14242729ee"])},
  "register.step_0.individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["70ebac72d79356a4f21c67c904b6d4e5"])},
  "register.step_0.professionnal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b36d42aae741cec028535338e914b2a1"])},
  "register.step_0.individual_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3f7f9d2e1222944f9bd0924b03fb8db1"])},
  "register.step_0.professionnal_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9e66c84b51171ea2920fd738cbf9b478"])},
  "register.step_1.general_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["be20941850e9439bf577ea583ecbf0e1"])},
  "register.step_1.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4175473a792bd84853cc9f7ed0564124"])},
  "register.step_1.personnal_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0789c4f326ba25797fb8c0cd1449050b"])},
  "register.step_1.business_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f752cd2d64bca596f526e56dd019437b"])},
  "register.step_1.select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["326464577e8669795d7324031e2e3610"])},
  "register.step_1.business_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d6be5616adaa88862983bd1f686ff706"])},
  "register.step_1.business_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["26b2c433955ac24c6e6631a7805cf8c3"])},
  "register.step_1.business_part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fc054608b13cbdc6ea85567f644dd1f5"])},
  "register.step_1.business_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f0dd12f1b594aa32d1dacab9b86790a3"])},
  "register.step_1.add_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18d925e4d5b1b569288d119420bbcb42"])},
  "register.step_1.birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0334ac8747692b2fb4414478a59c425d"])},
  "register.step_1.country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3a871f4ea1708593ed4288e7c5e5ab4b"])},
  "register.step_1.nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["120d0389cab763c5fc4163cdab6de98a"])},
  "register.step_1.password_requirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["75e2b633725c3dc54d1f71de07cee650"])},
  "register.step_2.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f5321f16b09ce6cc054285914d89c7bc"])},
  "register.step_2.subtitle_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["45684610431f2b41f88b0b25db058238"])},
  "register.step_2.subtitle_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c2ba1f41235710f2e0296c5c4ac9d6e4"])},
  "register.step_2.email_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a44c0bd60c85e50957d382eb72481fa0"])},
  "register.step_2.phone_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["facfcdc948956538a3786b28d7e57717"])},
  "register.step_2.no_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["41bc1f1137e84df6811114b44ec1b99a"])},
  "register.step_2.resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["748088fb8905907a122518dc83c88f66"])},
  "register.step_2.validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["acb4d4829cac8fee7b1b7426a51b206e"])},
  "register.step_2.await_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4788b2efcd86b0263686770fb62e0e58"])},
  "register.step_2.code_err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6a0731418a3936c04ec8ae0453a68131"])},
  "register.before_kyc.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5db4956c90191f7d320e85aa1168da8a"])},
  "register.before_kyc.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["70ad38e5d33cf1122462ade0b3ef649b"])},
  "register.before_kyc.continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f07fe9d0a1e1ab2a1f1dd4dd3681b03e"])},
  "register.step_3.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["46b2b51d9e14b273ef933d4da989999e"])},
  "register.step_3.document_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0bf4c07e48d16f125b1059bd81e9b7c4"])},
  "register.step_3.passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ef3cf7bcc8358dee382a91ac81ef5518"])},
  "register.step_3.identity_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["97a21366cec31de40f02d57b65559826"])},
  "register.step_3.import_file_passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["60964005541b523712b8f5103d3c434b"])},
  "register.step_3.import_file_identity_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4a139d8db93ed33e507709ee87c4ad75"])},
  "register.step_3.choice_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f92997c6a0303f307e95b1a6f0883868"])},
  "register.step_3.condition_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8b8333825d36a2e04dc3762dac4fde8d"])},
  "register.step_3.condition_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ff0d5dcc5ffc5ccc1fceebeb89811464"])},
  "register.step_3.condition_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["59c1cf30bafc83f8a1f2698a4c4a9df5"])},
  "register.step_3.validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cee13a48021fa94814ebcb7369b52a99"])},
  "register.step_3.verification_timing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bea50151dcf38c83d22ee43733e9778d"])},
  "register.step_3.active_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b5b8ae8548f57531e13ac170df0c7886"])},
  "register.step_3.description_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["abe34f41248e61fa902f30f4e9c46088"])},
  "register.step_3.documents_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d1371d2af36107dbf8182a5ecd2ae122"])},
  "register.step_3.verification_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1a0d9e4e37dfb75bfdbbf131aeb72d8a"])},
  "register.step_3.verification_in_progress_restriction_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bf729d5e70e9e292681590ed89260d71"])},
  "register.step_3.verification_in_progress_restriction_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3d7c041780579e4f1632129631348622"])},
  "register.step_3.send_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["308768fefaaf33fae57cb15b950e1517"])},
  "register.step_3.kbis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["233d63b1774a855ea83ed234d09d7153"])},
  "register.step_3.status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20ec015228d454af374bfba0bb8886c3"])},
  "register.step_3.passport_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f0019cb6961bc4f5ae67a4cf9684b6b0"])},
  "register.step_3.document_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1bb94f07422bb7864a5c1d3ac926b482"])},
  "register.step_3.documents_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14159345c0e1067de9a6f209c228b162"])},
  "register.step_3.refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4367ab64a2b2ba88afc6dbf765f8ba80"])},
  "register.step_3.refused_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b24d1f7ae931fc87e607101a3ddcfbb5"])},
  "register.step_3.IDENTITY_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13b51b319af11401e8d103fa0fc3ec59"])},
  "register.step_3.REGISTRATION_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6790251eecb5a7fcba3e542ae8b46a46"])},
  "register.step_3.ARTICLES_OF_ASSOCIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ddb1efde7464c7c86090c8a53e182457"])},
  "register.step_3.SHAREHOLDER_DECLARATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d984d5287cdbcdce4b3a73f8fa6d0c2f"])},
  "register.step_3.ADDRESS_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bd54a037c016d883ca26bb19fb4cb503"])},
  "register.step_3.payment_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c510e80b2169aeb31968813549da8569"])},
  "register.step_3.error_card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["31aabdf57b21212fbe01a63ec893a04a"])},
  "register.step_3.error_card_fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6573671eaadbc4d66705a14dd61a2c87"])},
  "register.step_3.error_card_expiration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["477be96b7206b3eb55b631c6bddb39db"])},
  "register.step_3.error_card_cvx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["abc0ad0905b50cdef7902500e0454d8e"])},
  "register.step_3.title_payment_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4f58875875d7e6a1a92aa296925f64b3"])},
  "register.step_4.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["def70303ad6b4252a2374131b9e9f1c5"])},
  "register.step_4.why_enter_banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["37f0f38138120a4cffcad9288f05d2be"])},
  "register.step_4.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2595cebeb27e3d38beeff7af49235d9b"])},
  "register.step_4.iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["350e17d65ded375d643259e7eed26e2a"])},
  "register.step_4.iban_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2902b8fc021b145ada75db035e9717e0"])},
  "register.step_4.bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["521e107d04a7f4c07fbaa196846ddc0a"])},
  "register.step_4.bic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1a8d71f643de3e5cd7b08fe56c2598de"])},
  "register.step_4.region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["768e0b8ed4c703449f11ae6960e26268"])},
  "register.step_4.region_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["775eec625c622a15eba7b5571f182ca2"])},
  "register.step_4.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3305f03b85ee03c5ba71526287f666a4"])},
  "register.step_4.mangopay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0ceb2ee7af613c2afece2d0b25598ed4"])},
  "register.success.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c43da27b9cde550985dac1bd15f6c354"])},
  "register.success.search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["07297626d6f8465f72b5d04dca476df3"])},
  "register.success.portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f22441a6a47f96cb6bbb33d515a1d8c6"])},
  "register.success.unlock_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["baac80d6ed7f3fc16dd7b4e2817bde60"])},
  "register.success.unlock_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30c5432f7d5697b959b395bccfdfd70f"])},
  "search.barcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d9c26f7e23d29b22ef2c746263191afb"])},
  "search.last_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["45386c022b28fe6292a325e31ef9c5bc"])},
  "search.delete_all_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0df74f9cae51441b142dd999f89885d6"])},
  "search.find_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["63de32c0c1799445a6796f8eda890c32"])},
  "search.find_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e9b5b73d00d5e30ffd281575f00867cb"])},
  "search.total_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3c4e3b5010d1a841a42cfcb0422b2d84"])},
  "search.total_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18e9789c8931efacd2ea0fdd0cbade16"])},
  "search.total_product_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fd1602f11240032c158a16fbbb5aec2b"])},
  "search.total_products_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cc232ecdc91992cd86e6dbe05aff3274"])},
  "search.empty_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cb9afe35ab1c7b498ab87a51518fa64c"])},
  "search.filter_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3e0fe668b1054d7b58f18914c15890a1"])},
  "search.see_composite_ticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0750a37106fd56971b2a8db77d18cd45"])},
  "search.buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["831a28f1e8df07c553fcd59546465d13"])},
  "search.sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3068c5a98c003498f1fec0c489212e8b"])},
  "search.taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4d3efcd0c7f0a10f291b15e2742a7a24"])},
  "search.giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9b5ba48e758c4fa853d3a349300e8710"])},
  "search.blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a688c5068f2e5f25b98cf3dc0aa7240b"])},
  "search.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d4700b697a54fbd43a1a613d32694e7f"])},
  "search.no_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5765837641915a4ec69eaff832c87432"])},
  "search.history_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["111effe7bbfd4e28a5df2c6c0eb13e0e"])},
  "search.no_auth_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24767c2fe20d6ac7ea2808288f5a86d9"])},
  "search.no_auth_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["660b310a2abfb0eee8357c6fbd60d3f9"])},
  "search.connect_access_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d170efb19d804747e9b0d261c841d953"])},
  "search.empty.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1def57b26ad52e8c9bff615a94652d81"])},
  "search.empty.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["97de14c449200a93190a1c581b10ef7e"])},
  "search.empty.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b5a7adde1af5c87d7fd797b6245c2a39"])},
  "search.empty.description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5fc008b204f09a69e0cd83835dc6e500"])},
  "search.empty.optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fe8331a89e38a6b7a8abf8ea9be5f349"])},
  "search.empty.url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e6b391a8d2c4d45902a23a8b6585703d"])},
  "search.empty.url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["293dd69870944ec96efa18f73211dafb"])},
  "search.empty.send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["770a1a1e17972d73a5f39914e0d5c6b1"])},
  "search.empty.missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5e7982ca822ad5002b48850520bea74d"])},
  "product_page.add_to_portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8dc10ba3d1f4ae98e6581d2e28263052"])},
  "product_page.btn_buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a98109ccab7c1cd420afed8b27475e21"])},
  "product_page.btn_swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8778f5cc5ee1685fb97ad92e33e63225"])},
  "product_page.btn_blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e04957e632546455407ddab5cdf63610"])},
  "product_page.product_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["685a7c67d1fcf3816fe77c3b40ac211e"])},
  "product_page.ticker_composite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3adb2d41c782648400648068d2a3b42f"])},
  "product_page.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["99fb48ddce014d8ad24810eeb3bfde68"])},
  "product_page.spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["06097d25bed368b631f8d418b4e32df7"])},
  "product_page.distillery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bdca5245eca993157b34282a2ec75f76"])},
  "product_page.bottler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fcda5df1fd95f32114049c691019cee2"])},
  "product_page.age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9d8d2d5ab12b515182a505f54db7f538"])},
  "product_page.brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a3e2b2eac8c6e13595eec203542f4def"])},
  "product_page.strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ee7eaa96e0276aa375adb19deb81b10c"])},
  "product_page.vintage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["67bc3557dcf90ce84e22914c21a699ba"])},
  "product_page.bottling_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["05c12b222ff744d14aa450554968bce7"])},
  "product_page.bottle_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bbd94a103d62395eb830f7ec9ab04ecf"])},
  "product_page.num_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5f9abcba7476b2c01019ff58222c19b0"])},
  "product_page.num_bottles_produced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ee6f0d45476432664a9a909529d811a4"])},
  "product_page.base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["095a1b43effec73955e31e790438de49"])},
  "product_page.production_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["53510666ebc6ba1cc6c0f7dabeb2e464"])},
  "product_page.distillery_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d02f0c53730cb2c297a8068724170e48"])},
  "product_page.classification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5c9335b28e6277ddbe0137f04e5e58a7"])},
  "product_page.empty_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dc9bcab7a0f9708b90d1d7684a869dec"])},
  "product_page.composite_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3adb2d41c782648400648068d2a3b42f"])},
  "product_page.active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4d3d769b812b6faa6b76e1a8abaece2d"])},
  "product_page.closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3cab03c00dbd11bc3569afa0748013f0"])},
  "product_page.search_add_bottle_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["54aa6c83213feb6ed7a647ab0e6e519a"])},
  "product_page.isbn_ticker_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fded40c3b5b39c131cd40deeb17026ef"])},
  "product_page.bottle_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["af26bf0156296136815f2ed01d06c0f3"])},
  "product_page.trades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["02e2b4ea2fa8f1802d2bb66fbdcb7089"])},
  "product_page.sources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fb61758d0f0fda4ba867c3d5a46c16a7"])},
  "product_page.empty_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7eea6a0d343c6d220643ce305f6fcf81"])},
  "product_page.cask_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2e1d093082a74bf57c4aa9c6cf646bf0"])},
  "product_page.show_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["625b17313dff940a3feaf024cf9de8ff"])},
  "product_page.live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e4eb12c44cd21234d317a74acb3920c2"])},
  "product_page.number_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["91043eba1201e8cfd331bce83396656f"])},
  "product_page.orders.buy_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9ea378e38a25f8c174c7315b33dae4c8"])},
  "product_page.orders.sell_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["02e2b4ea2fa8f1802d2bb66fbdcb7089"])},
  "product_page.orders.create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ff0cd9f176c317b988b59c2a83211854"])},
  "product_page.orders.individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8a0fd6bfbf3c9d8db4c92ecebeece8e9"])},
  "product_page.orders.pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["abd900517e55dce0437dac136a8568d7"])},
  "product_page.orders.mix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3037bfcfe8ff40986452eec97ea71b84"])},
  "product_page.orders.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cfd7358248f93d494c74dd60dbe68c3e"])},
  "product_page.orders.buy_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["be1c962a707c07c13bbfac1499bfc790"])},
  "product_page.orders.buy_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0b62f55a9d56da9d70aa7633b7498d67"])},
  "product_page.orders.buy_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1b27b371c9e7d0298eab533bffae53b7"])},
  "product_page.orders.buy_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dae8ace18bdcbcc6ae5aece263e14fe8"])},
  "product_page.orders.buy_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24f038109d075724dbd3d428259b962c"])},
  "product_page.orders.buy_create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f3539c9e2e88151d607e6f22b9a50984"])},
  "product_page.orders.sell_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2e6490158287564a93ff75c01df29014"])},
  "product_page.orders.sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1b27b371c9e7d0298eab533bffae53b7"])},
  "product_page.orders.sell_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ae90f21dc11d8af280e2626cd518aa67"])},
  "product_page.orders.length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f4839ef434b4d28e3156b683c3fb4589"])},
  "product_page.orders.width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fb3027d907547d8fa93a893d03a3693e"])},
  "product_page.orders.height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a75a4f63997cee053ca7f12341e002dc"])},
  "product_page.orders.sell_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0a8451d3190646923e27ce91e1d4a1a4"])},
  "product_page.orders.sell_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dae8ace18bdcbcc6ae5aece263e14fe8"])},
  "product_page.orders.sell_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24f038109d075724dbd3d428259b962c"])},
  "product_page.orders.sell_create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9578a41da6360848f4133205192a2ddb"])},
  "product_page.orders.success_create_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6130e0624a2ec2d72a24b8d405190f19"])},
  "product_page.orders.ft_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4c1709b71260c26cbb99ad370c81528e"])},
  "product_page.orders.buy_ft_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["26911d6eed4b596711f47f1b8ce83f1b"])},
  "product_page.orders.buy_ft_1_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["85420b3a78644e58696d6b505c39248c"])},
  "product_page.orders.buy_ft_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7ccee7963c0f883fb41642efe2391f0a"])},
  "product_page.orders.buy_ft_2_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["142805a69e2b5d53009f03608360e58b"])},
  "product_page.orders.buy_ft_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ff7e731d3b5f1a240d285ff25d0971a6"])},
  "product_page.orders.buy_ft_3_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8752a9232974cd42858d3283c498ab57"])},
  "product_page.orders.buy_ft_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dccd101b6895c25f1b627385fbeef6c5"])},
  "product_page.orders.buy_ft_4_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0825bca73c90a1012c1ab656d299cb0c"])},
  "product_page.orders.buy_ft_5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["72f86950a93291857a049844d2273151"])},
  "product_page.orders.buy_ft_5_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["506be6f3e085a3a7de8c4a5b6e419430"])},
  "product_page.orders.sell_ft_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8fc5d360610098113f8e5f84a3be92b4"])},
  "product_page.orders.sell_ft_1_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["63931eb179a774e2b9bfd7e17bf8fa86"])},
  "product_page.orders.sell_ft_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["69fd35bc72970a27221f8bf04922a346"])},
  "product_page.orders.sell_ft_2_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f387c30319a8789d6f93fe0c6b7a6b23"])},
  "product_page.orders.sell_ft_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dda9b3c00cedbe032c81ad55c1cef387"])},
  "product_page.orders.sell_ft_3_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cfb0675c30c28e90716cdcb012bffed3"])},
  "product_page.orders.sell_ft_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f42a8efdb1e943a641668291150ab63f"])},
  "product_page.orders.sell_ft_4_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["88ad078c608e71d4e829ce05249a01ec"])},
  "product_page.price_history.price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["74d9e81a2ca7ee876107a68616f52656"])},
  "product_page.price_history.compare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d1a5b059808daab36b2f53d5228ef6bc"])},
  "product_page.price_history.responsability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5791b344e6cc271adc86ec7643d9d2d0"])},
  "product_page.price_history.data_info_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4bdbb3a8d49855c416eb7b986c75f03a"])},
  "product_page.price_history.data_info_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eba37f0c50c2b4bec26d80081e591338"])},
  "product_page.price_history.data_info_11-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9d89e3f23e2814f6c83e21bfc56741ab"])},
  "product_page.price_history.data_info_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7371f4d113a2ff147a7fbca275334271"])},
  "product_page.price_history.data_info_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6e9f8cf93fdbc3a321fe4b363ff811df"])},
  "product_page.price_history.data_info_3-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ba487776fa8cb8934954a5df3e7e41c5"])},
  "product_page.price_history.currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3ec01ea92cad072b9d6a0f32fb9b58fc"])},
  "product_page.price_history.fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8805ae4378d434ff4aafca33f70d1f35"])},
  "product_page.price_history.average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cb75404b675c662f265d9aabf870346f"])},
  "product_page.price_history.high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["45813242334bd36ea577d842d352768c"])},
  "product_page.price_history.low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e92c45d0ed5c3da366c5b45ae81bb2c8"])},
  "product_page.price_history.base_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["602500a2980b81d5b7fea22d7a824883"])},
  "product_page.price_history.line_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7cf610515f9c6233a2d50eae96a4e5c0"])},
  "product_page.price_history.candlesticks_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12457be1cd94253b0c5ceab1d2a38066"])},
  "product_page.price_history.chart_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4c7c78c062388caa93f38750ef224453"])},
  "product_page.price_history.on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d9180594744f870aeefb086982e980bb"])},
  "product_page.price_history.perf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9446a98ad14416153cc4d45ab8b531bf"])},
  "product_page.price_history.since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2540c38185026625742fa021b15901e3"])},
  "product_page.price_history.delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["59286648f64abce1ca050126861d9043"])},
  "product_page.price_history.delay_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a3d51f0acedc6770f8ab4aaffaaba148"])},
  "product_page.price_history.no_recent_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["99b365b42b69f90b710b86e884847a18"])},
  "product_page.price_history.current_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6f2f3d380c90994cd4ff7e5881a86ea5"])},
  "product_page.price_history.day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b9602654f9298c8de43b420c50aa01d6"])},
  "product_page.price_history.month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b80b2c0315c188c2d67127f58e93713d"])},
  "product_page.price_history.year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a6fab3892c3cadc330feaa74c09776b2"])},
  "product_page.price_history.week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["84db0bd32a4d6a183339a5dc5273f05f"])},
  "product_page.price_history.compare_dialog.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e4240b63d843e14e28304bbb2a0361f5"])},
  "product_page.price_history.compare_dialog.no_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5c8e242436881b0e158603b495396b6b"])},
  "product_page.price_history.composite.display_composite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ff6dcb0f63d904ec4d6ff6383fce8a36"])},
  "product_page.report.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["34a6944807f5087c942c010dd6e63847"])},
  "product_page.report.error_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e3944fdb18099fc630f17960831df77a"])},
  "product_page.report.select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["326464577e8669795d7324031e2e3610"])},
  "product_page.report.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2127c5eb60630bd62a41865a1fb1c157"])},
  "product_page.report.optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fe8331a89e38a6b7a8abf8ea9be5f349"])},
  "product_page.report.description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["824dee39460db42cc2781abcde5b9b6e"])},
  "product_page.report.url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["293dd69870944ec96efa18f73211dafb"])},
  "product_page.report.pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17ad0c897628aa3081013482c10fb552"])},
  "product_page.report.pictures_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["942db1b4d969b4bfebe2e94243a2f955"])},
  "product_page.report.send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["770a1a1e17972d73a5f39914e0d5c6b1"])},
  "matchs.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["36995c80b4216534aa7f6239890d041d"])},
  "matchs.match_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["89c16644f60dedcad5c5f9caf4530cb2"])},
  "matchs.match_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2722ed4ccf26cd0bcd6a78251206ce62"])},
  "matchs.accept_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3480e5e94074ed8737ba6c65a1fd8012"])},
  "matchs.accept_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["56d46a30e8c7aba12ef046db189d91d6"])},
  "matchs.show_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d29748feacf45f6e19b2f9de57b2e3c4"])},
  "matchs.show_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7c54cad7b6843a3fce0353ad0fb2c133"])},
  "matchs.buying_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9e254ad1be002835d9a04e137bf6483d"])},
  "matchs.buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c8529473a0c37926bcd45948e87e396d"])},
  "matchs.in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7202b8ffa44d203b76371de10e54d0b8"])},
  "account_verification_details.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1682f49a40c5a9f1c55451fd6c7b6111"])},
  "account_verification_details.features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["440c8e210dd353075f67f38bca2dbe2c"])},
  "account_verification_details.unverified_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0e8b6c50eef634a9d07a837952823860"])},
  "account_verification_details.verified_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6ea6776ff4c30331aa3da037d5bd26cf"])},
  "account_verification_details.newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ad497a59cdd8e2b20cddc69d74dfeec4"])},
  "account_verification_details.full_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d9a8d1085f8339aced52713c64a094f9"])},
  "account_verification_details.price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["74d9e81a2ca7ee876107a68616f52656"])},
  "account_verification_details.advanced_visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["60704df1920f8459cf458d9406ada93e"])},
  "account_verification_details.month_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10909035e0d8439c57307e375609f735"])},
  "account_verification_details.month_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["63df97dfd72221f0c483d4019f57bc74"])},
  "account_verification_details.portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["58c21fdb39fab5482162344ed121c3eb"])},
  "account_verification_details.portfolio_month_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["970368682eda09cdf52c9a357361ae75"])},
  "account_verification_details.portfolio_month_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["44d5b344480008873b25b389fcf5abe2"])},
  "account_verification_details.tickers_limit_50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e07f05c6c54ff3422b5e1e16877651e7"])},
  "account_verification_details.tickers_limit_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cfbff6462c4ca4f02392538923dcfeb0"])},
  "account_verification_details.database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5d02ce9f48612a9f9539aeddabba7649"])},
  "account_verification_details.tickers_limit_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["843c44fb02796c42ffe20b27e1251f6f"])},
  "account_verification_details.trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e3cd5b2c64ca319aadec7c28c6c6feba"])},
  "account_verification_details.orders_visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13f2bf586d4acd1d68d8506a519a41e5"])},
  "account_verification_details.orders_not_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c141b5cf3a68685c863618a0a1cee575"])},
  "account_verification_details.kyc_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["098a0ceac9c71a82a219de5be965cdab"])},
  "account_verification_details.not_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a2378b41dbd005dd1e933d72c3d3a3ec"])},
  "portfolio.copied_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["66e9359b4d66fb7813a82dc63c9fa374"])},
  "portfolio.folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f1a7b0e450eb0d62469cf223e5727b04"])},
  "portfolio.import_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["37b239a72cae2d8af0b3970c8e634549"])},
  "portfolio.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18d925e4d5b1b569288d119420bbcb42"])},
  "portfolio.bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["51eacccd3bf1f858deb93dcf849a9722"])},
  "portfolio.select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e5c266de1075be609a167d695d4031d6"])},
  "portfolio.unselect_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["796a743d5ef003de57d68526d1149e30"])},
  "portfolio.research_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cc720bb1633ad9e084f6458384ab0f4c"])},
  "portfolio.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d4f859a96c13f551a2771b7fc3a78d38"])},
  "portfolio.to_guide_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d6317fb012ec80ddb7e74c198024475b"])},
  "portfolio.download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e6ec1da2ef7de611c491f2e7eb1f5ae6"])},
  "portfolio.load_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["95d0fd3b4e3c8f4975b2c50f0aaa2d31"])},
  "portfolio.or_drag_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["72949ee9f3f68db3c7c2be12f645126a"])},
  "portfolio.file_csv_and_xlsx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["53195a490519b778d7d1b6f331533e7f"])},
  "portfolio.an_import_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5ba564a228487ed6ad1c325ca6ddd5b5"])},
  "portfolio.columns_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["be920cea70712eb09d7f19a3ad4179c7"])},
  "portfolio.add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18d925e4d5b1b569288d119420bbcb42"])},
  "portfolio.average_holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ac80f4f438a0eb47bcceeba31ebf2bc8"])},
  "portfolio.portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d4f859a96c13f551a2771b7fc3a78d38"])},
  "portfolio.rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8f8024c5e8cbaeaf09f01cdb7d595871"])},
  "portfolio.move_in_another_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c7e2a33f7249f5c268297a36d3928074"])},
  "portfolio.rename_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9779d82c9f80b8921b010a8b2daf2770"])},
  "portfolio.empty_folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d3ac8f6fb816410db849f5a8d4a2869f"])},
  "portfolio.max_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1c5f8b4b9fdc65d9b3ee439cbbf8aa48"])},
  "portfolio.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d4700b697a54fbd43a1a613d32694e7f"])},
  "portfolio.import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15ffab04714cffc08a5e91a1fb62e4ee"])},
  "portfolio.settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e7785de91330713fc2d12404bb6bd556"])},
  "portfolio.statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cb90f73b2622d049d22687fc9e411189"])},
  "portfolio.column_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fca4a512f82f4bebb32d0ccca05759e9"])},
  "portfolio.options_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6a049d546f139f8546f0bfa739a445b9"])},
  "portfolio.options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dae8ace18bdcbcc6ae5aece263e14fe8"])},
  "portfolio.foldAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c4edf54a0fd866ee8f747f489b5bc6cf"])},
  "portfolio.unfoldAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8cda2c73a6f29e326d4fd7778055e165"])},
  "portfolio.show_sell_stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c044f5aa7834cfa725034d56b7e92e92"])},
  "portfolio.bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3bc61575b66367d5bc5de8ba537f8b62"])},
  "portfolio.tickers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["99efe424f976a8bebac5cd7bbde99093"])},
  "portfolio.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["326e057f57e830834c20d668e44627ef"])},
  "portfolio.th_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["44749712dbec183e983dcd78a7736c41"])},
  "portfolio.th_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1b27b371c9e7d0298eab533bffae53b7"])},
  "portfolio.th_net_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["34fe79bd6b57e1756a71f79b2e14c91d"])},
  "portfolio.th_gross_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5d7f2673841dba94ef2271e725ee3904"])},
  "portfolio.price_with_commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9180f9a5a138e11f11e569e1e1680d4e"])},
  "portfolio.th_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8805ae4378d434ff4aafca33f70d1f35"])},
  "portfolio.th_storage_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ef8be5769896e7c016f040a0ba35a643"])},
  "portfolio.th_holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ed3a8b5e7179783feb83993fb141275d"])},
  "portfolio.th_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7517e49d3a1f76eda505075e22139876"])},
  "portfolio.th_buy_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d6e01e8fafc9216c9276295e48d65f61"])},
  "portfolio.add_folder_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6207effbc54b20b855f3707824baadc9"])},
  "portfolio.add_bottle_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1d57ba79bef7e07c629da472f2e81347"])},
  "portfolio.name_parent_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d3e988fbf2954140f1a517622baba79a"])},
  "portfolio.additional_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3fade6785bfe1d9e607de4ac458cf0ac"])},
  "portfolio.column_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ca01fe5bbb601033268a7daf401b178d"])},
  "portfolio.my_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["461b9e5e6b3071a4265871140380f9ef"])},
  "portfolio.purchaseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a2c87444615f88f6ec1ba6b0c47e89ac"])},
  "portfolio.addPriceOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["070afd46b5d198cbdaa818418cd702f9"])},
  "portfolio.sellDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["479f2661d2258f6078b879c9722d4879"])},
  "portfolio.swap_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ecc7ffc817696231f67e30ea332e32a3"])},
  "portfolio.sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ba61dd4b74377a311414aa68a534a2c8"])},
  "portfolio.purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["54134a7f66aba08b894205a9f537ad5e"])},
  "portfolio.no_bottles_found_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["162ccc159124ddc023d2108398569c41"])},
  "portfolio.select_parent_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b808b8f1bc010dd3d3a655b940a31238"])},
  "portfolio.folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["49e66c56efe7e867c359433ad728ac90"])},
  "portfolio.create_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ebaa670cc0af968caf4993d2738163ae"])},
  "portfolio.modify_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["44f38a4d95735159644d7d1e8a4403b6"])},
  "portfolio.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b5a7adde1af5c87d7fd797b6245c2a39"])},
  "portfolio.running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7202b8ffa44d203b76371de10e54d0b8"])},
  "portfolio.bottle_on_hold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9520895eac33d151afefa612c71a5462"])},
  "portfolio.bottle_on_hold_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["45aded47887cfc04ad8866c262c06beb"])},
  "portfolio.unique_references_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["676ea6a0fcf3f2ee237a2b9e0fac409b"])},
  "portfolio.on_position_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0771bbe446cb2c4502f5526abebfc67d"])},
  "portfolio.AUM_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["08c2b214003641ae102c3f2d0c5417d3"])},
  "portfolio.running_pnl_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b2b795dd244b9a84feadd0a83898171c"])},
  "portfolio.running_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["673eaf341306366e37ec02d85f1de2ae"])},
  "portfolio.running_year_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b8c0a50a7fdf85e1f8274a83b30e4aff"])},
  "portfolio.average_price_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["227573fd8b67084704e5f692a46f80f2"])},
  "portfolio.average_holding_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f2dd0fd36d873f5b0a88c169c16019e2"])},
  "portfolio.bought_bottles_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3432cd31e4394fb1610ee86641bed322"])},
  "portfolio.sold_bottles_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de5099fbf1ae84fd637f1d81a7a010fc"])},
  "portfolio.total_spent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ffdeecf81424cfd4d8f9eaaafe761ac1"])},
  "portfolio.total_sold_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["78272c0c34509a8385183e0404c14750"])},
  "portfolio.total_pnl_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12e0d34329027ae32995565a0e194a64"])},
  "portfolio.total_pnl_period_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8b98a0d0532e33be7cef2506d59e5ca2"])},
  "portfolio.realized_total_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a2a7f25accd86c78349ded444251e42b"])},
  "portfolio.realized_year_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b184f63dc3ea5eba22da509f7c5fa64e"])},
  "portfolio.unique_references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f2d2789694ef35a7c7d623c1ba5507e5"])},
  "portfolio.on_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a48eb71917e2e1ee596bbcf3175c14db"])},
  "portfolio.running_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3b54846d42809636ef210cd89ce63385"])},
  "portfolio.running_year_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["77904afc56579a4933e91697b719fbd0"])},
  "portfolio.realized_year_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4b506a238e0cde6cc2e861aaef31627a"])},
  "portfolio.realized_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3fd5aaab4ebab0c996e706ae984cbba8"])},
  "portfolio.average_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eb96fd9579f945262b9c4b2163699c52"])},
  "portfolio.historical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["35723f9629b329d6c5260fb573ce2451"])},
  "portfolio.bought_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e19eb1c52a20e95920cae800004e531f"])},
  "portfolio.unique_ticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["07f5e635ac7296e49ca6b21bb1c0c203"])},
  "portfolio.AUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f0484e2b337e3c3bae976ee4f3041c71"])},
  "portfolio.running_pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4aa52f9062b8b70350ba07d30068712d"])},
  "portfolio.sold_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3da2dbe6507d4f2281a8b9d581247adb"])},
  "portfolio.total_spent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["73ff2139f144d35261cfc51e23d5a98c"])},
  "portfolio.total_pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["87e20804d61e0db68f36e62fc33c3a85"])},
  "portfolio.total_pnl_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e3852c15a32ddc6a4fccd3637ecacd8a"])},
  "portfolio.realized_total_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a485251d11135f79c4742902831deb2f"])},
  "portfolio.total_sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a174a227084639dcaa3da40fa81301e8"])},
  "portfolio.date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12e290145dddc74f73f1bbb9d158974a"])},
  "portfolio.net_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["34fe79bd6b57e1756a71f79b2e14c91d"])},
  "portfolio.price_without_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ed069e896ad1cec53c522db4eb5da9bb"])},
  "portfolio.price_with_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["418c39568d85378b09a31096766f8bea"])},
  "portfolio.price_with_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8d1dfcd1afc5025a5d28eed71f159b14"])},
  "portfolio.price_net": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["34fe79bd6b57e1756a71f79b2e14c91d"])},
  "portfolio.price_gross_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c7e4e42d59ef42861bf13303efaaeb96"])},
  "portfolio.price_gross_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2d105d225075f1ec7bb00932a9bb9321"])},
  "portfolio.storage_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ef8be5769896e7c016f040a0ba35a643"])},
  "portfolio.quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1b27b371c9e7d0298eab533bffae53b7"])},
  "portfolio.boughts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9ea378e38a25f8c174c7315b33dae4c8"])},
  "portfolio.sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["02e2b4ea2fa8f1802d2bb66fbdcb7089"])},
  "portfolio.details_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f931bdb41062f629fc2e0b5d7ae1e6c8"])},
  "portfolio.are_you_sure_delete_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["300c38ee7e250d2b1860a71ee59313f7"])},
  "portfolio.xlsx_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["189aadb19aeec83a71447068dd09e697"])},
  "portfolio.file_optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23bc0edf95030e18ddc2d9b91afa0105"])},
  "portfolio.displayed_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["468f54b2dc60d1f1e05adb587738ef7e"])},
  "portfolio.check_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e5c266de1075be609a167d695d4031d6"])},
  "portfolio.stockage_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["df63982ca1248dd2926fec39b86e99d2"])},
  "portfolio.add_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ebaa670cc0af968caf4993d2738163ae"])},
  "portfolio.add_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d1a5b059808daab36b2f53d5228ef6bc"])},
  "portfolio.import_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["433735cedbe98943350fa4f53a4f1965"])},
  "portfolio.current_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f922a42eb81fc543573feb515ee6ef88"])},
  "portfolio.current_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3d847f5a7b79a40c1c5976af3b53f798"])},
  "portfolio.current_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7d98c646026ae030e1151853db937842"])},
  "portfolio.current_references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["232f60b615d37fd3f7bd8a8f9cc73568"])},
  "portfolio.current_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0d75079e6552a8227ea12497a62e8123"])},
  "portfolio.move_to_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f62ee5532ccd342f1a2ef9ea3683659b"])},
  "portfolio.move_bottles_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3ef54284c71b58ff3b683ac9f6896119"])},
  "portfolio.move_references_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bc4a8c8131a3bfd959b898f8b159f2a8"])},
  "portfolio.move_bottle_to_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f62ee5532ccd342f1a2ef9ea3683659b"])},
  "portfolio.fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8805ae4378d434ff4aafca33f70d1f35"])},
  "portfolio.holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["00928cab4ab4aba86047ef511cdd3f31"])},
  "portfolio.files_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7517e49d3a1f76eda505075e22139876"])},
  "portfolio.buy_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d6e01e8fafc9216c9276295e48d65f61"])},
  "portfolio.sell_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c8702ae3165f57db71a3d5afd33d6066"])},
  "portfolio.sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6409f62591e3b2eac12d3a9a809760a6"])},
  "portfolio.wab_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["308ceaa9d6467ecc4bbcb5ed2ade31f0"])},
  "portfolio.wasd_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d591096e00b9bab40b11a8821a607fb4"])},
  "portfolio.category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8c6b7f206708b567f49f36edb123c060"])},
  "portfolio.sub_asset_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["db3b2a8c15569c34fa538b9452e11662"])},
  "portfolio.see_product_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["94295f6fefcbe6953df70216dd736772"])},
  "portfolio.last_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["55fb95a07d4755f73a64c16b47010327"])},
  "portfolio.volatility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ebed6633a43c39e46459327b9d1ff664"])},
  "portfolio.uncheck_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["57a4b088b0efdbd72f2225490571b4a6"])},
  "portfolio.selected_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["512b969418c5b5f91b79efc42a12587c"])},
  "portfolio.move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d7f3edbd8fd82a7d4d5a722dbbe36dc8"])},
  "portfolio.delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ccfd9851b50aaa671513b50d158ed579"])},
  "portfolio.delete_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["029aef5cfc58caf27baadfe0fd775841"])},
  "portfolio.tel_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["73f1c99349138e23587d582d30098e67"])},
  "portfolio.volatility_portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6204c95dd8abf9985625dc1b17d300b6"])},
  "portfolio.volatility_spirits_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fb9e3f534542085e77313397fb5541b8"])},
  "portfolio.volatility_distillery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["35292e47e102b9eaf482f6d7f1cb8a89"])},
  "portfolio.volatility_vintage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["93616bb8f241c687128569e10de45824"])},
  "portfolio.volatility_age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0465649d1eff20e456823a74181a8152"])},
  "portfolio.portfolio_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["73009f314a0c62f31a66f25612d00564"])},
  "portfolio.see_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1b93491ffb6a7c77988e2f007db75c1f"])},
  "portfolio.go_to_product_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["664e05b91b41827c849dd89077ef86e8"])},
  "bid.send_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eb6095aae212ddb7eaa6adceee71905b"])},
  "create_order.file_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["79d3d2aafcf729e1f3b3239574e2f7e5"])},
  "settings.title_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20e344197a19b831743e889d14c08bc4"])},
  "settings.title_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a2f37186925905b974583d322ec00ded"])},
  "settings.my_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b19c890b4d696f079d2810c28b78591d"])},
  "settings.payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d799b4181e238845d7e4842325adcf4e"])},
  "settings.invoice_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9ab1760be69dc34764ab3425f264c265"])},
  "settings.account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f4fb90a18f0390d83b0c4bbebeb14599"])},
  "settings.buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a6caeb0eecc492020c80e4648b7506d8"])},
  "settings.bank_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["def70303ad6b4252a2374131b9e9f1c5"])},
  "settings.security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f3892f780be4747eb82aa716232e4410"])},
  "settings.personnal_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c4c95c36570d5a8834be5e88e2f0f6b2"])},
  "settings.business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13752cc60384f9864c5a583283979f30"])},
  "settings.civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1ebbf11138d257442b6f358a7ff58d34"])},
  "settings.nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d10df7f7a001325ecc88fb157e89836e"])},
  "settings.lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d4700b697a54fbd43a1a613d32694e7f"])},
  "settings.firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["36017636c3c8fe809630502d2e0f04ff"])},
  "settings.birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7f5a59abd4a77dfa4bb00e0f47fb22e2"])},
  "settings.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1e884e3078d9978e216a027ecd57fb34"])},
  "settings.phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ed71441922ed55d31bed5c0361f0be39"])},
  "settings.address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50d46c2f993f4c6ab66c095555dcc401"])},
  "settings.zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["db17b3566c9be74a5dd2eec0852616cd"])},
  "settings.city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["92e2da12c19a4943821044eb760eea09"])},
  "settings.region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["768e0b8ed4c703449f11ae6960e26268"])},
  "settings.country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6c51308c891322544007578076e6c4b6"])},
  "settings.other_delivery_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["69b9cd086911b71bfa38e7c8e8171546"])},
  "settings.delivery_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3005cddb89d57c53c227c3321054cc9b"])},
  "settings.delivery_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c7c0761fc75e5ac6b6dbd0055390cf4f"])},
  "settings.billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1dfced8f8484229c96e6ec4c5fe64b8d"])},
  "settings.update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["898c079d2e62a4b1971dfbad9d860838"])},
  "settings.verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3fda1d2fab723a4928fd034d6125875c"])},
  "settings.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e9883f41b49348f66cced5c30789d01c"])},
  "settings.fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d7659a84492bb0f549a152fe01cf19ad"])},
  "settings.created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["579fd7c70445b24d8926338b8dbdbcfe"])},
  "settings.update_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c3e4879e85eaa1cb7cba7ef7e2ec9caf"])},
  "settings.2fa_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3821c27193ee07956cf91c38fee6920c"])},
  "settings.2fa_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18c034ac0bf0d637f128d052cda04094"])},
  "settings.2fa_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["568a113a93858a128cc67921e79cfde7"])},
  "settings.2fa_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["88515378f313b01848220fa9095d6edf"])},
  "settings.login_history_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7f6a76563d2cdbe9a3d1ecccf9e8816d"])},
  "settings.login_history_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50aed80c857d36f41454e48b34c76537"])},
  "settings.newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a4029bac9ae7588f98d89aaf633792f2"])},
  "settings.update_dialog.title_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b11b2d3aca02deb8469bdd54d3735edb"])},
  "settings.update_dialog.actual_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7b2353765e7ae3ed2d2673a040a76e5f"])},
  "settings.update_dialog.new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["36954345acfd561cf5073e0fe573f599"])},
  "settings.update_dialog.title_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["01258bacc14bf6c6780643ffc412c927"])},
  "settings.update_dialog.actual_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6bfea6e9b11e709cd96206fc24af596d"])},
  "settings.update_dialog.new_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b2e75c8b2fb2078c19a622b6d5b0b8f3"])},
  "settings.update_dialog.title_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2b3ac39c78c1779a7afe1fb0410b3ef5"])},
  "settings.update_dialog.register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aed88202e2432ee2a613f9120ef7486b"])},
  "settings.subscriptions.last_month_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5e0624cf6f507d2641718d6913713727"])},
  "settings.subscriptions.sub_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a87602dd1e8d3e6ce521f3e1b3602743"])},
  "settings.subscriptions.never_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a250c6d9766fecc393e0a179c398646a"])},
  "settings.subscriptions.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["75ae58015d8cc07a93d23d0df72da48c"])},
  "settings.subscriptions.next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["222a2f3785075bc274c4e34190388de0"])},
  "settings.subscriptions.subscription_term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d8d932be6674a2cfff6514bf68f0ff10"])},
  "settings.subscriptions.cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["015bc420b90df173a44271b9da7fd3d2"])},
  "settings.subscriptions.explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2218dcfeb27b80862925776d94f4628d"])},
  "settings.subscriptions.cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d526fb112fae218dd4752a13acc29662"])},
  "settings.subscriptions.cancel_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["54c60d2e331e672339c5cc24dc8ca6ad"])},
  "settings.subscriptions.cancel_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8a26ec6e4577f1d1c9b775c737664ffe"])},
  "settings.subscriptions.cancel_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["91c95352724ff9c1a6c58c23bec45890"])},
  "settings.subscriptions.reactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d573020ce13112f79fd9223ea4c4734a"])},
  "settings.subscriptions.reactive_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aefb1860155a2f6856448b3ccde4c04a"])},
  "settings.subscriptions.reactive_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dbba7352d5e9c5df04698e15b6284784"])},
  "settings.subscriptions.reactive_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8a26ec6e4577f1d1c9b775c737664ffe"])},
  "settings.subscriptions.reactive_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["28efb9ff5a939254a11da13f84b90de8"])},
  "settings.subscriptions.reactive_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["47e20825538ab1035c6531a368138bfb"])},
  "settings.subscriptions.reactive_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e030f5f6b574ffa3d91b75be5eb55994"])},
  "settings.invoices.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9ab1760be69dc34764ab3425f264c265"])},
  "settings.invoices.download_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9b81a22886ed168f8e3c6c0e3362855c"])},
  "settings.invoices.date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["44749712dbec183e983dcd78a7736c41"])},
  "settings.invoices.price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bc138131629e312572f811a97f506f44"])},
  "settings.invoices.status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ec53a8c4f07baed5d8825072c89799be"])},
  "settings.invoices.subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["abf75ad7b004e5dc55d051b73bf6557d"])},
  "settings.invoices.download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["295869a3b6cbdcf69a2b36a5f9391f20"])},
  "settings.invoices.paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a2828619790a8c8bfd9a66e995a2dfa0"])},
  "settings.invoices.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fdde6309f58053c49b3389d87bb8b155"])},
  "settings.invoices.delivery_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e9c00d4b31b2ee7e28cc6fc7573092e8"])},
  "settings.invoices.track_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["79983570cabd852fd2043c4a3552066d"])},
  "settings.invoices.delivery_estimation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d114a14804aa18b082702aebcbcbb19f"])},
  "settings.invoices.transaction_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f2d0ed1d2e079c5bcc51125ec8fddced"])},
  "settings.confirm.subtitle_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e63e3661ad597ead1062d01abcb9c964"])},
  "settings.confirm.subtitle_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c2ba1f41235710f2e0296c5c4ac9d6e4"])},
  "settings.confirm.new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7aaed84527f858e9757701e29e1dc546"])},
  "settings.confirm.new_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7e3386f3a295c735aa866ca5afeca349"])},
  "settings.confirm.code_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["503c960c25dbff77c3edebcbf3f9c575"])},
  "settings.confirm.code_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cecfaf2db627dc421b61b3afdbba92fe"])},
  "settings.billing_address_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["286e9ef98d967df5ed67d4ca3ac391f5"])},
  "footer.social_medias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2cb45bb2c2c1be853e7e21122cf55c7b"])},
  "footer.support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["db5eb84117d06047c97c9a0191b5fffe"])},
  "footer.legals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2aaa1ab5fd2990b85f29c78cc7a15cc9"])},
  "footer.help_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1369d864f9ad3cbd60d898718e221db4"])},
  "footer.faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1fe917b01f9a3f87fa2d7d3b7643fac1"])},
  "footer.recruitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["42e5c536e090d7e2d666d4fd7733e22e"])},
  "footer.bug_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["debe3cf4fa4bd9036680a188d24e7ad8"])},
  "footer.service_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c95afb92cbd65ed81cd8e6de84f15393"])},
  "footer.legal_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6ba68484be023d7a6dbb170dce31deb3"])},
  "footer.cgu_cgv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f43ecfb8dcba138bdf0a33a3743d406b"])},
  "footer.cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5eaf0d597c5b03dccf76ebdb3bbc2248"])},
  "footer.tep_footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1131509bb4226dd0a8a698612b672916"])},
  "paiment_process.shipping_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4c82581f81f4320b1d254677214a08d9"])},
  "paiment_process.tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["719fec04166d6fa75f89cd29ad61fa8c"])},
  "paiment_process.buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3480e5e94074ed8737ba6c65a1fd8012"])},
  "paiment_process.refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["40ce85bef382eced8b83d37159679516"])},
  "paiment_process.summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0481158399e4ac6ac208df0e021f7a7d"])},
  "paiment_process.sold_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["51016923e5c68d8aa54c4a1fbfb2ce05"])},
  "paiment_process.delivery_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4c82581f81f4320b1d254677214a08d9"])},
  "paiment_process.selling_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ce0127de5730f74e0678324543337daa"])},
  "paiment_process.total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["da2897e54f959ec117943ba3d22599db"])},
  "payment_process.transaction_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["773c7bdbe3dd75048f882ce1b0c65535"])},
  "payment_process.summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0481158399e4ac6ac208df0e021f7a7d"])},
  "payment_process.shipping_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c5565ada0991b90246541d54a3564285"])},
  "payment_process.tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20c86479a2e1b7770cdbdc90bd4699dd"])},
  "payment_process.buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3480e5e94074ed8737ba6c65a1fd8012"])},
  "payment_process.sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4c56070a139c38d87e3e2b576f3c4658"])},
  "payment_process.refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["40ce85bef382eced8b83d37159679516"])},
  "payment_process.sold_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2840c5f1b18219645edf66d0dbdbcbde"])},
  "payment_process.delivery_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4c82581f81f4320b1d254677214a08d9"])},
  "payment_process.selling_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5b477b0ba68dece67a7cd28405fed567"])},
  "payment_process.total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["da2897e54f959ec117943ba3d22599db"])},
  "commons.live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f59bbb749b558b991ce843fbb54e362e"])},
  "commons.reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7e01b0f2ececaaab8f6e1ff4c852d132"])},
  "commons.apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3d3a50dcf90108f361140d2e05e920e1"])},
  "commons.active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4d3d769b812b6faa6b76e1a8abaece2d"])},
  "newsfeed.reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7ae332d60b7d6b8a957b40b00a305b56"])},
  "newsfeed.event_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d4700b697a54fbd43a1a613d32694e7f"])},
  "newsfeed.sort_asc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6e6e31eee03bfb3d7d3de1e5ee9440de"])},
  "newsfeed.country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6c51308c891322544007578076e6c4b6"])},
  "newsfeed.city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["92e2da12c19a4943821044eb760eea09"])},
  "newsfeed.dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5f0ca3e8b37b653d3bb4b9898d7d6317"])},
  "newsfeed.month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2b7ecad4b5f1b534f22f4093a909b872"])},
  "newsfeed.spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["06097d25bed368b631f8d418b4e32df7"])},
  "newsfeed.search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["da21962e48634867f8aec7b5e6488bf4"])},
  "newsfeed.events_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5fe1c6748118c0478bb43d1f809a49e5"])},
  "newsfeed.start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b9afcfc623e35de4ae1d78cac7e4cfd8"])},
  "newsfeed.end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["806e28fc69755225ecfacba6ba869d88"])},
  "newsfeed.from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13a014cb9de9f7cad88d5dafb70ecb41"])},
  "newsfeed.to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8bcc25c96aa5a71f7a76309077753e67"])},
  "settings.card_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["902a753c3ddeb7c789d61b0c3babbc8c"])},
  "settings.card_created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8b58994eeebb0641d3d57b6080ef55c1"])},
  "settings.card_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["898c079d2e62a4b1971dfbad9d860838"])},
  "settings.card_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["158e6549b770a0f687e2c48f54fccabd"])},
  "settings.card_update_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b9778d251c879a23d9150d2cabd91a91"])},
  "settings.card_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c9584125cc4d215454145f3331cf78af"])},
  "settings.delivery_choice_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["781f58678ecca3a13d934e78d48c2dd0"])},
  "settings.delivery_is_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["652199263021a27c311bbff7f3cf5680"])},
  "commons.refuse_match_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4e2f2ba48995886fa02c9b9168a01b68"])},
  "commons.date_expire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3a2a62d5a38a0477638473583e25f9e7"])},
  "commons.status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["87a831bc99060c55b50dbe91c6aab6e9"])},
  "commons.price_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["61340855eeee671b3ebe76e5f7212862"])},
  "commons.inputs_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b9173e649ee067188216796edadc24f2"])},
  "orders.swap_taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["398b4af21c06483f0be759bab151819f"])},
  "orders.swap_giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1db6d26598454a9ab485ab7de474aa6d"])},
  "orders.account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de7f250dd15c633f136800b3117cd094"])},
  "product_page.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18d925e4d5b1b569288d119420bbcb42"])},
  "product_page.no_bottles_found_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7ca12d470610e118b8e425f1885fee9c"])},
  "product_page.orders.create_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f8e44fc571c2a2c57e5cdac771f3c6ee"])},
  "product_page.orders.create_sell_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ce6b77bdb4aedc51fb65604cda2569c4"])},
  "product_page.orders.create_taker_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f590ee107497a0e6aaff8c69daaed54d"])},
  "product_page.orders.create_giver_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d6e380e24a7ee22c345dabfeafdab6c4"])},
  "product_page.orders.create_blocktrade_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["02815440651aed99ef0ac51b62e764ee"])},
  "product_page.orders.weight_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a0e70abc64ea4443b88dca3d0ab3346b"])},
  "product_page.orders.size_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["75424ab5cabf8e5ac6f1bc53289ce0c9"])},
  "product_page.orders.success_create_dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f2b7e86eae5b0e3343a628f0c577b1d3"])},
  "product_page.orders.package_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["67f90a7fa51d20cbc989cc13dd3f5bcc"])},
  "product_page.orders.dispute_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4397300bb6cab6de92c7c70fc613b8de"])},
  "product_page.orders.confirm_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["96e06dbf64b2099429a2959184670141"])},
  "product_page.orders.confirm_shipment_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6becb466e191395817ac206a4974f700"])},
  "matchs.selling_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2e6490158287564a93ff75c01df29014"])},
  "settings.subscriptions.commitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9decd2ecb6e704b024f5dc6e5432e76d"])},
  "search.empty.description_placeholder_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e680db4fe3b122342f84e4331cf490bb"])},
  "match.refuse_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["26f679fbb7f4a6677e37ec272a16345a"])},
  "match.refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["40ce85bef382eced8b83d37159679516"])},
  "commons.lang.cs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8d7e99c73cd5a10adaaf4c9f9a520368"])},
  "commons.lang.cs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b3e015bf50224e0d62c1f135eaf19641"])},
  "commons.lang.da": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["03895f91b58717b678dd94bd941d7a72"])},
  "commons.lang.da_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b0adfd7d548899acb6bab67afc1a7c1c"])},
  "commons.lang.el": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d4e8bee50f2093fbced5daaf3c41980d"])},
  "commons.lang.el_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0c00c64a9d991b8bf36056080f10611e"])},
  "commons.lang.hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["35b5282113b88c2fe23dcbec9f0258c1"])},
  "commons.lang.hu_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["627405da66594a8a206b986e8121bc78"])},
  "commons.lang.ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["58806e006b14b04a535784a5462d09b0"])},
  "commons.lang.ja_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["05f8e87da64ab9ae3a16aa18b7ec51b3"])},
  "commons.lang.ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5fed318f9c859ad1b31120392472c25a"])},
  "commons.lang.ko_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a5b03f2384d11f546d1a1ede24e4b1f4"])},
  "commons.lang.pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["35357b9c8fe4d3273d0237ecc8ff2e75"])},
  "commons.lang.pt_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["292fba65c6fd3610931fb93fd1b012e8"])},
  "commons.lang.ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f9308c5d059650ee40cab30bad1e96d0"])},
  "commons.lang.ru_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0f87a15c3d07e465057778ee8119547a"])},
  "commons.lang.sk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13dd621f27110108a10a88e99fe9ceaf"])},
  "commons.lang.sk_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b0644619aff8e7ede33a8ac59d9f59ff"])},
  "commons.lang.zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2f360b70e0ecf402694429fac3a9255d"])},
  "commons.lang.zh_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8852f9b6d2b9018bafeb2f08effe9d5d"])},
  "matchs.buying_order_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["be1c962a707c07c13bbfac1499bfc790"])},
  "matchs.sold_by_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8d1de917d69bb92bfed7ca36f3b661f1"])},
  "matchs.selling_order_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["db569f10c10d81636e92b5c107de1618"])},
  "commons.report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5238d59077988c93ae554981b20fc64e"])},
  "transactions.direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8ddcdb0969b716a5bd64b92bc5d62952"])},
  "transactions.dispute_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["06377f098365649cf3a01d70ec2e4ade"])},
  "transactions.dispute_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a0f609c1925491536dc2761104786e55"])},
  "commons.buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c8529473a0c37926bcd45948e87e396d"])},
  "commons.seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b1802cee3f64d16df4fba4f28a4b5fdb"])},
  "product_page.orders.active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a9f35372e5f038fe5244dc297166e30e"])},
  "product_page.orders.deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ee8bd13de2389803bef04163c6e3a39f"])},
  "product_page.orders.match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6da89265a9a8b0b28eb4946bb2ec0c6d"])},
  "product_page.orders.match_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a3bcfe6e2a4c647c0817d980f0bf1f1b"])},
  "product_page.orders.ship_send_pending_buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1d794ed4cb64b9d43c75b9dddb5a850b"])},
  "product_page.orders.ship_send_pending_seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["064cb6d7185517ec350faa7a1c63232a"])},
  "product_page.orders.delivery_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["51f7df0d2ed9dee99ed4b7339983174f"])},
  "product_page.orders.delivery_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["db022c119864e48f5ec6187c22f36072"])},
  "product_page.orders.dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["896c3972d717f273c56f464b80ea8c3c"])},
  "product_page.orders.end_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["da53818f7b27bf413d7edb1a7f19fe38"])},
  "matchs.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e414cd5a2143b581f2fe7791eca6a053"])},
  "transactions.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0a34cd29fd49ef174a83b8017a816775"])},
  "search.live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f59bbb749b558b991ce843fbb54e362e"])},
  "orders.current_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f59bbb749b558b991ce843fbb54e362e"])},
  "orders.direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["02674a4ef33e11c879283629996c8ff8"])},
  "orders.creation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1df3e4291539e048539071445d1306d3"])},
  "orders.price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bc138131629e312572f811a97f506f44"])},
  "orders.quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1b27b371c9e7d0298eab533bffae53b7"])},
  "orders.update_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["906d34d200eeb45a3ce1f7e2d5c9bf02"])},
  "orders.update_sell_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e064ee7635a1c60df28f48081584bb5f"])},
  "orders.update_block_trade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6d6f5d5d757f3e1d537d5bcf237df7b7"])},
  "orders.update_swap_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9115216a8455f9f898a13a8c46784ad4"])},
  "orders.display_deleted_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5d534859bb188e978236e8ec58647915"])},
  "product_page.shipping_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["41f29dcdd408d6a6ae752f1c1b4044f7"])},
  "settings.subscriptions.monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a3d51f0acedc6770f8ab4aaffaaba148"])},
  "settings.subscriptions.yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1298cbdc3eacd6eed0690cdad2626813"])},
  "orders.wish_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["094d25b90a5c3579a60af7014afa10a8"])},
  "orders.possess_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4f4fee66c58667bdc2f60910747356a5"])},
  "orders.in_exchange_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["70b404be3675d163a0eb9fca626c253e"])},
  "orders.that_trade_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6396fcb9513b005ceb8137752163f4c1"])},
  "product_page.orders.update_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["898c079d2e62a4b1971dfbad9d860838"])},
  "product_page.orders.shipping_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["41f29dcdd408d6a6ae752f1c1b4044f7"])},
  "product_page.orders.track_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a09f268690261814182737ddd5f15479"])},
  "product_page.orders.pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a2828619790a8c8bfd9a66e995a2dfa0"])},
  "product_page.orders.track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a5e6fce177847206680f0a6afe321502"])},
  "product_page.orders.shipping_details_see": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["affc8ced7618bead8ae966ad6bebff77"])},
  "product_page.orders.in_transit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5a8dc9d9c6735891653f46d1f6da0f06"])},
  "payment_process.price_tva_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bc3f6f227b24562271ebf3476b035452"])},
  "subscriptions.recap_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2300fb30d22eaa91b29ea231530433ab"])},
  "subscriptions.buy_commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0b3a091a1d48dc19e9f40b17888dee6f"])},
  "subscriptions.buy_commission_tva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cc6a22a0b498bb2d55bb0c4341d915f3"])},
  "payment_process.shipping_ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1b5eb6d171a12a66a7405df1876030e3"])},
  "payment_process.payment_type_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7b7dc5be3269e8455436b946d222225a"])},
  "payment_process.payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d799b4181e238845d7e4842325adcf4e"])},
  "commons.code_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ed0d014ac5950bf7d608039aa8ea529a"])},
  "commons.code_time_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fad5a3d686ac0b0fe933ccdbd3d6ef74"])},
  "register.step_3.kyc_documents_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5121ebc50bdc9ec029e429c823ecf299"])},
  "register.step_4.banking_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["813ab0a730e01451bc54fda9db991ef5"])},
  "register.step_4.banking_already_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["35b5e093add8826ab39c3178fe50535a"])},
  "product_page.orders.order_create_block_by_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f7a1f432150091535167d35d3547d017"])},
  "portfolio.folder_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["52ca32211b03969105af947d1654f880"])},
  "portfolio.folder_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7a50ccb5eb4adf3b32786432f8707c3b"])},
  "portfolio.folder_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a8cb1fbdabcf4ce5d62ca8448b5b3a94"])},
  "portfolio.folder_created_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["195dc126d9077308bcd2d5636d6529c3"])},
  "portfolio.bottle_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ed65931b5a941ab58ebc616f037f2080"])},
  "portfolio.bottle_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e0cc52bbb14ff6c3c9351b14c5f8b694"])},
  "portfolio.bottle_move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6c2a14310eef40fc9a8b21de5ae82c65"])},
  "portfolio.file_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["820dc2d235490c15a148c746ce991b93"])},
  "settings.preferences_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c02cdb6657bdc3ac1794d17f3d59bdaa"])},
  "payment_process.inssurance_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4c814c4b99f98df3aa75e6c59f29a6f6"])},
  "product_page.success_send_error_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c293dcc81463fb8357337e2b6c6c3787"])},
  "product_page.orders.blocktrade_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7625b3cc147a5e66a4b85994554ef7fe"])},
  "product_page.orders.blocktrade_unique_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["01af1bea0724fbe970eda3534466d1fc"])},
  "product_page.orders.blocktrade_secure_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b7432a522f83d382cef5c64fe5034209"])},
  "product_page.orders.delete_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ccfd9851b50aaa671513b50d158ed579"])},
  "product_page.orders.shipping_download_sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b90d53228b5411295e928d9558e76c8a"])},
  "product_page.orders.bo_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4fed2eb22d42606e5ffb04406bd2b473"])},
  "commons.blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7978b606e7f71e42ee8f9c1b3326fe58"])},
  "settings.birth_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4dfd8a1ec1c0c4d7b9a828b13b5b7d0c"])},
  "settings.birth_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fe13e5df43d66ce4c17675aef3bbbd9b"])},
  "register.step.step_1_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c4c95c36570d5a8834be5e88e2f0f6b2"])},
  "register.step.step_4_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["683ffcba79dd997e623f341c05e64912"])},
  "settings.update_dialog.err_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["03114a70bb14a1352fc84475a41b44d9"])},
  "settings.update_dialog.err_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a8aa5805d35334e64428dc4d9a4f6512"])},
  "settings.update_dialog.err_email_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["158a7bd88381dff53a943b5b3ae5ad63"])},
  "settings.update_dialog.err_phone_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["db15d9b8036b32ad48fd8eb6aeac96b5"])},
  "commons.pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b36d42aae741cec028535338e914b2a1"])},
  "commons.individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8a0fd6bfbf3c9d8db4c92ecebeece8e9"])},
  "product_page.orders.anonymous_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9f39f6004fe300a428c24c3bda9eed13"])},
  "product_page.price_history.compare_dialog.same_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d7087b2ecdaf89113d0b7d4e882c7804"])},
  "product_page.price_history.compare_dialog.limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["331c40db56a17954e7efb4897c299e07"])},
  "product_page.orders.max_min_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ffdd7fb128f18fa966e70c4b06b1615a"])},
  "product_page.orders.duplicate_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cb763c3f6f6f812530f59bf14428dc30"])},
  "product_page.orders.pictures_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4ea13428fc96be98eef7806232bca6ec"])},
  "product_page.orders.add_min_one_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5bf00a8c62eb0fb0499800586ceff525"])},
  "product_page.orders.empty_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["51059a21a28ff390fd0b0fbe6b411170"])},
  "product_page.orders.empty_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4f846be84491a7c068a4631e18050c87"])},
  "portfolio.move_modal_title_origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4909919b2c234971b945d9c2ab9a23d9"])},
  "portfolio.move_modal_content_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d7d54cb65ec8009fcfce7614da667386"])},
  "portfolio.move_folder_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7ca9724ade9328d483b89b10bd3c3769"])},
  "blog.reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7ae332d60b7d6b8a957b40b00a305b56"])},
  "blog.published_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5c4504d68da7525eb9cd828f3eb13283"])},
  "blog.updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14ef122f80b665a87eb490ed199e6ae0"])},
  "blog.articles_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["acea748d81402ddfedf34e8a98ff25e3"])},
  "blog.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f3d58c1ecc32d11781c7481c16824bd8"])},
  "register.us_phone_regulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["091ab054f32705a7ec67bad809f2b8e9"])},
  "register.us_phone_regulation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["facb3bc773a648951a779bc86a9c5194"])},
  "settings.preferences_mails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a274f4d4670213a9045ce258c6c56b80"])},
  "settings.preference_t_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18467edd7e13aba29dae7df1367ee3fb"])},
  "settings.preference_t_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["844f31edc5cf073e9ddd6884747aa2b1"])},
  "settings.preference_t_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ccd2d8eba9d20b70b5a22333e7c1f9f0"])},
  "settings.preference_t_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a7a840dd05e9193f90c99e3f6c90b66f"])},
  "settings.preference_t_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["614fc54426ac6d6ccd54910ccd567505"])},
  "settings.preference_t_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10caa82bbd27c00dc3ecc44958c3ce72"])},
  "settings.preference_t_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30bf03d773e1beb69797c814c237795c"])},
  "settings.preferences.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["82180eadf01f50ed0afdfa5e849560b3"])},
  "commons.wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["156e132b014314b1eae89410e54bcc8c"])},
  "wallet.available_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a9af9a0c7e9d21827f1f741113c9848e"])},
  "wallet.waiting_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["439149be8bfe2e2ff0c156e35bfd5894"])},
  "wallet.total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["da2897e54f959ec117943ba3d22599db"])},
  "wallet.withdraw_money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c68f7b3a3855be4ce664f195b7d32a86"])},
  "wallet.money_movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["45bc634424662cf77de5f8f44a20488a"])},
  "wallet.payin_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["270cf5d97942938dded3d07f01180a5e"])},
  "wallet.empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["820383d708c94a7456355cee2d3a7415"])},
  "wallet.transfert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b0be74ed47748baceb149f91c2c3997e"])},
  "wallet.transfert_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c36385d76e54ffb7db60ea48520c8a1e"])},
  "wallet.transfert_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c85e8398e814db4c6519accfe2b57352"])},
  "wallet.payout_seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["86886668c72ece12fc5f11c4c03b6825"])},
  "portfolio.folder_contain_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["179901412cef7cd0632d41d6076026cb"])},
  "wallet.bank_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eb7e66571e2c30131d58f4831999912e"])},
  "wallet.bank_payout_in_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["004297023ff5d64333c205f6e7a37b4b"])},
  "wallet.payout_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["096ffcdcbb5d9820e698a9b8fbe5a2ff"])},
  "wallet.payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6f2e15cadd946320d115eb5a73543323"])},
  "wallet.success_payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fd8c05cc186dae2a08f701d600c3bfc1"])},
  "wallet.failed_payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0ae4e5d24160ffcc0e65b31d9648b636"])},
  "wallet.order_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dda1e9aeda456cc956148ec56c7e5f76"])},
  "commons.my_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["156e132b014314b1eae89410e54bcc8c"])},
  "wallet.available_after_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1c35179035e65651ef9fa87863e290b1"])},
  "wallet.payin_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f8a4229f1ec1a544e1d6f0c9e719cde6"])},
  "wallet.transactions_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ef23830471917b96a95ee3b019c55f6a"])},
  "filters.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d010ce53bda9af1d48e4676932cb5c91"])},
  "filters.filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9a407e28c2d74878abfaad7cd8cbf964"])},
  "commons.all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3dd16e13926dddc74d77f098b5d1cc89"])},
  "commons.save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["76b56638af22c17c4b07b74ada232fdb"])},
  "commons.update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["898c079d2e62a4b1971dfbad9d860838"])},
  "commons.error_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9ac1a9bf7bf8ce0a2bb1521042a8be7b"])},
  "register.birth_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3e93ae0a1786a3e4c29625cbbc5fe06b"])},
  "register.birth_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fe13e5df43d66ce4c17675aef3bbbd9b"])},
  "register.company_siret_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ecefe0941710c64aefa99c0ad38ca77c"])},
  "filters.filters_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c051687130ebbc14b040ad4997562f61"])},
  "product_page.orders.anonymous_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dc5c279db854e10ed0fa90ee2260b8a5"])},
  "product_page.orders.pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["262b6734724f9e434c41036dde19a263"])},
  "product_page.orders.pickup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["45aca03790171024ce224d483a2c3fe9"])},
  "product_page.orders.pickup_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["37fff6c8f48927e0d4339d31382b89a9"])},
  "product_page.orders.pickup_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f147a9a9b130e8f912e5615b8639958c"])},
  "product_page.orders.pickup_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5cd7315795de5ff904247c8d8d32a6c1"])},
  "product_page.orders.not_match_preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a4aa430166b3ea44f85b7105ef58e343"])},
  "payment_process.ups_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c0ada82e81811ac1580655f1ab3c9f6d"])},
  "payment_process.ups_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b783218ce93faaf74e8211e0366368bf"])},
  "payment_process.relay_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["95fbf5f12720dd166a0436601e9d8a93"])},
  "payment_process.save_relay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f4cc43d5ae1c095385bd81adcbb1121c"])},
  "settings.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d4700b697a54fbd43a1a613d32694e7f"])},
  "settings.relay_ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6ad180a42612dae65356e4c01f976984"])},
  "settings.buySellPreferences.buying_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0e83964c6ec842ece654d5e80f4a0610"])},
  "settings.buySellPreferences.selling_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["33304ce78003ff519ef7efca42b75f62"])},
  "settings.buySellPreferences.buying_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["474d5867b2d053666f643212df9df11f"])},
  "settings.buySellPreferences.selling_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aeee44649372ca0c9542f2c6ed18a6cc"])},
  "settings.buySellPreferences.country_exclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["91d1805b327ca27100082ad6e817ef72"])},
  "settings.buySellPreferences.country_inclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4393752472f50b6b0d3437ae83d3852b"])},
  "settings.buySellPreferences.preferences_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a987a530c5cf42f3cf83824ab392341d"])},
  "settings.buySellPreferences.save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["af7f48bc1fc3dc6639e367084c2b85bd"])},
  "home.search.search_bottle_bottler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2fe3f2cfd67d7b8278b9f67c36d486b6"])},
  "search.bottler_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1e25b92a1daf4e225a42d629c97ef852"])},
  "orders.update_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a2c0185180501ff6bd65bed1b3ccf908"])},
  "orders.create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6130e0624a2ec2d72a24b8d405190f19"])},
  "payment_process.failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["857c7cf30c21695687b036712e18bea6"])},
  "payment_process.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7d68ce17c0f6276d8eb510b21689cb7a"])},
  "payment_process.payment_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0258fea8b6cfa8a38a56ddaa36c40735"])},
  "payment_process.payment_please_wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5acf2db1dbb8a057474315154123d1a0"])},
  "wallet.send_iban_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6e7cd82919e8c7aa47dd0a3a9c9246c0"])},
  "wallet.wallet_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3285d50e93f8700ae814f1d2fa5dbd76"])},
  "wallet.wallet_iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["99b546dfb3f77b40547a549cae6f4d8f"])},
  "wallet.wallet_bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b368548f6ef0c6c6d4c492750efce132"])},
  "wallet.wallet_await_funds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6d9161cf7949711bb243baa2073566c7"])},
  "wallet.copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3883bb59f804739cfbea2af8ab8203ce"])},
  "wallet.copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b84e3e85f4cf04498edc328cf8a3210f"])},
  "wallet.wallet_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d3fd9e21ea4ace691fbddda4eec215b2"])},
  "commons.copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b84e3e85f4cf04498edc328cf8a3210f"])},
  "commons.copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14e3beb63d4ee12b5829365743d327f0"])},
  "home.without_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["227c982cf8a991a01fcc0188a035105a"])},
  "settings.subscriptions.premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["351e2d571432386dda1cd6f8fe557db1"])},
  "product_page.orders.load_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b41a84d835b640312e7ed9518fca9485"])},
  "product_page.orders.or_drag_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["72949ee9f3f68db3c7c2be12f645126a"])},
  "product_page.orders.file_format_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b05246e965bfc47fe6b062734de91ce9"])},
  "register.step_3.ubo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c9da941361411d29add41340f04cb43e"])},
  "commons.filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["74f2cd5faeaa4e12ca8a86f4dbeb85bb"])},
  "orders.total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5e7dc6e66647737fa73f2086098dcef5"])},
  "orders.total_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0638e06496a66ff1c3abf5a7ac74d4c3"])},
  "orders.search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8cbe8e3fd269074e02c0c9e3f2cdb6e9"])},
  "product_page.orders.pickup_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a2109013a3b5e955e353084a55eccd76"])},
  "subscriptions.already_sub_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["80314bd04583ab7852453bd58233d8fe"])},
  "product_page.orders.kyc_not_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f5e38f88452a8b8a4d7a37ce26a60b6d"])},
  "product_page.orders.kyc_redirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b614a23140d8282c8abc7e35507547ee"])},
  "register.before_kyc.continue_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8accaaf17f5e92dc8a1ed9cffdcab8d5"])},
  "newsfeed.events_show_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15f315d8da658572e72a1a1d9e2e8382"])},
  "newsfeed.events_show_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ecf77ee8d9ce4a85bcb5a6033369f32b"])},
  "register.step_3.verification_timing_individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c2d8f57eefc401d58988f5fb3440217c"])},
  "product_page.release_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7a3ba3cebb0e616c413fa9330ec5b97f"])},
  "product_page.release_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["05ce318dcf1a4dda5cfee4571a50121d"])},
  "search.search_explain_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["738ea6280679f7a8f9b42bb6abf715f5"])},
  "search.search_explain_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c2dd2ac3177b0cfbb500f84a223cffc5"])},
  "search.search_explain_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2b89ac60fef06fd72c91ac7a25e26769"])},
  "search.search_explain_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["48c7103f96add11e6797d571f78b3142"])},
  "register.step_3.kyc_explain_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c1380bcfe84550519c3c97c901590374"])},
  "register.step_3.kycexplain_content_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["102abd54f66716ca555232813743e431"])},
  "register.step_3.kyc_explain_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["62f9e1a642f688157b401deed8bea43c"])},
  "register.step_3.kycexplain_content_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d1786f69f844ecb75c6dd17bb472afa9"])},
  "register.step_3.kyc_explain_title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6dbfafbd0852853679297a373f03f382"])},
  "register.step_3.kycexplain_content_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ec58dc85eb7eede6bac61fa08147a461"])},
  "login.password_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["903632f4baf3791b5ad863f02de3a6d0"])},
  "login.wrong_values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b047c0db217ac5c0030c45ea867b98e6"])},
  "login.wrong_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6a0731418a3936c04ec8ae0453a68131"])},
  "commons.orders_alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eadf43d5ddb5c0c34edb04c2aae72ccf"])},
  "product_page.orders.create_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["af0d48770afa6813448f68ac046c6eda"])},
  "product_page.orders.create_alert_explain_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d1b9ed6b1d440fde5e8132af0147d0c3"])},
  "product_page.orders.create_alert_explain_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6fa1f8137aaf889754bfd8d80acc38a7"])},
  "product_page.orders.create_alert_explain_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d3e0f21844b119d67af45fb9b50ac9a7"])},
  "product_page.orders.create_alert_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c176f2a837a66eba860532ec72643d27"])},
  "product_page.orders.order_alert_price_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["75d7333e292125fb7519561a1b54fccb"])},
  "product_page.orders.order_alert_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30890133afbf0b698b06e6656ff3c34a"])},
  "product_page.orders.order_alert_errored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b87d08aeea0da14f078f79a8107d2a6c"])},
  "product_page.orders.order_alert_created_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["502ab7a092bb85a4a13987301dbedef2"])},
  "product_page.orders.order_alert_paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["39594e29a7f4a8880a256d09db45f707"])},
  "product_page.orders.order_alert_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b03b6c433f6d3ec944a3e65ab5feef31"])},
  "product_page.orders.disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6936b619b2c887b2f45d6841af37e096"])},
  "search.search_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["37957ed93178b03f387964e5ab38404b"])},
  "commons.min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5b55621ff5cbf47f41f0df249f092863"])},
  "commons.max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["69fa1a7bda1c1ce56753b5fd08134f9d"])},
  "register.step_3.documents_min_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a31ba59819121aff82cead01bdb60a9a"])},
  "register.step_3.documents_min_passeport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1c89a547e824865f6c71d4fa75a4b584"])},
  "register.step_3.refuse_generic_kyc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["51f85ee111489f4dab6bdfba911e1bcf"])},
  "commons.error_503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["443ce10f9f9aa13597488cf9eb8e1357"])},
  "product_page.orders.blocktrade_link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a47ea75fcaaca739c8996d9acb17cd5f"])},
  "product_page.orders.blocktrade_countdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["73b0ad98451a73a2879b9997a049df50"])},
  "product_page.orders.success_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c75845c3a3a165714ea527c9bc24b058"])},
  "product_page.orders.wrong_value_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0fb3c41457cb50f3b1cc12e1123bbf51"])},
  "product_page.orders.bottle_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0e7294adfd194e0c70d7fd5947166a22"])},
  "product_page.orders.package_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["00c588976fa192005da429e72317f320"])},
  "product_page.orders.add_bottle_basket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d1a5b059808daab36b2f53d5228ef6bc"])},
  "product_page.orders.choose_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3a2a62d5a38a0477638473583e25f9e7"])},
  "product_page.orders.duplicate_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5aeb6da35e0db2354463279e5edf45f2"])},
  "product_page.orders.maximum_sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["164f667070d992b487d9a746fe7d805e"])},
  "payment_process.add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b6f0b163c605d974c0a6817e15f99973"])},
  "payment_process.remove_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0f48a45c1324748400e1bd7a02d10dcc"])},
  "wallet.instant_payout_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4b5e3fe04274d0cfa36a9b64bd98a913"])},
  "wallet.available_amount_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["47d788fb8c824077aee722736a3770a6"])},
  "wallet.banking_account_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2d1c85ef246f7b5a3f05d6109656c1b7"])},
  "home.intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c0d1dc17b5bcd4afaee1cd804a996f29"])},
  "home.create_account_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["204a3e9af3b9784487eb430c2892cf81"])},
  "home.newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["acbce0aa9ffc6687228e4cd4c3bff381"])},
  "home.newsfeed_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dda89cbec68e127cb5fac2338a733301"])},
  "home.blog_and_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f52f26a9dfee45056cfd47cdf8c7e2cb"])},
  "home.blog_and_review_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c4c426fecee1e8afa86e6b0fb0b1e500"])},
  "home.events_browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3311e438d2a89a0a7ef45b749384ecf2"])},
  "home.events_browser_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6366de50c439b0af0702c320bf445e4e"])},
  "ratings.ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d1ec602319f9da672ff7e6e84f8ec53d"])},
  "ratings.ratings_phone_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["458659dd4013ae13d03fa841a339233f"])},
  "ratings.score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3b0649c72650c313a357338dcdfb64ec"])},
  "ratings.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b5a7adde1af5c87d7fd797b6245c2a39"])},
  "ratings.description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8fca5a2a1b0b856776970a62529accc9"])},
  "ratings.create_error_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c9713ee22b607e146b3ad933bbbd9a76"])},
  "ratings.create_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8a8e7f6c6a7d9f40f2138de076fc3468"])},
  "ratings.create_btn_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["69c4f4460d6cc76093a9ac0b9b24ebd8"])},
  "ratings.see_or_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["394b8cc7ca76a881c3159bf2d13d863d"])},
  "ratings.update_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dd6583c5a0ba3f30cf744c977703dde0"])},
  "ratings.delete_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["31a397602ddc3a9ee37966f08653479a"])},
  "ratings.rating_explain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d8f04fe17f6ae60d17849b02af1d836b"])},
  "ratings.search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3fdaa8f42087e3d5d56598676682d652"])},
  "ratings.description_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ef7508ac39fec6806f0665bbd89a4764"])},
  "ratings.not_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bec9bc8c31fe276c06c9522bb12ae5c2"])},
  "ratings.rating_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["39301e2158062604e786a4eeea7b01b1"])},
  "ratings.pseudo_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["be6338fa3a98f5087b7ad0649bc4f36d"])},
  "ratings.empty_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dbe25bd31762806aca9b0115a3a79d58"])},
  "ratings.ranking_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2f157ae7d8bf900a94e22d1dbbc5896d"])},
  "ratings.ranking_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0718d2a745f45361cb1a89c091d684b0"])},
  "ratings.ranking_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d0ec622aba11eed8177d363d934a4c84"])},
  "ratings.ranking_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["55994358f05fed2190beb6ddda38b881"])},
  "ratings.ranking_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["803f430a6a82b6778c2f8d7d80d150f6"])},
  "ratings.ranking_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9083684d0ffef4b2c420d2d66af190ae"])},
  "ratings.ranking_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9b6b41fc0cedd7c17add1c5862209ab1"])},
  "ratings.rewards_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["58e54641aee109e9ee13ebd642a9c737"])},
  "product_page.orders.add_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["f26f84071e91185f2442e0811794aec3"])},
  "product_page.orders.sell_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dcf90959ad83a8131f1f9b55b81ed937"])},
  "product_page.orders.min_pictures_required_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["45dfc0243b915628a04ef2728c81efce"])},
  "product_page.orders.min_pictures_required_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["88c9885551b694f5105561441a05aa24"])},
  "ratings.pseudo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["798cbab92e12849302dd4ac8992e2bfc"])},
  "settings.update_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b7d1a738645fa1860e076607424eafd9"])},
  "settings.wrong_pseudo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d5e314946658c52ba770b2e8af428700"])},
  "settings.catch_update_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cc66f69dbba3ff26e3c9767aca4dd5fe"])},
  "settings.pseudo_already_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["b734fdd18b0a456fe89ab73a37d8a216"])},
  "settings.update_pseudo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["496fe4b917b5c594eafc30dde2c06d41"])},
  "ratings.private_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11274cad81fe02b1a026c0a3871514b9"])},
  "ratings.profil_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["820c6916ebfb13d1738ab6a13cf0a69b"])},
  "ratings.rating_unitary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["47476a0e9bea1d2f13392fe538869481"])},
  "ratings.rating_multi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["562a50071799562050653385208e72a6"])}
}