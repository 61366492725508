export default {
  "commons": {
    "buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar y vender"])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercambio"])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocktrade"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartera"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Región"])},
    "bid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferta"])},
    "ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregunte a"])},
    "giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dador"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomador"])},
    "report_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informar de un error"])},
    "account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de cuenta"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedidos"])},
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedidos actuales"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitar"])},
    "product_origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origen del producto"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexión"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesión"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una cuenta"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar y negociar"])},
    "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscripciones"])},
    "dark_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modo nocturno"])},
    "light_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modo transparente"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historial de transacciones"])},
    "portfolio_gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartera"])},
    "matchs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partidos"])},
    "transactions_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historial de transacciones"])},
    "account_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración de la cuenta"])},
    "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activado"])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desactivado"])},
    "alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificaciones"])},
    "alerts_clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar todo"])},
    "alerts_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay notificaciones por el momento"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione"])},
    "no_copy_paste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se puede hacer clic con el botón derecho del ratón"])},
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrega"])},
    "secure3d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprobar la transacción"])},
    "cancel_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
    "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IVA"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "too_many_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hace demasiadas solicitudes"])},
    "see_products_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha alcanzado su límite de consultas diarias. Compruebe su cuenta para evitar más restricciones."])},
    "check_inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compruebe su entrada:"])},
    "generic_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error, inténtelo de nuevo más tarde"])},
    "login_retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuelva a conectarse"])},
    "email_phone_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, valide su correo electrónico y teléfono antes de realizar esta acción"])},
    "not_authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No está autorizado a realizar esta acción"])},
    "service_unaivalable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio no disponible, póngase en contacto con el servicio de atención al cliente"])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noticias"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
    "lang": {
      "lang_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elija un idioma"])},
      "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR"])},
      "fr_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francés"])},
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ES"])},
      "en_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Español"])},
      "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ES"])},
      "es_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Español"])},
      "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TI"])},
      "it_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italiano"])},
      "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PL"])},
      "pl_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polaco"])},
      "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DE"])},
      "de_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alemán"])},
      "cs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CS"])},
      "cs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checa"])},
      "da": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DA"])},
      "da_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danés"])},
      "el": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EL"])},
      "el_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Griego"])},
      "hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HU"])},
      "hu_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Húngaro"])},
      "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JA"])},
      "ja_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japonés"])},
      "ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KO"])},
      "ko_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coreano"])},
      "pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PT"])},
      "pt_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugués"])},
      "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REINO UNIDO"])},
      "ru_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruso"])},
      "sk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SK"])},
      "sk_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eslovaquia"])},
      "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZH"])},
      "zh_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["China"])}
    },
    "refuse_match_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechazar el partido"])},
    "date_expire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de caducidad"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
    "price_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El precio incluye el IVA"])},
    "inputs_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compruebe que ha rellenado correctamente todos los campos obligatorios"])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informar de un problema"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprador"])},
    "seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendedor"])},
    "code_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código enviado"])},
    "code_time_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenga en cuenta que el código es válido durante 15 minutos."])},
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artículos"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesional"])},
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Particulares"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monedero"])},
    "my_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monedero"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
    "error_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compruebe su entrada o póngase en contacto con el servicio de atención al cliente"])},
    "copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar"])},
    "copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copia con éxito"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrar :"])},
    "orders_alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alertas"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min :"])},
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max :"])},
    "error_503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La acción ha sido cancelada por haberse superado el tiempo de espera. Por favor, inténtelo de nuevo"])}
  },
  "major": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe ser mayor de 18 años para visitar este sitio"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, compruebe su edad para entrar."])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soy mayor de 18 años"])},
    "nok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soy menor de 18 años"])},
    "conditions_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al acceder a este sitio, usted acepta la"])},
    "conditions_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["condiciones de uso"])},
    "conditions_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["y"])},
    "conditions_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["política de privacidad."])}
  },
  "home": {
    "revolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una revolución en sí misma"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La referencia para comprar, vender e intercambiar sus bebidas espirituosas"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanto si es un amante del whisky, un coleccionista de coñac o un profesional en busca de la perfección, La plataforma de intercambio es para usted. Seguridad óptima, transparencia total y comisiones reducidas: descubra el comercio de bebidas espirituosas con total tranquilidad."])},
    "secure_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacciones seguras"])},
    "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comisiones reducidas 2,5% IVA incluido"])},
    "verified_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuentas gratuitas 100% verificadas"])},
    "title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanto si es un particular como un profesional, explore nuestro conjunto de más de 70 fuentes de precios sobre miles de referencias de bebidas espirituosas."])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cree su cuenta gratuita sin suscripción"])},
    "buy_and_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compra y venta: sus transacciones son seguras y transparentes"])},
    "buy_and_sell_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La plataforma de intercambio facilita la compra y venta continuas. Acceda fácilmente a todas las ofertas disponibles gracias a nuestras referencias normalizadas, que facilitan la comparación y garantizan la transparencia. Encuentre lo que busca o manifieste su interés: TEP le permite hacer coincidir sus necesidades con las búsquedas de las contrapartes, tanto si compra como si vende. Ahorre tiempo, optimice sus transacciones y negocie siempre al mejor precio. Con una interfaz intuitiva y transacciones seguras, TEP simplifica el comercio de bebidas espirituosas, ofreciendo una experiencia rápida, fiable y eficaz tanto para aficionados como para profesionales."])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercambio de botellas: seguro y garantizado sin riesgo de contrapartida"])},
    "swap_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEP es la única plataforma que ofrece la posibilidad de intercambiar botellas con total seguridad gracias a nuestras soluciones de garantía en efectivo o depósito en almacén. Este sistema garantiza una protección total: no hay riesgo de contrapartida, porque el dinero está asegurado con nuestro proveedor de servicios de pago hasta que se confirmen las respectivas entregas de las botellas. Esta característica está revolucionando el mercado de las bebidas espirituosas al ofrecer una transparencia y fiabilidad sin precedentes. Tanto si es coleccionista como entendido, Exchange le permite ampliar u optimizar su colección sin preocuparse por la seguridad de las transacciones. Intercambie sus espirituosos con total tranquilidad, en un entorno moderno y seguro."])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block Trade: Asegure sus transacciones externas con facilidad"])},
    "blocktrade_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block Trade, una función exclusiva de TEP, le permite asegurar sus transacciones de compra/venta en unos pocos clics. Gracias a esta innovación, se genera un enlace único para cada transacción, lo que garantiza una ejecución fiable y segura. Tanto si compra como si vende, Block Trade elimina los riesgos asociados a los intercambios externos directos proporcionando un marco transparente y seguro. También incorpora herramientas avanzadas para confirmar los términos de la transacción, verificar las cuentas y garantizar una ejecución fluida en cada fase. Descubra una nueva forma de negociar sus espíritus, con la tranquilidad y fiabilidad que ofrece TEP."])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de carteras: una revolución para coleccionistas e inversores"])},
    "portfolio_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para los más experimentados, la plataforma de intercambio ofrece una herramienta única de gestión de carteras. Esta función le permite controlar sus posiciones y su valor en tiempo real utilizando los precios en directo de la plataforma, combinados con nuestros datos históricos detallados. Se acabaron las preguntas interminables sobre el valor de su colección. Con una visión objetiva y agnóstica sin precedentes, podrá seguir la evolución de sus activos y tomar decisiones con conocimiento de causa. Tanto si está evaluando su colección como planificando transacciones estratégicas, la herramienta de gestión de carteras de TEP transforma su experiencia al proporcionarle una transparencia total y un control óptimo sobre sus espíritus."])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precios históricos: transparencia inigualable"])},
    "price_history_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En TEP, hemos recopilado el historial de precios más completo de todas las bebidas espirituosas posibles, ¡con datos que se remontan a 2002! Gracias a la agregación de 70 fuentes diferentes, complementadas con nuestras propias transacciones, ofrecemos una visión transparente y agnóstica de los precios realizados en el pasado. Todos estos datos están cuidadosamente normalizados y disponibles en 18 divisas diferentes, lo que permite a nuestros usuarios comparar y analizar las tendencias del mercado a escala mundial. Esta funcionalidad única es una herramienta indispensable para coleccionistas, inversores y profesionales que deseen tomar decisiones con conocimiento de causa, ya sea comprando, vendiendo o simplemente evaluando su cartera de bebidas espirituosas."])},
    "title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una garantía de confianza"])},
    "secure_transactions_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los pagos se realizan a través de nuestro proveedor de servicios de pago Mangopay para garantizar transacciones fluidas, sin riesgo de que el dinero desaparezca nunca lo enviará a la contraparte."])},
    "low_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las comisiones más bajas del mercado"])},
    "low_fees_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenemos las comisiones más bajas del mercado, 2,5% IVA incluido para el comprador y el vendedor, sin comisiones de cotización ni de reserva. Usted sólo paga si hay una transacción efectiva."])},
    "anonymous_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonimato previo a la transacción"])},
    "anonymous_transactions_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanto si es un particular como un profesional, nuestra plataforma es la solución a todos los problemas actuales gracias a su anonimato pretransaccional."])},
    "verified_accounts_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gracias al control del registro por parte de nuestro proveedor de servicios de pago antes de la validación de la cuenta, ninguna cuenta fantasma, estafador o bot puede entrar en la plataforma. La trazabilidad y la seguridad son totales."])},
    "title_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una oferta adaptada a sus necesidades"])},
    "join_community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Únase a una comunidad para encontrar, gestionar e intercambiar sus bebidas espirituosas."])},
    "search": {
      "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de bebidas espirituosas"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione"])},
      "bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botella"])},
      "search_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker, nombre, EAN, palabras clave..."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar en"])},
      "search_bottle_bottler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar en"])}
    },
    "without_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negociación disponible sin suscripción."])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanto si es aficionado, coleccionista o profesional, La plataforma de intercambio está hecha para usted: seguridad óptima, transparencia total y bajos costes de transacción, con 70 fuentes de precios históricos que cubren miles de referencias de botellas."])},
    "create_account_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descubra el comercio de bebidas espirituosas con total tranquilidad."])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsfeed: su centro de noticias sobre bebidas espirituosas"])},
    "newsfeed_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manténgase al día de las últimas noticias del sector, nuevos lanzamientos, acontecimientos importantes y tendencias del mercado a través del Newsfeed de TEP. Esta función le permite seguir en tiempo real todo lo que ocurre en el mundo de las bebidas espirituosas, con información de los sitios más relevantes y reconocidos del sector. El Newsfeed le ofrece una visión completa y agnóstica de las noticias, para que pueda mantenerse al tanto de la evolución del mercado en un solo lugar."])},
    "blog_and_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog & Review: su espacio de análisis y descubrimiento"])},
    "blog_and_review_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore contenidos de degustación gratuitos con el Blog & Review de TEP. Con reseñas de expertos y entusiastas del sector, el Blog & Review le da acceso a perspectivas únicas y recomendaciones de confianza. Tanto si busca descubrir nuevas botellas como comprender las tendencias del mercado o ampliar sus conocimientos, este feed está diseñado para enriquecer su experiencia en el mundo de las bebidas espirituosas."])},
    "events_browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navegador de eventos: su guía de eventos sobre bebidas espirituosas"])},
    "events_browser_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con el Navegador de Eventos gratuito de TEP, nunca se perderá otra cita importante del mundo de las bebidas espirituosas. Entérate de ferias y eventos exclusivos estés donde estés. Gracias a una cuidada selección actualizada, el Navegador de Eventos te acerca los acontecimientos más relevantes del sector, ya seas coleccionista, inversor o simplemente aficionado. Planifique sus salidas y disfrute de experiencias únicas en torno a las bebidas espirituosas."])}
  },
  "newsfeed": {
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog y reseña"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noticias"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventos"])},
    "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer filtros"])},
    "event_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "sort_asc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordenar por orden alfabético"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechas"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes"])},
    "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de bebidas espirituosas"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar..."])},
    "events_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrar eventos"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de inicio"])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de finalización"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en"])},
    "events_show_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["próximos eventos"])},
    "events_show_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["próximos eventos"])}
  },
  "subscriptions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscripciones anuales"])},
    "price_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifas anuales"])},
    "price_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifas mensuales"])},
    "free_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 mes gratis en un pago anual"])},
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferta especial"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferta profesional"])},
    "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precios históricos de las bebidas espirituosas"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para usuarios que desean datos históricos completos sin desfase temporal."])},
    "all_taxs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])},
    "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["al mes"])},
    "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["al año"])},
    "per_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["por tipo de bebida espirituosa"])},
    "references_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso a todas las referencias"])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precios históricos sin desfase temporal"])},
    "advanced_stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estadísticas avanzadas"])},
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscribirse a una bolsa de bebidas espirituosas"])},
    "choice_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selección de bebidas espirituosas :"])},
    "wine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vinos"])},
    "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otros"])},
    "complementary_module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Módulo complementario"])},
    "portfolio_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para coleccionistas experimentados que desean más datos y una verdadera herramienta de gestión."])},
    "portfolio_subtitle_pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Únase a una comunidad de especialistas para analizar, gestionar y negociar sus bebidas espirituosas."])},
    "advanced_gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión avanzada de carteras"])},
    "choice_this_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elija esta oferta"])},
    "add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir a la cesta"])},
    "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cesta"])},
    "see_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago mensual"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago anual"])},
    "sub_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
    "next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente muestreo"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "include_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IVA (20%)"])},
    "payment_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceder al pago"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar"])},
    "already_in_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta suscripción ya está en su cesta"])},
    "already_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya está abonado"])},
    "cart_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su cesta está vacía"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a suscripciones"])},
    "contact_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de contacto"])},
    "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturación"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forma de pago"])},
    "payment_method_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las transacciones en la plataforma de intercambio se realizan a través de nuestro proveedor de servicios de pago Mangopay."])},
    "banking_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjeta de crédito"])},
    "card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de tarjeta"])},
    "card_fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre en la tarjeta de pago"])},
    "card_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de vencimiento (MM/AA)"])},
    "card_code_security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de seguridad"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalice su compra"])},
    "soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximamente"])},
    "recap_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumen de pagos"])},
    "buy_commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comisión de compra"])},
    "buy_commission_tva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IVA Comisión de compra"])},
    "already_sub_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonado"])}
  },
  "payment": {
    "cvx_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El código de verificación de la tarjeta (en el reverso de la tarjeta, generalmente 3 dígitos"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha tenido en cuenta la cancelación"])},
    "cancel_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelación fallida, póngase en contacto con el servicio de atención al cliente"])}
  },
  "login": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenido a la plataforma de intercambio"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceda a un entorno de negociación fluido, seguro y normalizado."])},
    "save_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar mis datos de acceso"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrarse"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Ha olvidado su contraseña?"])},
    "forgot": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer contraseña"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibirá un correo electrónico con instrucciones para restablecer su contraseña."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca su dirección de correo electrónico"])}
    },
    "reset": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer contraseña"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibirá un correo electrónico con instrucciones para restablecer su contraseña."])},
      "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva contraseña"])},
      "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar contraseña"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de verificación"])}
    },
    "doubleFA": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doble autenticación"])},
      "code_reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca el código recibido por correo electrónico o teléfono"])}
    },
    "password_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su contraseña debe contener 7 caracteres con al menos una letra mayúscula."])},
    "wrong_values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, rellene todos los campos correctamente"])},
    "wrong_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca un código válido"])}
  },
  "register": {
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una cuenta"])},
    "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civismo"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de nacimiento"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nacionalidad"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Región"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
    "address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de la calle"])},
    "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código postal"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
    "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar contraseña"])},
    "mangopay_accept_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acepto el"])},
    "mangopay_accept_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["condiciones de uso"])},
    "mangopay_accept_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de Mangopay."])},
    "tep_accept_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acepto el"])},
    "tep_accept_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["condiciones de uso"])},
    "tep_accept_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["y la"])},
    "tep_accept_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["política de privacidad"])},
    "tep_accept_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de La plataforma de intercambio."])},
    "newsletter_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscríbase a nuestro boletín"])},
    "newsletter_enter_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca su dirección de correo electrónico"])},
    "create_and_continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear mi cuenta gratuita y continuar"])},
    "end_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completar mi registro"])},
    "verify_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtener una cuenta verificada"])},
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la empresa"])},
    "company_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección completa"])},
    "company_zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código postal"])},
    "company_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "company_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
    "company_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico profesional"])},
    "company_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de empresa"])},
    "company_siret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de empresa"])},
    "company_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de IVA"])},
    "company_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi empresa"])},
    "company_siret_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["123 456 789 00010"])},
    "step": {
      "step_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información general"])},
      "step_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmación 2FA"])},
      "step_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprobación KYC"])},
      "step_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos bancarios"])},
      "step_1_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información"])},
      "step_4_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos bancarios"])}
    },
    "step_0": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceda a un entorno de negociación fluido, seguro y normalizado."])},
      "usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione su uso"])},
      "account_no_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El tipo de cuenta no puede cambiarse una vez completado el registro."])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Particulares"])},
      "professionnal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesional"])},
      "individual_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta para uso personal"])},
      "professionnal_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta profesional verificada"])}
    },
    "step_1": {
      "general_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información general"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rellene todos los campos siguientes"])},
      "personnal_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta privada"])},
      "business_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta de empresa"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione"])},
      "business_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría profesional"])},
      "business_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forma jurídica"])},
      "business_part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los propietarios beneficiarios que posean más del 25% ?"])},
      "business_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indique los datos de otros beneficiarios efectivos que posean más del 25%."])},
      "add_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir"])},
      "birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nació el"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reside en"])},
      "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" y nacionalidad"])},
      "password_requirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La contraseña debe contener al menos 8 caracteres, incluyendo una mayúscula, una minúscula, un número y un carácter especial."])}
    },
    "step_2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmación de su correo electrónico y número de teléfono"])},
      "subtitle_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca el código recibido por correo electrónico"])},
      "subtitle_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca el código recibido por teléfono"])},
      "email_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se le ha enviado un correo electrónico."])},
      "phone_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se le ha enviado un SMS."])},
      "no_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿No ha recibido el código?"])},
      "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar a"])},
      "validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmado"])},
      "await_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A la espera de confirmación"])},
      "code_err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca un código válido"])}
    },
    "before_kyc": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enhorabuena, ya tiene acceso gratuito a muchas de las funciones del sitio."])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Desea verificar su identidad ahora para disfrutar de la experiencia completa?"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtener una cuenta verificada"])},
      "continue_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtenga una cuenta verificada gratuita"])}
    },
    "step_3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificación de su identidad"])},
      "document_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione el tipo de documento"])},
      "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasaporte"])},
      "identity_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carné de identidad"])},
      "import_file_passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importe una foto de su pasaporte"])},
      "import_file_identity_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importe una foto de las dos caras de su documento de identidad"])},
      "choice_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione un archivo"])},
      "condition_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivo claro y legible"])},
      "condition_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamaño máximo del archivo: 7 Mb"])},
      "condition_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato JPG, JPEG o PNG"])},
      "validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar para verificación"])},
      "verification_timing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El tiempo medio de verificación es de unas 24 horas."])},
      "active_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funcionalidad limitada"])},
      "description_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete el proceso de registro y acceda a todas las funciones."])},
      "documents_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envío de documentos"])},
      "verification_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualmente se está comprobando"])},
      "verification_in_progress_restriction_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puede buscar botellas y ver precios históricos."])},
      "verification_in_progress_restriction_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todavía no se puede acceder a la parte transaccional ni contratar suscripciones."])},
      "send_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviado el"])},
      "kbis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extracto Kbis o documento equivalente con menos de tres meses de antigüedad"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copia de los estatutos"])},
      "passport_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasaporte o documento de identidad, por ambas caras"])},
      "document_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento enviado:"])},
      "documents_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentos enviados:"])},
      "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechazado"])},
      "refused_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo de la denegación:"])},
      "IDENTITY_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identidad personal"])},
      "REGISTRATION_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identidad corporativa"])},
      "ARTICLES_OF_ASSOCIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estatutos"])},
      "SHAREHOLDER_DECLARATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declaración de accionistas"])},
      "ADDRESS_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificación de direcciones"])},
      "payment_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceder al pago"])},
      "error_card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de tarjeta no válido"])},
      "error_card_fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre y apellidos en la tarjeta no válida"])},
      "error_card_expiration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de caducidad no válida"])},
      "error_card_cvx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de tarjeta de crédito no válido"])},
      "title_payment_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasas KYC para empresas"])},
      "kyc_documents_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los documentos se han enviado correctamente. La validación tendrá lugar en un plazo de 24 horas."])},
      "ubo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declaración de beneficiarios efectivos"])},
      "verification_timing_individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El tiempo medio de verificación es de sólo unos minutos"])},
      "kyc_explain_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haz fotos claras y nítidas:"])},
      "kycexplain_content_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asegúrese de que el documento de identidad o pasaporte sea totalmente visible, sin sombras ni reflejos, y que todos los detalles sean legibles."])},
      "kyc_explain_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar en un formato compatible:"])},
      "kycexplain_content_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilice un formato JPEG, JPG o PNG, con suficiente resolución para que la información sea claramente visible."])},
      "kyc_explain_title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulte el documento completo :"])},
      "kycexplain_content_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asegúrese de que todas las páginas necesarias se incluyen por separado."])},
      "documents_min_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe añadir 2 pruebas de identidad"])},
      "documents_min_passeport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es necesario añadir un recibo para el Pasaporte"])},
      "refuse_generic_kyc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su nombre de archivo o el tamaño máximo de 7 MB por documento se ha superado"])}
    },
    "step_4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos bancarios"])},
      "why_enter_banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca sus datos bancarios para recibir el dinero de sus ventas."])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sus datos serán validados por nuestro servicio de atención al cliente. Recibirá un correo electrónico cuando sus datos bancarios hayan sido validados."])},
      "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "iban_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XX11 0000 0000 0000 000"])},
      "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC/ABA"])},
      "bic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC, número de ruta..."])},
      "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Región"])},
      "region_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, rellene"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
      "mangopay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las transacciones en la plataforma de intercambio se realizan a través de nuestro proveedor de servicios de pago Mangopay."])},
      "banking_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sus datos bancarios se han guardado"])},
      "banking_already_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sus datos bancarios ya han sido enviados"])}
    },
    "success": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enhorabuena. Su cuenta ya está verificada."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar botellas"])},
      "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceder a mi cartera"])},
      "unlock_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modo activo ilimitado"])},
      "unlock_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya puede acceder a todas las funciones."])}
    },
    "us_phone_regulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acepto recibir mensajes de La Plataforma de Intercambio en el número de teléfono arriba indicado. Pueden aplicarse tarifas de datos, responda a STOP para cancelar la suscripción."])},
    "us_phone_regulation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si rechaza los mensajes SMS 2FA, no podrá confirmar su cuenta. Siempre puede darse de baja después de confirmar su número de teléfono a través de nuestro sistema SMS 2FA."])},
    "birth_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lugar de nacimiento"])},
    "birth_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País de nacimiento"])},
    "company_siret_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número SIRET"])}
  },
  "search": {
    "barcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escanee o escriba el código de barras"])},
    "last_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Búsquedas recientes"])},
    "delete_all_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar todo"])},
    "find_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["producto expuesto"])},
    "find_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productos expuestos"])},
    "total_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["producto encontrado con esta búsqueda"])},
    "total_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productos encontrados con esta búsqueda"])},
    "total_product_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["producto encontrado"])},
    "total_products_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productos encontrados"])},
    "empty_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Le falta una botella?"])},
    "filter_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrar por"])},
    "see_composite_ticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todo"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vender"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomador"])},
    "giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dador"])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comercio en bloque"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "no_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se han encontrado botellas que coincidan con su búsqueda."])},
    "history_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver historial de búsqueda"])},
    "no_auth_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cree una cuenta para obtener acceso ilimitado"])},
    "no_auth_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regístrese gratis"])},
    "connect_access_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es necesario iniciar sesión para acceder a esta función"])},
    "empty": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botella desaparecida"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la botella"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si es necesario, especifique los detalles de la botella que falta"])},
      "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcional"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
      "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.thexchangeplatform.io"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
      "missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se han encontrado resultados."])},
      "description_placeholder_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puede describir los motivos de rechazo"])}
    },
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedidos actuales"])},
    "bottler_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página del embotellador"])},
    "search_explain_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puede utilizar combinaciones de palabras clave."])},
    "search_explain_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La búsqueda no distingue entre mayúsculas y minúsculas."])},
    "search_explain_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No es necesario especificar \"años\"."])},
    "search_explain_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escribir una categoría genérica de bebidas alcohólicas (ron, whisky, coñac, etc.) no producirá ningún resultado."])},
    "search_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compruebe que tiene al menos una palabra clave en la barra de búsqueda."])}
  },
  "product_page": {
    "add_to_portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir a la cartera"])},
    "btn_buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar/Vender"])},
    "btn_swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercambio"])},
    "btn_blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comercio en bloque"])},
    "product_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información sobre botellas"])},
    "ticker_composite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker compuesto"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título"])},
    "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de bebidas espirituosas"])},
    "distillery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destilería"])},
    "bottler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embotelladora"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edad"])},
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marca"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abv"])},
    "vintage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vintage"])},
    "bottling_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Año de embotellado"])},
    "bottle_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenido"])},
    "num_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de botella"])},
    "num_bottles_produced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de botellas producidas"])},
    "base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base"])},
    "production_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Método de producción"])},
    "distillery_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado de la destilería"])},
    "classification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clasificación"])},
    "empty_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay pedido para esta botella"])},
    "composite_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker compuesto"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactivo"])},
    "search_add_bottle_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar y añadir una botella al gráfico"])},
    "isbn_ticker_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN, ticker o nombre del producto"])},
    "bottle_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha encontrado la botella que busca"])},
    "trades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventas"])},
    "sources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuentes"])},
    "empty_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay imagen disponible para esta referencia"])},
    "cask_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de barril"])},
    "show_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver precios históricos"])},
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedidos en directo"])},
    "number_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de botellas"])},
    "orders": {
      "buy_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar"])},
      "sell_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventas"])},
      "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una orden"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Particulares"])},
      "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro"])},
      "mix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mezclar"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay pedidos por el momento."])},
      "buy_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orden de compra"])},
      "buy_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio de compra"])},
      "buy_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
      "buy_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciones"])},
      "buy_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Programa una fecha y hora de finalización?"])},
      "buy_create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una orden de compra"])},
      "sell_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orden de venta"])},
      "sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
      "sell_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peso"])},
      "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longitud"])},
      "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anchura"])},
      "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altura"])},
      "sell_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir fotos"])},
      "sell_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciones"])},
      "sell_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Programa una fecha y hora de finalización?"])},
      "sell_create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una orden de venta"])},
      "success_create_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orden creada con éxito"])},
      "ft_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reglas que debe observar"])},
      "buy_ft_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evite inundar innecesariamente el libro de órdenes"])},
      "buy_ft_1_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite sus órdenes a transacciones reales."])},
      "buy_ft_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evite rechazos repetidos"])},
      "buy_ft_2_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las negativas reiteradas a una coincidencia pueden percibirse como malintencionadas o irresponsables. Asegúrese de que sólo realiza transacciones si tiene intención de completarlas."])},
      "buy_ft_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respetar los plazos de recuperación"])},
      "buy_ft_3_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No recoger los paquetes a tiempo en repetidas ocasiones puede acarrear molestias para el vendedor y costes adicionales."])},
      "buy_ft_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respetar la integridad del libro de órdenes"])},
      "buy_ft_4_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amañar o manipular el libro de órdenes, desactivar un interés después de que se haya rechazado una coincidencia, etc., se consideran prácticas engañosas. Asegúrese de que sus acciones sean transparentes."])},
      "buy_ft_5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prepárese para los costes asociados"])},
      "buy_ft_5_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pueden aplicarse impuestos locales de importación y aduanas. Asegúrese de estar preparado para pagarlos al concluir una transacción."])},
      "sell_ft_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proporcionar información precisa"])},
      "sell_ft_1_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asegúrese de que la descripción de la botella es exacta y veraz. Esto ayuda a los compradores a tomar una decisión con conocimiento de causa. Facilite una foto completa del anverso y el reverso."])},
      "sell_ft_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respetar los detalles de la oferta"])},
      "sell_ft_2_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si se compromete a vender una botella, cumpla este compromiso. Cualquier oferta que no se cumpla tendrá consecuencias para el vendedor."])},
      "sell_ft_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garantizar la calidad del envase y su contenido"])},
      "sell_ft_3_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las entregas repetidas de paquetes dañados (deterioro, rotura, fuga) y con un contenido incorrecto le afectan. Asegúrese de embalar sus botellas con cuidado."])},
      "sell_ft_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respetar los plazos de entrega"])},
      "sell_ft_4_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El incumplimiento reiterado de los plazos de envío puede afectar a su acceso a la zona de transacciones. Planifique en consecuencia para evitar retrasos."])},
      "create_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear orden de compra"])},
      "create_sell_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una orden de venta"])},
      "create_taker_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una orden de intercambio"])},
      "create_giver_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una orden de intercambio"])},
      "create_blocktrade_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una operación en bloque"])},
      "weight_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unidad de peso"])},
      "size_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamaño de la unidad"])},
      "success_create_dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su solicitud ha sido procesada. En breve nos pondremos en contacto con usted."])},
      "package_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referencia del paquete:"])},
      "dispute_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puede detallar aquí su problema para facilitar la resolución del ticket"])},
      "confirm_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar la recepción del paquete"])},
      "confirm_shipment_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al confirmar la recepción del paquete, confirma que el producto cumple sus expectativas."])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activos"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suprimido"])},
      "match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match"])},
      "match_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En espera"])},
      "ship_send_pending_buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envío"])},
      "ship_send_pending_seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar a"])},
      "delivery_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En tránsito"])},
      "delivery_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entregado"])},
      "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Litigios"])},
      "end_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completado"])},
      "update_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
      "shipping_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles de la entrega"])},
      "track_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de seguimiento"])},
      "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De pago"])},
      "track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguir mi paquete"])},
      "shipping_details_see": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver detalles de la entrega"])},
      "in_transit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En entrega"])},
      "order_create_block_by_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acepte o rechace sus propuestas antes de crear una nueva orden"])},
      "blocktrade_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlace a Blocktrade"])},
      "blocktrade_unique_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlace único para compartir"])},
      "blocktrade_secure_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacción segura con nuestro proveedor de pagos Mangopay"])},
      "delete_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar"])},
      "shipping_download_sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar el albarán"])},
      "bo_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En verificación"])},
      "anonymous_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacción anónima (3 EUR IVA incluido)"])},
      "max_min_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe cargar imágenes de la botella"])},
      "duplicate_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se puede poner la misma imagen más de una vez"])},
      "pictures_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sólo se aceptan imágenes de menos de 7mb y con extensión .JPG, .JPEG y .png."])},
      "add_min_one_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su orden no puede ser válida durante menos de una hora."])},
      "empty_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por el momento no hay órdenes de compra."])},
      "empty_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay órdenes de venta por el momento."])},
      "anonymous_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El comprador no conocerá su identidad"])},
      "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colección de inicio"])},
      "pickup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS recoge su paquete en su domicilio"])},
      "pickup_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de recogida"])},
      "pickup_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primera hora de recogida"])},
      "pickup_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última hora de recogida"])},
      "not_match_preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta orden no coincide con sus preferencias de país ni con las de la contraparte"])},
      "load_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir imágenes"])},
      "or_drag_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o arrastrar y soltar"])},
      "file_format_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sólo .JPG, .PNG y .JPEG (7 MB máximo por foto)"])},
      "pickup_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible en puntos de relevo"])},
      "kyc_not_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe verificar su identidad para acceder a la sección de transacciones."])},
      "kyc_redirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprobar mi identidad"])},
      "create_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear alerta"])},
      "create_alert_explain_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibirá un correo electrónico cuando se cree una orden de venta."])},
      "create_alert_explain_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibirá un correo electrónico cuando se cree una orden de compra."])},
      "create_alert_explain_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se recibe un correo electrónico cuando se crea una orden."])},
      "create_alert_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para crear una alerta, vaya a la página del producto de su elección."])},
      "order_alert_price_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precios (opcional)"])},
      "order_alert_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su alerta ha sido creada"])},
      "order_alert_errored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al crear su alerta"])},
      "order_alert_created_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su precio debe estar entre 50 y 30000 EUR o 0 EUR"])},
      "order_alert_paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pausar esta notificación"])},
      "order_alert_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reactivar esta alerta"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desactivado"])},
      "blocktrade_link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copie el enlace para compartir su orden Blocktrade"])},
      "blocktrade_countdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su blocktrade está disponible durante un máximo de 2 horas. Para compartir esta orden de venta privada, copie el enlace haciendo clic en el botón de arriba."])},
      "success_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El enlace Blocktrade se ha copiado correctamente"])},
      "wrong_value_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compruebe su entrada"])},
      "bottle_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio por unidad (50-30000)"])},
      "package_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensiones del paquete"])},
      "add_bottle_basket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir una botella"])},
      "choose_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de caducidad"])},
      "duplicate_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se puede añadir la misma referencia dos veces"])},
      "maximum_sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha alcanzado la cantidad máxima autorizada para una sola venta"])},
      "add_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir un paquete extra"])},
      "sell_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de precios"])},
      "min_pictures_required_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe añadir al menos"])},
      "min_pictures_required_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" fotos"])}
    },
    "price_history": {
      "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precios históricos"])},
      "compare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir una botella"])},
      "responsability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los gráficos son meramente informativos. No nos hacemos responsables de posibles errores."])},
      "data_info_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestros datos históricos agregan"])},
      "data_info_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ventas"])},
      "data_info_11-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["venta"])},
      "data_info_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
      "data_info_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["diferentes fuentes."])},
      "data_info_3-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fuente."])},
      "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divisa"])},
      "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifas"])},
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media"])},
      "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top"])},
      "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bas"])},
      "base_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base 100"])},
      "line_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gráfico de líneas"])},
      "candlesticks_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gráfico de velas"])},
      "chart_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciones de gráficos"])},
      "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["el"])},
      "perf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendimiento"])},
      "since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
      "delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los precios se muestran con un retraso de"])},
      "delay_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mes"])},
      "no_recent_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay datos históricos de precios disponibles. Si aún no los hemos incluido, póngase en contacto con el servicio de asistencia para que los añadan."])},
      "current_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vista actual"])},
      "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diario"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensualmente"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anual"])},
      "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semanal"])},
      "compare_dialog": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparar con"])},
        "no_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay botellas seleccionadas"])},
        "same_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta botella ya está en el gráfico"])},
        "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha alcanzado el límite de 6 botellas para el gráfico"])}
      },
      "composite": {
        "display_composite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver compuesto"])}
      }
    },
    "report": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informar de un error"])},
      "error_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de error"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observaciones"])},
      "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcional"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describa aquí el problema con más precisión..."])},
      "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.thexchangeplatform.io"])},
      "pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capturas de pantalla / Imágenes"])},
      "pictures_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 imágenes como máximo."])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])}
    },
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir"])},
    "no_bottles_found_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha encontrado ninguna botella para esta referencia."])},
    "shipping_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles de la entrega"])},
    "success_send_error_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su solicitud ha sido tenida en cuenta"])},
    "release_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de lanzamiento"])},
    "release_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precios de salida"])}
  },
  "matchs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partidos"])},
    "match_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coincidir con el comprador"])},
    "match_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emparejar al vendedor"])},
    "accept_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar"])},
    "accept_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptar y vender"])},
    "show_transactions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ver mis ", _interpolate(_named("num_transac")), " transacciones actuales"])},
    "show_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar fotos"])},
    "buying_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi orden de compra"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprador"])},
    "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En curso"])},
    "selling_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orden de venta"])},
    "buying_order_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orden de compra"])},
    "sold_by_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrapartida"])},
    "selling_order_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi orden de venta"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay coincidencias para mostrar."])}
  },
  "account_verification_details": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creación de cuenta y funciones disponibles para todos"])},
    "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Características"])},
    "unverified_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta no auditada"])},
    "verified_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta verificada"])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noticias"])},
    "full_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso completo gratuito e ilimitado"])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precios históricos"])},
    "advanced_visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualización avanzada"])},
    "month_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplazamiento de 15 meses"])},
    "month_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 meses de aplazamiento"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartera"])},
    "portfolio_month_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valoración diferida a 15 meses"])},
    "portfolio_month_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valoración diferida a 12 meses"])},
    "tickers_limit_50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50 tickers únicos"])},
    "tickers_limit_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100 tickers únicos"])},
    "database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base de datos"])},
    "tickers_limit_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50 tickers al día"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negociación"])},
    "orders_visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver órdenes activas"])},
    "orders_not_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin acceso transaccional"])},
    "kyc_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificación de identidad gratuita para particulares y sujeta a una tasa para profesionales. KYC realizado por nuestro proveedor de servicios de pago Mangopay."])},
    "not_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso completo ilimitado"])}
  },
  "portfolio": {
    "copied_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID copiado"])},
    "folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivo"])},
    "import_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importar un archivo CSV o Excel"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir"])},
    "bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botella"])},
    "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione todo"])},
    "unselect_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deseleccionar todo"])},
    "research_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar una botella"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartera"])},
    "to_guide_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para orientarle,"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar"])},
    "load_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargar un archivo"])},
    "or_drag_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o arrastrar y soltar"])},
    "file_csv_and_xlsx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sólo archivos CSV y XLSX"])},
    "an_import_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un modelo de importación"])},
    "columns_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Columnas"])},
    "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir"])},
    "average_holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodo medio de tenencia"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartera"])},
    "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar nombre"])},
    "move_in_another_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mover a otra carpeta"])},
    "rename_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar nombre de carpeta"])},
    "empty_folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El nombre del archivo es obligatorio"])},
    "max_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El número máximo de caracteres no debe superar los 30"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importador"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetros"])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estadísticas"])},
    "column_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Columnas de la tabla"])},
    "options_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabla de opciones"])},
    "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciones"])},
    "foldAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doblar todo"])},
    "unfoldAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descubra todo"])},
    "show_sell_stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar acciones vendidas"])},
    "bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botellas"])},
    "tickers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickers"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información"])},
    "th_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
    "th_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
    "th_net_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio neto"])},
    "th_gross_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio bruto"])},
    "price_with_commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comisión"])},
    "th_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifas"])},
    "th_storage_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almacén"])},
    "th_holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poseer"])},
    "th_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivos adjuntos"])},
    "th_buy_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dónde comprar"])},
    "add_folder_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir un archivo"])},
    "add_bottle_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir una botella"])},
    "name_parent_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la carpeta principal"])},
    "additional_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Columnas adicionales"])},
    "column_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Columna de pedidos"])},
    "my_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis botellas"])},
    "purchaseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de compra"])},
    "addPriceOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir opciones de precio"])},
    "sellDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de venta"])},
    "swap_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de la bolsa"])},
    "sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venta"])},
    "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar"])},
    "no_bottles_found_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se han encontrado botellas que coincidan con su búsqueda."])},
    "select_parent_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione la carpeta principal"])},
    "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "create_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una cuenta"])},
    "modify_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar botella"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
    "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En curso"])},
    "bottle_on_hold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botellas en cartera"])},
    "bottle_on_hold_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botellas en su poder"])},
    "unique_references_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referencias únicas"])},
    "on_position_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botellas en propiedad multiplicadas por el precio de compra respectivo"])},
    "AUM_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botellas en propiedad multiplicadas por el valor de mercado"])},
    "running_pnl_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus valeur en cours, diferencia entre el valor de mercado y el precio de compra multiplicado por la cantidad."])},
    "running_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendimiento actual"])},
    "running_year_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rentabilidad anual actual"])},
    "average_price_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio medio de todas las botellas"])},
    "average_holding_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo medio de permanencia de la participación de un comprador en la cartera"])},
    "bought_bottles_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sus botellas compradas hasta ahora"])},
    "sold_bottles_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sus botellas vendidas hasta ahora"])},
    "total_spent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gasto total hasta la fecha"])},
    "total_sold_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventas totales hasta la fecha"])},
    "total_pnl_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ganancia acumulada desde el primer día hasta la fecha"])},
    "total_pnl_period_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ganancia realizada durante el periodo seleccionado"])},
    "realized_total_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rentabilidad calculada desde el primer día (sólo se tienen en cuenta las botellas vendidas)"])},
    "realized_year_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendimiento anual efectivo (botellas vendidas)"])},
    "unique_references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referencias únicas"])},
    "on_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posición de valor inicial"])},
    "running_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendimiento actual"])},
    "running_year_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rentabilidad anualizada"])},
    "realized_year_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rentabilidad histórica anual"])},
    "realized_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendimiento histórico realizado"])},
    "average_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio medio"])},
    "historical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historia"])},
    "bought_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botellas compradas"])},
    "unique_ticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker único"])},
    "AUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valoración"])},
    "running_pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ganancia latente"])},
    "sold_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botellas vendidas"])},
    "total_spent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gasto total"])},
    "total_pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ganancia total realizada"])},
    "total_pnl_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ganancia realizada (periodo)"])},
    "realized_total_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendimiento histórico realizado"])},
    "total_sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventas totales"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de compra/venta"])},
    "net_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio neto"])},
    "price_without_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precios excl."])},
    "price_with_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precios con comisiones"])},
    "price_with_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precios con IVA"])},
    "price_net": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio neto"])},
    "price_gross_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio bruto de compra"])},
    "price_gross_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio de venta bruto"])},
    "storage_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almacén"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
    "boughts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar"])},
    "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventas"])},
    "details_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos de referencia"])},
    "are_you_sure_delete_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Está seguro de que desea eliminar este archivo?"])},
    "xlsx_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichero Excel"])},
    "file_optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consejos para su archivo excel"])},
    "displayed_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Columnas mostradas"])},
    "check_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione todo"])},
    "stockage_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espacio utilizado"])},
    "add_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una cuenta"])},
    "add_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir una botella"])},
    "import_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importar un archivo"])},
    "current_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botella actual"])},
    "current_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botellas actuales"])},
    "current_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referencia actual"])},
    "current_references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referencias actuales"])},
    "current_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossier actual"])},
    "move_to_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mover a una carpeta"])},
    "move_bottles_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["botellas en la carpeta"])},
    "move_references_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["referencias en el dossier"])},
    "move_bottle_to_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mover a una carpeta"])},
    "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifas"])},
    "holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participaciones (día)"])},
    "files_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivos adjuntos"])},
    "buy_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dónde comprar"])},
    "sell_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lugar de venta"])},
    "sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad vendida"])},
    "wab_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio WAB"])},
    "wasd_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premios WASD"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría"])},
    "sub_asset_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clase de activo"])},
    "see_product_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir a la ficha de producto"])},
    "last_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Último precio"])},
    "volatility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatilidad"])},
    "uncheck_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deseleccionar todo"])},
    "selected_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["botella(s) seleccionada(s)"])},
    "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mueva"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar"])},
    "delete_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar botella"])},
    "tel_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualmente, la cartera solo está disponible en PC, pero pronto lo estará en móvil."])},
    "volatility_portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatilidad de la cartera"])},
    "volatility_spirits_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatilidad de las bebidas espirituosas"])},
    "volatility_distillery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatilidad de las destilerías"])},
    "volatility_vintage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatilidad vintage"])},
    "volatility_age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edad de la volatilidad"])},
    "portfolio_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartera premium próximamente"])},
    "see_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más información"])},
    "go_to_product_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir a la página del producto"])},
    "folder_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichero creado"])},
    "folder_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El archivo ha sido modificado"])},
    "folder_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El archivo ha sido eliminado"])},
    "folder_created_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al crear el archivo"])},
    "bottle_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha añadido la botella"])},
    "bottle_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La botella se ha modificado"])},
    "bottle_move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La botella se ha movido"])},
    "file_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichero importado correctamente"])},
    "move_modal_title_origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivo original :"])},
    "move_modal_content_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usted moverá esta carpeta y todas las botellas que contiene."])},
    "move_folder_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su archivo ha sido movido"])},
    "folder_contain_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposible borrar ya que hay botellas en esta carpeta"])}
  },
  "bid": {
    "send_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar una oferta"])}
  },
  "create_order": {
    "file_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sólo se autorizan archivos .jpg, .jpeg y .png de menos de 3Mb."])}
  },
  "payment_process": {
    "transaction_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles de la transacción"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumen de pedidos"])},
    "shipping_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastos de envío"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impuesto"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vender"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechazar"])},
    "sold_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendedor :"])},
    "delivery_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastos de envío"])},
    "selling_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifas de transacción"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importe total"])},
    "price_tva_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IVA incluido en"])},
    "shipping_ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastos de envío de UPS"])},
    "payment_type_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forma de pago"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forma de pago"])},
    "inssurance_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguros UPS"])},
    "ups_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS en casa"])},
    "ups_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS point relais"])},
    "relay_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntos de retirada disponibles"])},
    "save_relay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar esta dirección para futuras compras"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La transacción ha fallado. Vuelva a intentarlo"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La transacción se ha completado con éxito"])},
    "payment_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su pago está siendo verificado"])},
    "payment_please_wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espere unos segundos"])},
    "add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir a la cesta"])},
    "remove_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar de la cesta"])}
  },
  "settings": {
    "title_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración de la cuenta"])},
    "title_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de suscripciones"])},
    "my_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis suscripciones"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forma de pago"])},
    "invoice_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historial de facturas"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi cuenta"])},
    "buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar y vender"])},
    "bank_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos bancarios"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguridad y 2FA"])},
    "personnal_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa"])},
    "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civismo"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nacionalidad"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de nacimiento"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de teléfono"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
    "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código postal"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Región"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
    "other_delivery_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir otra dirección de entrega"])},
    "delivery_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de entrega"])},
    "delivery_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar dirección de entrega"])},
    "billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de facturación"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
    "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviado a"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay información"])},
    "fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titular de la cuenta"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviado el"])},
    "update_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambie su RIB"])},
    "2fa_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doble autenticación"])},
    "2fa_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reciba un código cada vez que se conecte y cada vez que se realice un cambio en su cuenta."])},
    "2fa_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doble autenticación por correo electrónico"])},
    "2fa_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doble autenticación por SMS"])},
    "login_history_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimas conexiones"])},
    "login_history_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulte el historial de las 10 últimas conexiones a su cuenta en todos los dispositivos."])},
    "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibir el boletín"])},
    "card_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº de tarjeta de débito"])},
    "card_created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadido el"])},
    "card_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
    "card_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expira el"])},
    "card_update_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar mi tarjeta bancaria"])},
    "card_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aún no ha registrado una tarjeta de pago."])},
    "delivery_choice_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establecer esta dirección como predeterminada"])},
    "delivery_is_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de entrega preferida"])},
    "update_dialog": {
      "title_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio de dirección de correo electrónico"])},
      "actual_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de correo electrónico actual"])},
      "new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva dirección de correo electrónico"])},
      "title_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio de número de teléfono"])},
      "actual_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de teléfono actual"])},
      "new_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo número de teléfono"])},
      "title_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir una dirección de entrega"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regístrese en"])},
      "err_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico incorrecto"])},
      "err_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de teléfono incorrecto"])},
      "err_email_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya tiene esta dirección de correo electrónico"])},
      "err_phone_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya tiene este número de teléfono"])}
    },
    "subscriptions": {
      "last_month_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastos del mes en curso"])},
      "sub_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonado desde"])},
      "never_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No está abonado"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscripciones anuales"])},
      "next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próxima fecha de recogida"])},
      "subscription_term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscrito hasta el"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar suscripción"])},
      "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore las suscripciones a precios históricos disponibles"])},
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar suscripción"])},
      "cancel_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su suscripción expirará el"])},
      "cancel_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscripción"])},
      "cancel_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar cancelación"])},
      "reactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reanudar la suscripción"])},
      "reactive_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar una suscripción"])},
      "reactive_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El próximo pago se efectuará el"])},
      "reactive_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscripción"])},
      "reactive_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El pago se realizará cada"])},
      "reactive_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["al precio de"])},
      "reactive_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmación de la recuperación"])},
      "commitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprometido hasta el"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mes"])},
      "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ans"])},
      "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precios históricos de las bebidas espirituosas"])}
    },
    "invoices": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historial de facturas"])},
      "download_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar todo"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precios"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
      "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscripción"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar factura"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De pago"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tiene factura"])},
      "delivery_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles de la entrega"])},
      "track_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguimiento de paquetes"])},
      "delivery_estimation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrega estimada"])},
      "transaction_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° transacción"])}
    },
    "confirm": {
      "subtitle_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca el código recibido por correo electrónico"])},
      "subtitle_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca el código recibido por teléfono"])},
      "new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo correo electrónico"])},
      "new_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo teléfono"])},
      "code_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca el código recibido por correo electrónico"])},
      "code_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca el código recibido por teléfono"])}
    },
    "billing_address_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factura y dirección de entrega"])},
    "preferences_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sus preferencias se han registrado correctamente"])},
    "birth_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciudad de nacimiento"])},
    "birth_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País de nacimiento"])},
    "preferences_mails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificaciones"])},
    "preference_t_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una orden de compra"])},
    "preference_t_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una orden de venta"])},
    "preference_t_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una orden de intercambio"])},
    "preference_t_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una orden de blockchain"])},
    "preference_t_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un comprador rechazó el partido"])},
    "preference_t_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orden de venta en espera de validación"])},
    "preference_t_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizar la recepción de SMS (validación de cuenta y/o 2FA)"])},
    "preferences": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferencias de comunicación"])}
    },
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "relay_ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punto de relevo UPS"])},
    "buySellPreferences": {
      "buying_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciones de compra"])},
      "selling_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciones de venta"])},
      "buying_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibilidad de compra"])},
      "selling_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibilidad de las ventas"])},
      "country_exclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Países excluidos"])},
      "country_inclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Países incluidos"])},
      "preferences_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar mis preferencias de compra para la venta"])},
      "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sus cambios se han tenido en cuenta"])}
    },
    "update_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar la información de la cuenta"])},
    "wrong_pseudo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compruebe su nombre de usuario"])},
    "catch_update_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No es posible actualizar su información. Póngase en contacto con el servicio de asistencia si el problema persiste."])},
    "pseudo_already_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este nombre de usuario ya no está disponible, por favor elija otro"])},
    "update_pseudo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar mis datos"])}
  },
  "footer": {
    "social_medias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redes sociales"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soporte"])},
    "legals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal"])},
    "help_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centro de ayuda"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PREGUNTAS FRECUENTES"])},
    "recruitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contratación"])},
    "bug_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informar de un error"])},
    "service_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado de los servicios"])},
    "legal_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información legal"])},
    "cgu_cgv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONDICIONES GENERALES"])},
    "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de cookies"])},
    "tep_footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa registrada en Francia"])}
  },
  "paiment_process": {
    "shipping_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastos de envío"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impuestos"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechazar"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumen de pedidos"])},
    "sold_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parte vendida :"])},
    "delivery_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastos de envío"])},
    "selling_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastos de venta"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importe total"])}
  },
  "orders": {
    "swap_taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercambiador"])},
    "swap_giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercambiador"])},
    "account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de cuenta"])},
    "current_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedidos actuales"])},
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
    "creation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de creación"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precios"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
    "update_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar orden de compra"])},
    "update_sell_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar orden de venta"])},
    "update_block_trade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar operación en bloque"])},
    "update_swap_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar orden de intercambio"])},
    "display_deleted_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar órdenes eliminadas"])},
    "wish_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Me gustaría recibir un"])},
    "possess_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soy propietario de una"])},
    "in_exchange_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a cambio de"])},
    "that_trade_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por qué me gustaría intercambiar"])},
    "update_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orden modificada con éxito"])},
    "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orden creada con éxito"])},
    "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importe total :"])},
    "total_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de pedidos :"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título, precio..."])}
  },
  "match": {
    "refuse_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo de la denegación"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechazar"])}
  },
  "transactions": {
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sens"])},
    "dispute_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Litigios en curso"])},
    "dispute_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declarar un litigio"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se realizan transacciones."])}
  },
  "blog": {
    "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer filtros"])},
    "published_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicado el :"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificado el :"])},
    "articles_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrar artículos"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se han encontrado artículos."])}
  },
  "wallet": {
    "available_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad disponible"])},
    "waiting_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En espera"])},
    "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importe total"])},
    "withdraw_money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirada de fondos"])},
    "money_movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movimientos"])},
    "payin_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago efectuado"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay transacciones que mostrar."])},
    "transfert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencia"])},
    "transfert_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencia entrante"])},
    "transfert_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencia saliente"])},
    "payout_seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago del vendedor"])},
    "bank_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirada de fondos"])},
    "bank_payout_in_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirar dinero a mi cuenta bancaria"])},
    "payout_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirada de fondos disponibles"])},
    "payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar retirada"])},
    "success_payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La transferencia a su cuenta bancaria se ha completado con éxito"])},
    "failed_payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La transferencia a su cuenta ha fallado. Si el problema persiste, póngase en contacto con el Servicio de Atención al Cliente"])},
    "order_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi cartera (0%)"])},
    "available_after_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importe restante tras la transacción :"])},
    "payin_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago recibido"])},
    "transactions_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceder a mi historial de operaciones"])},
    "send_iban_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferir dinero directamente a su monedero para evitar comisiones de pago."])},
    "wallet_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propietario :"])},
    "wallet_iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN :"])},
    "wallet_bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC :"])},
    "wallet_await_funds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sus fondos estarán disponibles en un plazo de 2 a 3 días laborables."])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemento copiado correctamente"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar"])},
    "wallet_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provisión de carteras"])},
    "instant_payout_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El pago es por transferencia instantánea por defecto"])},
    "available_amount_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad disponible para retirar :"])},
    "banking_account_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta bancaria terminada en :"])}
  },
  "filters": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros avanzados"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrar"])},
    "filters_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se requieren 2 filtros para las búsquedas sin palabras clave"])}
  },
  "ratings": {
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluaciones"])},
    "ratings_phone_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valoraciones :"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
    "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La descripción puede contener hasta 500 caracteres"])},
    "create_error_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compruebe que su valoración está entre 1 y 100 y que su descripción tiene menos de 500 caracteres."])},
    "create_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una evaluación"])},
    "create_btn_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una evaluación"])},
    "see_or_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver o cambiar la valoración"])},
    "update_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar la valoración"])},
    "delete_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar calificación"])},
    "rating_explain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para crear una evaluación, vaya a la página de un producto"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría, nombre, puntuación..."])},
    "description_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La descripción es privada. No se hará pública."])},
    "not_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opcional"])},
    "rating_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha alcanzado un número mínimo de valoraciones para hacer una media."])},
    "pseudo_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De 5 a 50 caracteres (se aceptan letras y números)"])},
    "empty_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aún no hay valoración para esta botella."])},
    "ranking_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El punto de partida para todos los usuarios."])},
    "ranking_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reconoce a los colaboradores habituales."])},
    "ranking_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indica un compromiso y una experiencia avanzados."])},
    "ranking_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todo un compromiso."])},
    "ranking_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reconoce un papel de liderazgo en la comunidad."])},
    "ranking_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo para usuarios muy activos."])},
    "ranking_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El rango definitivo, para los contribuyentes más dedicados."])},
    "rewards_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las recompensas para cada nivel se anunciarán en enero."])},
    "pseudo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apodo"])},
    "private_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hacer privado mi comentario"])},
    "profil_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil"])},
    "rating_unitary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["valoración"])},
    "rating_multi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["calificaciones"])}
  }
}