<template>
    <div v-if="getOrdersAlerts" class="dashboard">
        <div class="transactions-options">

            <h1>{{ $t('commons.orders_alerts') }}</h1>
        
            <div class="orders-searchbar">
                <input v-model="search" type="text" :placeholder="$t('orders.search')" />
                <button>
                    <img src="@/assets/svg/v2/search.svg" alt="Search icon" />
                </button>
            </div>
      </div>

      <div class="transactions">
        <el-table
          :data="visibleOrdersAlertsFiltered"
          class="bottles-list orders-list"
          stripe
        >
          <el-table-column :label="$t('commons.status')" prop="status" width="130">
            <template v-slot="{ row }">
              <p class="title">{{ getStatus(row) }}</p>
            </template>
          </el-table-column>
  
          <el-table-column
            :label="$t('orders.direction')"
            prop="transaction"
            width="140"
          >
            <template v-slot="{ row }">
              <button v-if="row.type === 0" class="live-order-list-buy">
                <img
                  alt="Buy order"
                  height="16"
                  src="@/assets/svg/v2/products/buyOrder.svg"
                  width="16"
                />
                <p>{{ $t('search.buy') }}</p>
              </button>
  
              <button v-if="row.type === 1" class="live-order-list-sell">
                <img
                  alt="Sell order"
                  height="16"
                  src="@/assets/svg/v2/products/sellOrder.svg"
                  width="16"
                />
                <p>{{ $t('search.sell') }}</p>
              </button>
  
              <button v-if="row.type === 2" class="live-order-list-giver">
                <img
                  alt="Sell order"
                  height="16"
                  src="@/assets/svg/v2/products/swap.svg"
                  width="16"
                />
                <p>Giver</p>
              </button>
  
              <button v-if="row.type === 3" class="live-order-list-taker">
                <img
                  alt="Sell order"
                  height="16"
                  src="@/assets/svg/v2/products/swap.svg"
                  width="16"
                />
                <p>Taker</p>
              </button>
  
              <button v-if="row.type === 4" class="live-order-list-sell">
                <img
                  alt="Swap taker order"
                  height="16"
                  src="@/assets/svg/v2/products/blocktrade.svg"
                  style="background: #2667ff !important"
                  width="16"
                />
                <p>BlockTrade</p>
              </button>
            </template>
          </el-table-column>
  
          <el-table-column
            :label="$t('orders.creation_date')"
            prop="date"
            sortable
            width="190"
          >
            <template v-slot="{ row }">
              <a class="title">{{ dateConvert(row.created_at) }}</a>
            </template>
          </el-table-column>
  
          <el-table-column
            label="Ticker"
            prop="ticker_maitre"
            width="130"
          >
            <template v-slot="{ row }">
              <button v-if="row.infos.ticker" class="ticker-maitre">
                {{ row.infos.ticker }}
              </button>
            </template>
          </el-table-column>
  
          <el-table-column
            :label="$t('search.name')"
            min-width="190"
            prop="title"
          >
            <template v-slot="{ row }">
              <a :href="'/product/' + row.isbn" class="title">{{ row.infos.title }}</a>
            </template>
          </el-table-column>
  
          <el-table-column
            :label="$t('orders.price')"
            width="70"
            prop="price"
            sortable
          >
            <template v-slot="{ row }">
              <button class="title">
                {{ row.price }}
              </button>
            </template>
          </el-table-column>
  
          <el-table-column label="" prop="title" width="130">
            <template v-slot="{ row }">
              <div class="buttons">

                <el-popover placement="top" width="300">
                    <template #reference>
                        <img class="order-alert-update-icon" alt="Edit order alert" src="@/assets/svg/v2/edit.svg" width="20"/>
                    </template>

                    <div class="popover-create-alert">
                        <div class="create-alert-sell-inputs">
                            <el-input v-model="row.price" style="width: 240px" :placeholder="$t('product_page.orders.order_alert_price_trigger')" />
                            <button @click="updateOrderAlert(row)" class="generic-btn">{{ $t('commons.confirm') }}</button>
                        </div>
                        <p>{{ $t('product_page.orders.create_alert_explain_generic') }}</p>
                    </div>
                </el-popover>

                <button @click="setAlertSleep(row)" class="download-invoice">
                  <el-tooltip
                    :content="row.enabled === 1 ? $t('product_page.orders.order_alert_paused') : $t('product_page.orders.order_alert_active')"
                    class="tooltip"
                    effect="dark"
                    placement="top"
                  >
                    <img v-if="row.enabled === 1" alt="Alert paused" src="@/assets/svg/v2/orders/notificationsPaused.svg" />
                    <img v-else alt="Reactive alert" src="@/assets/svg/v2/settings/notifications.svg" />
                  </el-tooltip>
                </button>

                <button @click="deleteOrderAlert(row)" class="download-invoice">
                  <el-tooltip
                    :content="$t('product_page.orders.delete_order')"
                    class="tooltip"
                    effect="dark"
                    placement="top"
                  >
                    <img alt="Delete alert" src="@/assets/svg/v2/delete.svg" />
                  </el-tooltip>
                </button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="create-order-help">
        <img src="@/assets/svg/v2/infos.svg" alt="Informations create order alert" width="23" />
        <p>{{ $t('product_page.orders.create_alert_help') }}</p>
      </div>
    </div>
  </template>
  
  <script>
  import { mapActions, mapGetters } from 'vuex'
  import { filter } from 'lodash'
  import moment from 'moment'
  
  export default {
    data() {
      return {
        search: '',
      }
    },
    async mounted() {
      await this.allOrdersAlertsList();
    },
    computed: {
      ...mapGetters({
        getOrdersAlerts: 'getOrdersAlerts'
      }),
      enabledOrders() {
        return filter(this.getMyOrders, { enabled: 1 })
      },
      soldOrders() {
        return filter(this.getMyOrders, { enabled: 0, sold: 1 })
      },
      cancelOrders() {
        return filter(this.getMyOrders, { enabled: 0, sold: 0 })
      },
      visibleOrdersAlertsFiltered() {        
        return filter(this.getOrdersAlerts, (o) => {           
    
            return (o.infos.ticker.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 ||
                o.infos.title.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 ||
                String(o.created_at).indexOf(this.search) !== -1 ||
                String(o.price).indexOf(this.search) !== -1)
        });
      },
    },
    methods: {
      ...mapActions({
        allOrdersAlertsList: 'allOrdersAlertsList',
        updateOrderAlertAction: 'updateOrderAlertAction'
      }),
      dateConvert(date) {
        return moment(date).format('DD/MM/YYYY HH:mm:ss')
      },
      getStatus(row) {
        if (row.enabled === 1 ) {
  
          return this.$t('product_page.orders.active');
  
        } else if (row.enabled === 2) {
  
          return this.$t('product_page.orders.disabled');
  
        }
      },
      async updateOrderAlert(row) {
        if (!row.price) {
            row.price = 0;
        }

        await this.updateOrderAlertAction(row).finally(() => {
            this.allOrdersAlertsList();
        })
      },
      async setAlertSleep(row) {

        if (!row.price) {
            row.price = 0;
        }
        
        if (row.enabled === 1) {
            row.enabled = 2;
        } else {
            row.enabled = 1;
        }

        await this.updateOrderAlertAction(row).finally(() => {
            this.allOrdersAlertsList();
        })
      },
      async deleteOrderAlert(row) {
        row.enabled = 0;
        if (!row.price) {
            row.price = 0;
        }

        await this.updateOrderAlertAction(row).finally(() => {
            this.allOrdersAlertsList();
        })
      }
    }
  }
  </script>
  
  <style lang="scss">
  @import '@/assets/styles/root.scss';

  .dashboard {
    margin: 30px;
    margin-top: 20px;

    .transactions-options {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 30px;
        display: flex;
    }
  }
  
  .orders-searchbar {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    
    input {
      box-sizing: border-box;
      width: 240px;
      height: 32px;
      border: none;
      background: #333;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      padding: 5px 10px;
      font-size: 15px;
    }
    button {
      display: flex;
      align-items: center;
      gap: 5px;
      height: 32px;
      background: #2667ff;
      border: none;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      padding: 5px 12px;
      font-size: 15px;
    }
  }

  .order-alert-update-icon {
    cursor: pointer;
  }
  
  .dashboard {
    min-height: 52%;
    margin-top: 20px;
  
    h1 {
      margin-bottom: 6px;
      padding-left: 0;
    }
  
    .display-deleted-orders {
      padding-left: 30px;
    }
  }
  
  .transactions {
    border-radius: 12px;
    border: 1px solid $border;
    background: #333;
    flex-direction: column;
    align-items: flex-start;
    margin: 15px 0;
    overflow: hidden;
  }
  
  .buttons {
    display: flex;
    justify-content: left;
    gap: 20px;
  }
  
  .title,
  .price {
    display: flex;
    text-align: start;
    word-break: keep-all;
    color: white;
    font-size: 15px;
    font-weight: 500;
    border: 0;
    background: transparent;
  }
  
  h1 {
    padding-left: 30px;
  }
  
  .ticker-maitre,
  .status {
    border-radius: 6px;
    border: 1px solid $border;
    color: $grey40;
    background: transparent;
    padding: 3px;
  }
  
  .status-paid {
    color: #add7f6;
    border: 1px solid #add7f6;
  }
  
  .delivery {
    border: none;
    color: $grey40;
    background: transparent;
  }
  
  .cell {
    display: flex;
    justify-content: start;
  }
  
  .live-orders {
    justify-content: flex-start;
  
    .case-buy,
    .case-sell,
    .case-taker,
    .case-giver {
      width: 90px;
  
      .empty-order {
        margin-left: 10px;
      }
    }
  
    .case-sell {
      .empty-order {
        margin-left: 22px;
      }
    }
  
    .case-taker {
      width: 105px;
  
      .empty-order {
        margin-left: 33px;
      }
    }
  
    .case-giver {
      width: 105px;
      margin-left: 10px;
  
      .empty-order {
        margin-left: 33px;
      }
    }
  }
  
  .live-order-list-buy,
  .live-order-list-sell,
  .live-order-list-taker,
  .live-order-list-giver {
    display: flex;
    border-radius: 50px;
    border: 1px solid $border;
    background: #444;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.16);
    height: 27px;
    width: max-content;
    padding: 2px 10px;
  }
  
  .live-order-list-buy,
  .live-order-list-sell,
  .live-order-list-taker,
  .live-order-list-giver {
    justify-content: center;
    align-items: center;
    margin-left: 0;
  
    img {
      background: #84cc16;
      border-radius: 50%;
    }
  
    p {
      color: white;
      font-size: 15px;
      font-weight: 500;
      margin-left: 6px;
    }
  
    span {
      color: $grey40;
      font-size: 15px;
      font-weight: 500;
      margin-left: 2px;
    }
  }
  
  .live-order-list-sell {
    margin-left: 0;
  
    img {
      background: #f43f5e;
    }
  }
  
  .live-order-list-taker,
  .live-order-list-giver {
    img {
      background: #a855f7;
    }
  }
  
  .live-order-list-giver {
    img {
      background: $blue;
    }
  }
  
  .download-invoice {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    color: white;
  
    img {
      width: 20px;
    }
  }
  
  .shipment-send {
    background: green;
    border-radius: 50%;
    padding: 4px;
  }
  
  .el-table tr,
  .el-table th.el-table__cell {
    background-color: #333333;
    font-size: 15px;
    font-weight: 500;
  }
  
  .el-table th.el-table__cell.is-leaf,
  .el-table td.el-table__cell {
    border-bottom: 1px solid $border;
  }
  
  .el-table thead {
    color: white;
  }
  
  .el-table__body tr:hover > td.el-table__cell {
    background-color: transparent;
  }
  
  .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell,
  .el-table--striped:hover
    .el-table__body
    tr.el-table__row--striped:hover
    td.el-table__cell {
    background: #ffffff32;
  }
  
  .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background: #444444;
  }
  
  .el-table__inner-wrapper::before {
    background-color: transparent;
  }

  .create-order-help {
    display: flex;
    align-items: center;

    img {
        margin-right: 8px;
    }
  }
  
  @media screen and (max-width: 500px) {
  
    .hidden-tel {
      display: none;
    }
  
    .orders-list {
      display: block !important;
    }
  
    .dashboard {
      max-width: 100%;
      box-sizing: border-box;
      margin: 5px;
      margin-top: 20px;

      .transactions-options {
        flex-direction: column;
      }
  
      h1 {
        margin-bottom: 10px !important;
        padding-left: 10px;
      }
    }
  
    .orders-searchbar {
      margin-left: 0;
  
      input {
        width: 80%;
      }
  
      button {
        width: 20%;
        justify-content: center;
      }
    }
  
    .transactions {
      margin: 5px;
    }
  
    .display-deleted-orders {
      padding-left: 7px !important;
    }

    .create-order-help {
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }
  </style>
  