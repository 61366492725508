export default {
  "commons": {
    "refuse_match_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매칭 거부하기"])},
    "date_expire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["만료일"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상태"])},
    "buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매 및 판매"])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스왑"])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["블록트레이드"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포트폴리오"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["국가"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지역"])},
    "bid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bid"])},
    "ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["물어보기"])},
    "giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giver"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taker"])},
    "report_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오류 신고하기"])},
    "account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 유형"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문"])},
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 주문"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수량"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["뒤로 가기"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["닫기"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리셋"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신청하기"])},
    "product_origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제품 원산지"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계속 보기"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["확인"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["날짜"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유형"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연결"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그오프"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 만들기"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색 및 거래"])},
    "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구독"])},
    "price_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가격에는 부가가치세가 포함되어 있습니다."])},
    "dark_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["야간 모드"])},
    "light_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["클리어 모드"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래 내역"])},
    "portfolio_gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포트폴리오"])},
    "matchs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매치"])},
    "transactions_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래 내역"])},
    "account_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 설정"])},
    "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활성화됨"])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비활성화됨"])},
    "alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알림"])},
    "alerts_clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모두 삭제"])},
    "alerts_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 알림이 없습니다."])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택"])},
    "no_copy_paste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마우스 오른쪽 버튼으로 클릭할 수 없습니다."])},
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배달"])},
    "secure3d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래 확인"])},
    "cancel_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소"])},
    "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["합계"])},
    "too_many_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["너무 많은 요청을 하는 경우"])},
    "see_products_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일일 상담 한도에 도달했습니다. 더 이상 제한되지 않도록 계정을 확인하세요."])},
    "check_inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["항목을 확인하세요:"])},
    "generic_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오류가 발생했습니다. 나중에 다시 시도하세요."])},
    "login_retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다시 로그인하세요."])},
    "email_phone_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 작업을 수행하기 전에 이메일과 휴대폰을 인증해 주세요."])},
    "not_authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["귀하는 이 작업을 수행할 권한이 없습니다."])},
    "service_unaivalable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스를 이용할 수 없는 경우 고객 서비스에 문의하세요."])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["뉴스피드"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활성"])},
    "inputs_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 필수 입력란을 올바르게 입력했는지 확인하세요."])},
    "lang": {
      "lang_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["언어 선택"])},
      "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR"])},
      "fr_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["프랑스어"])},
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EN"])},
      "en_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["영어"])},
      "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ES"])},
      "es_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스페인어"])},
      "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT"])},
      "it_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian"])},
      "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PL"])},
      "pl_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polish"])},
      "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DE"])},
      "de_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["독일어"])},
      "cs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CS"])},
      "cs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["체코"])},
      "da": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DA"])},
      "da_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danish"])},
      "el": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EL"])},
      "el_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greek"])},
      "hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HU"])},
      "hu_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["헝가리어"])},
      "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JA"])},
      "ja_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일본어"])},
      "ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KO"])},
      "ko_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["한국어"])},
      "pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PT"])},
      "pt_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포르투갈어"])},
      "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UK"])},
      "ru_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["러시아어"])},
      "sk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SK"])},
      "sk_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["슬로바키아"])},
      "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZH"])},
      "zh_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["중국어"])}
    },
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문제 신고하기"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매자"])},
    "seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매자"])},
    "code_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코드 전송"])},
    "code_time_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코드는 15분 동안 유효합니다."])},
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기사"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전문가"])},
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["월렛"])},
    "my_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["월렛"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모두"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["편집"])},
    "error_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["항목을 확인하거나 고객 서비스에 문의하세요."])},
    "copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["복사"])},
    "copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["성공적인 카피"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터 :"])},
    "orders_alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알림"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min :"])},
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최대 :"])},
    "error_503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대기 시간이 초과되어 작업이 취소되었습니다. 다시 시도해 주세요."])}
  },
  "major": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 사이트를 방문하려면 만 18세 이상이어야 합니다."])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가입하려면 연령을 확인하세요."])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["만 18세 이상"])},
    "nok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["만 18세 미만입니다."])},
    "conditions_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 사이트에 액세스하면 다음 사항에 동의하는 것입니다."])},
    "conditions_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이용 약관 및 조건"])},
    "conditions_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그리고"])},
    "conditions_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인정보 보호정책."])}
  },
  "home": {
    "revolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그 자체로 혁명"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주류 구매, 판매 및 거래를 위한 참고 자료"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["위스키 애호가든, 코냑 수집가든, 완벽을 추구하는 전문가든, 거래소 플랫폼은 여러분을 위한 곳입니다. 최적의 보안, 완벽한 투명성, 저렴한 수수료: 안심하고 증류주를 거래할 수 있습니다."])},
    "secure_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["안전한 거래"])},
    "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수수료 2.5% 할인(부가가치세 포함)"])},
    "verified_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% 검증된 무료 계정"])},
    "title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인이든 전문가든, 수천 가지 주류에 대한 70개 이상의 가격 정보를 종합적으로 살펴보세요!"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구독 없이 무료 계정 만들기"])},
    "buy_and_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매 및 판매: 안전하고 투명한 거래"])},
    "buy_and_sell_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래소 플랫폼을 통해 지속적으로 쉽게 사고 팔 수 있습니다. 비교가 쉽고 투명성을 보장하는 표준화된 레퍼런스를 통해 이용 가능한 모든 오퍼에 쉽게 액세스할 수 있습니다. 원하는 상품을 찾거나 관심사를 표현하세요: TEP를 사용하면 구매 또는 판매 여부와 관계없이 상대방의 검색 조건에 맞춰 원하는 상품을 찾을 수 있습니다. 시간을 절약하고 거래를 최적화하며 항상 최적의 가격으로 거래하세요. 직관적인 인터페이스와 안전한 거래로 주류 거래를 간소화한 TEP는 아마추어와 전문가 모두에게 빠르고 안정적이며 효율적인 경험을 제공합니다."])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["병 교환: 거래 상대방 위험 없이 안전하고 보장된 거래"])},
    "swap_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEP는 담보 현금 또는 창고 보증금 솔루션 덕분에 완벽한 보안 속에서 병을 교환할 수 있는 유일한 플랫폼입니다. 이 시스템은 완벽한 보호를 보장합니다. 병의 배송이 확인될 때까지 결제 서비스 제공업체를 통해 자금을 안전하게 보호하므로 거래 상대방 위험이 없습니다. 이 기능은 전례 없는 투명성과 신뢰성을 제공함으로써 주류 시장에 혁신을 일으키고 있습니다. 수집가든 감정가든, 거래 보안에 대한 걱정 없이 Exchange를 통해 컬렉션을 확장하거나 최적화할 수 있습니다. 현대적이고 안전한 환경에서 완전히 안심하고 증류주를 교환하세요."])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["블록 거래: 간편하게 외부 거래를 보호하세요."])},
    "blocktrade_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEP의 독점 기능인 블록 트레이드를 사용하면 단 몇 번의 클릭만으로 구매/판매 거래를 안전하게 보호할 수 있습니다. 이 혁신적인 기능 덕분에 각 거래마다 고유한 링크가 생성되어 안정적이고 안전한 체결을 보장합니다. 블록 트레이드는 구매 또는 판매 시 투명하고 안전한 프레임워크를 제공해 외부 직접 거래와 관련된 위험을 제거합니다. 또한 거래 조건을 확인하고, 계좌를 확인하며, 모든 단계에서 원활한 체결을 보장하는 고급 도구가 통합되어 있습니다. TEP가 제공하는 안심하고 신뢰할 수 있는 새로운 주류 거래 방법을 알아보세요."])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포트폴리오 관리: 수집가와 투자자를 위한 혁명"])},
    "portfolio_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["경험이 많은 분들을 위해 거래소 플랫폼은 고유한 포트폴리오 관리 도구를 제공합니다. 이 기능을 사용하면 플랫폼의 실시간 가격과 상세한 과거 데이터를 결합하여 포지션과 그 가치를 실시간으로 모니터링할 수 있습니다. 컬렉션의 가치에 대해 더 이상 궁금해하지 마세요! 전례 없는 객관적이고 불가지론적인 시각으로 자산의 변화를 모니터링하고 정보에 입각한 결정을 내릴 수 있습니다. 컬렉션을 평가하든 전략적 거래를 계획하든, TEP의 포트폴리오 관리 도구는 완전한 투명성과 최적의 관리 기능을 제공하여 사용자 경험을 혁신합니다."])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과거 가격: 타의 추종을 불허하는 투명성"])},
    "price_history_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEP에서는 2002년으로 거슬러 올라가는 데이터로 가능한 모든 주류의 가장 포괄적인 가격 내역을 수집했습니다! 70개의 다양한 출처를 취합하고 자체 거래로 보완한 덕분에 과거 실현된 가격에 대한 투명하고 편견 없는 시각을 제공합니다. 이 모든 데이터는 신중하게 표준화되어 18개 통화로 제공되므로 사용자는 전 세계 시장 동향을 비교하고 분석할 수 있습니다. 이 고유한 기능은 증류주 포트폴리오를 구매, 판매하거나 단순히 평가하는 등 정보에 입각한 결정을 내리고자 하는 수집가, 투자자 및 전문가에게 없어서는 안 될 필수적인 도구입니다."])},
    "title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신뢰 보장"])},
    "secure_transactions_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결제는 결제 서비스 제공업체인 망고페이를 통해 이루어지므로 거래가 원활하게 이루어지며, 거래 상대방에게 송금한 금액이 사라질 위험이 없습니다."])},
    "low_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시장에서 가장 낮은 수수료"])},
    "low_fees_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시장에서 가장 낮은 수수료인 2.5%(구매자와 판매자 모두 부가세 포함)를 받고 있으며, 상장 수수료나 예약 수수료가 없습니다. 거래가 성사된 경우에만 수수료를 지불하면 됩니다."])},
    "anonymous_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래 전 익명성"])},
    "anonymous_transactions_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인이든 전문가든, 거래 전 익명성을 보장하는 저희 플랫폼은 오늘날의 모든 문제에 대한 해결책이 될 수 있습니다."])},
    "verified_accounts_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 인증 전 결제 서비스 제공업체의 등록 관리 덕분에 유령 계정, 사기꾼 또는 봇이 플랫폼에 들어올 수 없습니다. 추적성과 보안이 완벽합니다."])},
    "title_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필요에 맞는 맞춤형 오퍼"])},
    "join_community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["커뮤니티에 가입하여 주류를 찾고, 관리하고, 거래하세요!"])},
    "search": {
      "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주류 유형"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택"])},
      "bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottle"])},
      "search_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["티커, 이름, EAN, 키워드..."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색"])},
      "search_bottle_bottler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내에서 검색"])}
    },
    "without_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구독 없이도 거래가 가능합니다!"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아마추어, 수집가, 전문가 모두에게 최적의 보안, 완벽한 투명성, 낮은 거래 비용, 수천 개의 병에 대한 70개의 과거 가격 정보를 제공하는 거래소 플랫폼은 여러분을 위해 만들어졌습니다."])},
    "create_account_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["안심하고 증류주 거래를 시작해보세요."])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["뉴스피드: 무료 주류 뉴스 허브"])},
    "newsfeed_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEP의 뉴스피드를 통해 최신 업계 뉴스, 신제품, 주요 이벤트, 시장 동향을 파악할 수 있습니다. 이 기능을 사용하면 업계에서 가장 관련성이 높고 인정받는 사이트에서 제공하는 정보를 통해 증류주 업계에서 일어나는 모든 일을 실시간으로 확인할 수 있습니다. 뉴스피드를 통해 뉴스를 종합적으로 볼 수 있으므로 시장 동향을 한 곳에서 모두 파악할 수 있습니다."])},
    "blog_and_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["블로그 및 리뷰: 분석과 발견을 위한 공간"])},
    "blog_and_review_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEP의 블로그 및 리뷰에서 무료 시음 콘텐츠를 살펴보세요. 업계 전문가와 애호가들의 리뷰를 통해 블로그 & 리뷰는 독특한 관점과 신뢰할 수 있는 추천을 제공합니다. 새로운 와인을 발견하고 싶거나, 시장 트렌드를 이해하고 싶거나, 지식을 넓히고 싶을 때 이 피드는 증류주 세계에 대한 경험을 풍부하게 해줍니다."])},
    "events_browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이벤트 브라우저: 주류 이벤트 가이드"])},
    "events_browser_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEP의 무료 이벤트 브라우저를 사용하면 주류 업계의 중요한 미팅을 놓치지 않을 수 있습니다. 어디서든 독점적인 무역 박람회와 이벤트에 대해 알아보세요. 이벤트 브라우저는 수집가, 투자자 또는 열정적인 애호가 모두에게 가장 관련성 높은 이벤트를 엄선하여 제공합니다. 나들이 계획을 세우고 증류주와 관련된 특별한 경험을 즐겨보세요."])}
  },
  "newsfeed": {
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["블로그 및 리뷰"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["뉴스피드"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이벤트"])},
    "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터 재설정"])},
    "event_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름"])},
    "sort_asc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알파벳 순서로 정렬"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["국가"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["날짜"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["월"])},
    "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주류 유형"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색..."])},
    "events_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이벤트 필터링"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시작 날짜"])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["종료일"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["에서"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["에서"])},
    "events_show_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예정된 이벤트"])},
    "events_show_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예정된 이벤트"])}
  },
  "subscriptions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연간 구독"])},
    "price_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연간 요금"])},
    "price_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["월별 요금"])},
    "free_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연간 결제 시 1개월 무료"])},
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["특별 행사"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전문가 제안"])},
    "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["프리미엄 과거 가격"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시간 지연 없이 완전한 과거 데이터를 원하는 사용자에게 적합합니다."])},
    "all_taxs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])},
    "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["월별"])},
    "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연간"])},
    "per_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주류 유형별"])},
    "references_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 참고자료에 액세스"])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시차가 없는 과거 가격"])},
    "advanced_stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고급 통계"])},
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주류 구독하기"])},
    "choice_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주류 선택 :"])},
    "wine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["와인"])},
    "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기타"])},
    "complementary_module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보완 모듈"])},
    "portfolio_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["더 많은 데이터와 실제 관리 도구를 원하는 숙련된 수집가에게 적합합니다."])},
    "portfolio_subtitle_pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전문가 커뮤니티에 가입하여 주류를 분석, 관리, 거래하세요!"])},
    "advanced_gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고급 포트폴리오 관리"])},
    "choice_this_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 오퍼 선택하기"])},
    "add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장바구니에 담기"])},
    "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["바구니"])},
    "see_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보기"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["월별 결제"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연간 결제"])},
    "sub_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["하위 합계"])},
    "next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음 샘플링"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["합계"])},
    "include_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부가가치세 (20%)"])},
    "payment_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결제 진행"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제"])},
    "already_in_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 구독은 이미 장바구니에 있습니다."])},
    "already_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 구독자입니다."])},
    "cart_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장바구니가 비어 있습니다."])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구독으로 돌아가기"])},
    "contact_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연락처 정보"])},
    "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결제"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["편집"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결제 방법"])},
    "payment_method_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래소 플랫폼에서의 거래는 결제 서비스 제공업체인 망고페이를 통해 이루어집니다."])},
    "banking_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신용 카드"])},
    "card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카드 번호"])},
    "card_fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결제 카드의 이름"])},
    "card_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["만기일(월/년)"])},
    "card_code_security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보안 코드"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매 완료하기"])},
    "soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["곧 출시 예정"])},
    "recap_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결제 요약"])},
    "buy_commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매 수수료"])},
    "buy_commission_tva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부가가치세 구매 수수료"])},
    "already_sub_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구독자"])}
  },
  "payment": {
    "cvx_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카드 인증 코드(카드 뒷면에 일반적으로 3자리로 되어 있습니다."])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소가 고려되었습니다."])},
    "cancel_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소 실패, 고객 서비스에 문의하세요"])}
  },
  "login": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래소 플랫폼에 오신 것을 환영합니다."])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["원활하고 안전하며 표준화된 거래 환경을 이용할 수 있습니다."])},
    "save_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인 저장"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호를 잊어버리셨나요?"])},
    "forgot": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 재설정"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 재설정 방법에 대한 안내가 포함된 이메일을 받게 됩니다."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일을 입력하세요."])}
    },
    "reset": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 재설정"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 재설정 방법에 대한 안내가 포함된 이메일을 받게 됩니다."])},
      "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 비밀번호"])},
      "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 확인"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증 코드"])}
    },
    "doubleFA": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이중 인증"])},
      "code_reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일이나 전화로 받은 코드를 입력하세요."])}
    },
    "password_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호는 대문자 하나 이상을 포함한 7자로 구성되어야 합니다."])},
    "wrong_values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 입력란을 정확하게 작성해 주세요."])},
    "wrong_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유효한 코드를 입력하세요."])}
  },
  "register": {
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 만들기"])},
    "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시민성"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름"])},
    "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["생년월일"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["국적"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지역"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["국가"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소"])},
    "address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거리 번호"])},
    "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["우편번호"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전화"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호"])},
    "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 확인"])},
    "mangopay_accept_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나는"])},
    "mangopay_accept_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이용 약관 및 조건"])},
    "mangopay_accept_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["망고페이의"])},
    "tep_accept_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나는"])},
    "tep_accept_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이용 약관 및 조건"])},
    "tep_accept_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그리고"])},
    "tep_accept_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인정보 보호정책"])},
    "tep_accept_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["드 거래소 플랫폼."])},
    "newsletter_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["뉴스레터 구독하기"])},
    "newsletter_enter_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 입력"])},
    "create_and_continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["무료 계정을 만들고 계속하기"])},
    "end_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록 완료하기"])},
    "verify_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증된 계정 만들기"])},
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회사 이름"])},
    "company_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체 주소"])},
    "company_zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["우편 번호"])},
    "company_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "company_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["국가"])},
    "company_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전문가 이메일"])},
    "company_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회사 유형"])},
    "company_siret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회사 번호"])},
    "company_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부가가치세 번호"])},
    "company_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내 회사"])},
    "company_siret_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["123 456 789 00010"])},
    "step": {
      "step_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일반 정보"])},
      "step_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2단계 인증"])},
      "step_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KYC 확인"])},
      "step_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["은행 정보"])},
      "step_1_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정보"])},
      "step_4_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["은행 정보"])}
    },
    "step_0": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["원활하고 안전하며 표준화된 거래 환경을 이용할 수 있습니다."])},
      "usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["용도 선택"])},
      "account_no_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 유형은 등록이 완료되면 변경할 수 없습니다."])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인"])},
      "professionnal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전문가"])},
      "individual_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인용 계정"])},
      "professionnal_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검증된 전문가 계정"])}
    },
    "step_1": {
      "general_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일반 정보"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아래의 모든 필드를 입력해 주세요."])},
      "personnal_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비공개 계정"])},
      "business_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기업 계정"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택"])},
      "business_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전문가 카테고리"])},
      "business_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["법적 형식"])},
      "business_part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["25% 이상의 주식을 보유한 실소유자 ?"])},
      "business_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["25% 이상을 보유한 다른 실소유자의 세부 정보를 제공해 주세요."])},
      "add_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추가하기"])},
      "birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["탄생"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거주 지역"])},
      "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 국적"])},
      "password_requirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호는 대문자, 소문자, 숫자, 특수문자를 포함해 8자 이상이어야 합니다."])}
    },
    "step_2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 및 휴대폰 번호 확인"])},
      "subtitle_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일로 받은 코드를 입력하세요."])},
      "subtitle_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전화로 받은 코드를 입력하세요."])},
      "email_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일이 전송되었습니다."])},
      "phone_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS가 전송되었습니다."])},
      "no_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코드를 받지 못하셨나요?"])},
      "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보내기"])},
      "validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["확인"])},
      "await_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["확인 대기 중"])},
      "code_err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유효한 코드를 입력하세요."])}
    },
    "before_kyc": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 사이트의 많은 기능을 무료로 이용하실 수 있습니다!"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지금 신원을 인증하고 전체 경험을 즐기시겠습니까?"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증된 계정 만들기"])},
      "continue_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["무료 인증 계정 만들기"])}
    },
    "step_3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신원 확인"])},
      "document_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문서 유형 선택"])},
      "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport"])},
      "identity_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신분증"])},
      "import_file_passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["여권에서 사진 가져오기"])},
      "import_file_identity_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신분증 양면 사진 가져오기"])},
      "choice_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일 선택"])},
      "condition_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["명확하고 읽기 쉬운 파일"])},
      "condition_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일 크기 최대 7MB"])},
      "condition_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JPG, JPEG 또는 PNG 형식"])},
      "validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["확인을 위해 제출하기"])},
      "verification_timing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평균 인증 시간은 약 24시간입니다."])},
      "active_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제한된 기능"])},
      "description_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록 절차를 완료하고 모든 기능에 액세스하세요."])},
      "documents_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문서 전송"])},
      "verification_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 확인 중"])},
      "verification_in_progress_restriction_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["병을 검색하고 과거 가격을 확인할 수 있습니다."])},
      "verification_in_progress_restriction_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아직 거래 부분에 액세스하거나 구독을 신청할 수는 없습니다."])},
      "send_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전송"])},
      "kbis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3개월 미만의 Kbis 추출 또는 이에 상응하는 문서"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정관 사본"])},
      "passport_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["여권 또는 신분증, 양면 모두"])},
      "document_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문서 전송:"])},
      "documents_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문서 전송:"])},
      "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거부됨"])},
      "refused_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거부 사유:"])},
      "IDENTITY_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인 신원"])},
      "REGISTRATION_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기업 아이덴티티"])},
      "ARTICLES_OF_ASSOCIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["법령"])},
      "SHAREHOLDER_DECLARATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주주 신고"])},
      "ADDRESS_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소 확인"])},
      "payment_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결제 진행"])},
      "error_card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유효하지 않은 카드 번호"])},
      "error_card_fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유효하지 않은 카드의 이름과 성"])},
      "error_card_expiration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유효 기간이 유효하지 않습니다."])},
      "error_card_cvx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["잘못된 신용카드 코드"])},
      "title_payment_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기업용 KYC 수수료"])},
      "kyc_documents_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문서가 성공적으로 전송되었습니다. 24시간 이내에 유효성 검사가 진행됩니다."])},
      "ubo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수익적 소유자 신고"])},
      "verification_timing_individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평균 인증 시간은 단 몇 분입니다."])},
      "kyc_explain_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선명하고 깨끗한 사진 촬영:"])},
      "kycexplain_content_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신분증이나 여권이 그림자나 반사 없이 완전히 보이고 모든 세부 정보를 읽을 수 있는지 확인하세요."])},
      "kyc_explain_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["호환되는 형식으로 전송하세요:"])},
      "kycexplain_content_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정보를 선명하게 볼 수 있는 충분한 해상도의 JPEG, JPG 또는 PNG 형식을 사용하세요."])},
      "kyc_explain_title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체 문서를 확인하세요:"])},
      "kycexplain_content_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필요한 모든 페이지가 별도로 포함되어 있는지 확인하세요."])},
      "documents_min_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신원 증명 2개를 추가해야 합니다."])},
      "documents_min_passeport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["패스포트에 영수증을 추가해야 합니다."])},
      "refuse_generic_kyc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일 이름 또는 문서당 최대 크기 7MB를 초과했습니다."])}
    },
    "step_4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["은행 정보"])},
      "why_enter_banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매 대금을 받으려면 은행 정보를 입력하세요."])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원님의 세부 정보는 고객 서비스 부서의 확인을 거치게 됩니다. 은행 정보가 확인되면 이메일을 보내드립니다."])},
      "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "iban_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XX11 0000 0000 0000 0000 000"])},
      "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC/ABA"])},
      "bic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC, 라우팅 번호..."])},
      "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지역"])},
      "region_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음을 입력하세요."])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제출하기"])},
      "mangopay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래소 플랫폼에서의 거래는 결제 서비스 제공업체인 망고페이를 통해 이루어집니다."])},
      "banking_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["은행 정보가 저장되었습니다."])},
      "banking_already_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["은행 정보가 이미 전송되었습니다."])}
    },
    "success": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["축하합니다! 이제 계정이 인증되었습니다."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["병 찾아보기"])},
      "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내 포트폴리오에 액세스하기"])},
      "unlock_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["무제한 활성 모드"])},
      "unlock_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이제 모든 기능을 이용할 수 있습니다."])}
    },
    "us_phone_regulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["본인은 위의 전화번호로 거래소 플랫폼의 메시지를 수신하는 데 동의합니다. 데이터 요금이 부과될 수 있으며, 수신 중지를 누르면 수신이 취소됩니다."])},
    "us_phone_regulation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2단계 인증 SMS 메시지를 거부하면 계정을 확인할 수 없습니다. SMS 2FA 시스템을 통해 휴대폰 번호를 확인한 후 언제든지 구독을 취소할 수 있습니다."])},
    "birth_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발상지"])},
    "birth_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출생 국가"])},
    "company_siret_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIRET 번호"])}
  },
  "search": {
    "barcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["바코드 스캔 또는 입력"])},
    "last_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근 검색"])},
    "delete_all_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모두 삭제"])},
    "find_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["표시되는 제품"])},
    "find_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["표시된 제품"])},
    "total_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 검색으로 찾은 제품"])},
    "total_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 검색으로 찾은 제품"])},
    "total_product_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제품 찾기"])},
    "total_products_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상품 검색"])},
    "empty_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["누락된 병이 있나요?"])},
    "filter_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터링 기준"])},
    "see_composite_ticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모두 보기"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매하기"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taker"])},
    "giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giver"])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래 차단"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름"])},
    "no_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색과 일치하는 병을 찾을 수 없습니다."])},
    "history_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색 기록 보기"])},
    "no_auth_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정을 만들어 무제한 액세스 잠금 해제하기"])},
    "no_auth_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["무료로 등록하기"])},
    "connect_access_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 기능을 사용하려면 로그인이 필요합니다."])},
    "empty": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["누락된 병"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["병 이름"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설명"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필요한 경우 누락된 병의 세부 정보를 지정하세요."])},
      "description_placeholder_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거부 사유를 설명할 수 있습니다."])},
      "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택 사항"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
      "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.thexchangeplatform.io"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보내기"])},
      "missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결과가 없습니다."])}
    },
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 주문"])},
    "bottler_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보틀러 페이지"])},
    "search_explain_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["키워드 조합을 사용할 수 있습니다."])},
    "search_explain_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색은 대소문자를 구분하지 않습니다."])},
    "search_explain_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"연도\"를 지정할 필요가 없습니다."])},
    "search_explain_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일반적인 주류 카테고리(럼, 위스키, 코냑 등)를 작성하면 결과가 표시되지 않습니다."])},
    "search_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색창에 키워드가 하나 이상 있는지 확인하세요."])}
  },
  "orders": {
    "swap_taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스왑 테이커"])},
    "swap_giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스왑 기버"])},
    "account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 유형"])},
    "current_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 주문"])},
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["방향"])},
    "creation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["생성 날짜"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가격"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수량"])},
    "update_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매 주문 수정"])},
    "update_sell_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매 주문 수정"])},
    "update_block_trade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["블록 거래 수정하기"])},
    "update_swap_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스왑 주문 수정"])},
    "display_deleted_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제된 주문 보기"])},
    "wish_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나는"])},
    "possess_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나는"])},
    "in_exchange_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그 대가로"])},
    "that_trade_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교환하고 싶은 상품"])},
    "update_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문이 성공적으로 수정됨"])},
    "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문이 성공적으로 생성됨"])},
    "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총 금액 :"])},
    "total_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문 수 :"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제목, 가격..."])}
  },
  "match": {
    "refuse_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거부 사유"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuse"])}
  },
  "product_page": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추가하기"])},
    "no_bottles_found_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["참조할 병을 찾을 수 없습니다."])},
    "add_to_portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포트폴리오에 추가하기"])},
    "btn_buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매/판매"])},
    "btn_swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스왑"])},
    "btn_blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["블록 거래"])},
    "product_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["병 정보"])},
    "ticker_composite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["티커 종합"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제목"])},
    "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주류 유형"])},
    "distillery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distillerie"])},
    "bottler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보틀러"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나이"])},
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["브랜드"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abv"])},
    "vintage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vintage"])},
    "bottling_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["병입 연도"])},
    "bottle_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["콘텐츠"])},
    "num_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["병 번호"])},
    "num_bottles_produced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["생산된 병 수"])},
    "base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base"])},
    "production_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["생산 방법"])},
    "distillery_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["증류소 상태"])},
    "classification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["분류"])},
    "empty_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 병에 대한 주문 없음"])},
    "composite_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["티커 종합"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활성"])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비활성"])},
    "search_add_bottle_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["차트에 병 검색 및 추가하기"])},
    "isbn_ticker_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN, 티커 또는 제품 이름"])},
    "bottle_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["찾고 있는 병을 찾지 못했습니다."])},
    "trades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매"])},
    "sources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출처"])},
    "empty_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["참조할 수 있는 이미지가 없습니다."])},
    "cask_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["캐스크 번호"])},
    "show_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과거 가격 보기"])},
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["실시간 주문"])},
    "number_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["병 수"])},
    "orders": {
      "create_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매 주문 생성"])},
      "create_sell_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매도 주문 생성"])},
      "create_taker_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스왑 테이커 주문 생성하기"])},
      "create_giver_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스왑 기버 주문 생성하기"])},
      "create_blocktrade_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["블록 거래 생성하기"])},
      "weight_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["무게 단위"])},
      "size_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단위 크기"])},
      "buy_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매하기"])},
      "sell_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매"])},
      "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문 생성하기"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인"])},
      "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro"])},
      "mix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mix"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 주문이 없습니다."])},
      "buy_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문 구매"])},
      "buy_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매 가격"])},
      "buy_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수량"])},
      "buy_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["옵션"])},
      "buy_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["종료 날짜와 시간을 예약할 수 있나요?"])},
      "buy_create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매 주문서 생성"])},
      "sell_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문 판매"])},
      "sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수량"])},
      "sell_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["무게"])},
      "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["길이"])},
      "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["폭"])},
      "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["높이"])},
      "sell_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사진 추가하기"])},
      "sell_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["옵션"])},
      "sell_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["종료 날짜와 시간을 예약할 수 있나요?"])},
      "sell_create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매도 주문 생성"])},
      "success_create_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문이 성공적으로 생성됨"])},
      "success_create_dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["요청이 처리되었습니다. 곧 연락드리겠습니다."])},
      "ft_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원으로서 준수해야 할 규칙"])},
      "buy_ft_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["불필요한 주문서 범람 방지"])},
      "buy_ft_1_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문은 실제 거래로 제한하세요."])},
      "buy_ft_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["반복되는 거절 방지"])},
      "buy_ft_2_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["반복적인 매칭 거부는 악의적이거나 무책임한 것으로 인식될 수 있습니다. 거래를 완료할 의사가 있는 경우에만 거래를 체결하세요."])},
      "buy_ft_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회복 시간 존중"])},
      "buy_ft_3_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소포를 제때 수거하지 못하는 일이 반복되면 판매자에게 불편을 초래하고 추가 비용이 발생할 수 있습니다."])},
      "buy_ft_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오더북의 무결성 존중"])},
      "buy_ft_4_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["호가창을 조작하거나 조작하는 행위, 매칭이 거부된 후 관심을 비활성화하는 행위 등은 기만적인 행위로 간주됩니다. 자신의 행동이 투명한지 확인하세요."])},
      "buy_ft_5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관련 비용에 대비하세요"])},
      "buy_ft_5_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현지 수입세 및 관세가 부과될 수 있습니다. 거래를 체결할 때 세금을 지불할 준비가 되어 있는지 확인하세요."])},
      "sell_ft_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정확한 정보 제공"])},
      "sell_ft_1_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["병에 대한 설명이 정확하고 진실한지 확인하세요. 이는 구매자가 정보에 입각한 결정을 내리는 데 도움이 됩니다. 전면 및 후면 전체 사진을 제공하세요."])},
      "sell_ft_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오퍼의 세부 사항을 존중하세요"])},
      "sell_ft_2_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["한 병을 판매하기로 약속하면 이 약속을 지켜야 합니다. 약속을 지키지 않으면 판매자에게 불이익이 돌아갑니다."])},
      "sell_ft_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포장 및 내용물의 품질 보장"])},
      "sell_ft_3_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["손상된 소포(변질, 파손, 누수) 및 잘못된 내용물이 반복적으로 배송되면 고객에게 영향을 미칩니다. 병을 조심스럽게 포장하세요."])},
      "sell_ft_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배송 시간 준수"])},
      "sell_ft_4_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제시간에 배송이 반복적으로 실패하면 거래 영역에 대한 액세스 권한이 제한될 수 있습니다. 지연을 피하기 위해 적절히 계획하세요."])},
      "package_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["패키지 참조:"])},
      "dispute_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["티켓 해결을 돕기 위해 여기에서 문제를 자세히 설명할 수 있습니다."])},
      "confirm_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소포 수령 확인"])},
      "confirm_shipment_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소포 수령을 확인하면 제품이 기대에 부합한다는 것을 확인하는 것입니다."])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자산"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제됨"])},
      "match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match"])},
      "match_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대기 중"])},
      "ship_send_pending_buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배송"])},
      "ship_send_pending_seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보내기"])},
      "delivery_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["운송 중"])},
      "delivery_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배달"])},
      "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소송"])},
      "end_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["완료됨"])},
      "update_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["편집"])},
      "shipping_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배송 세부 정보"])},
      "track_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추적 번호"])},
      "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유료"])},
      "track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내 소포 추적"])},
      "shipping_details_see": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배송 세부 정보 보기"])},
      "in_transit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배달 중"])},
      "order_create_block_by_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 주문을 생성하기 전에 제안을 수락하거나 거절하세요."])},
      "blocktrade_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["블록트레이드 링크"])},
      "blocktrade_unique_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공유할 고유 링크"])},
      "blocktrade_secure_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결제 서비스 제공업체 망고페이를 통한 안전한 거래"])},
      "delete_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제"])},
      "shipping_download_sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배송 노트 다운로드"])},
      "bo_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["확인 중"])},
      "anonymous_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["익명 거래(부가가치세 포함 3유로)"])},
      "max_min_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["병 이미지를 업로드해야 합니다."])},
      "duplicate_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["같은 이미지를 두 번 이상 넣을 수 없습니다."])},
      "pictures_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7MB 미만의 이미지와 확장자가 .JPG, .JPEG, .png인 이미지만 허용됩니다."])},
      "add_min_one_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문은 1시간 미만 동안 유효하지 않습니다."])},
      "empty_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 구매 주문은 없습니다."])},
      "empty_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재는 매도 주문이 없습니다."])},
      "anonymous_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매자는 회원님의 신원을 알 수 없습니다."])},
      "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["홈 컬렉션"])},
      "pickup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소포는 UPS에서 자택에서 수거합니다."])},
      "pickup_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수집 날짜"])},
      "pickup_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["첫 수령 시간"])},
      "pickup_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마지막 수령 시간"])},
      "not_match_preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 주문이 회원님의 국가 선호도 또는 거래 상대방의 국가 선호도와 일치하지 않습니다."])},
      "load_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미지 추가"])},
      "or_drag_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["또는 드래그 앤 드롭"])},
      "file_format_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".JPG, .PNG 및 .JPEG만 가능(사진당 최대 7MB)"])},
      "pickup_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["릴레이 포인트에서 사용 가능"])},
      "kyc_not_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래 섹션에 액세스하려면 신원을 인증해야 합니다."])},
      "kyc_redirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내 신원 확인"])},
      "create_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알림 만들기"])},
      "create_alert_explain_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매도 주문이 생성되면 이메일을 받게 됩니다."])},
      "create_alert_explain_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매 주문이 생성되면 이메일을 받게 됩니다."])},
      "create_alert_explain_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문이 생성되면 이메일을 받습니다."])},
      "create_alert_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알림을 만들려면 원하는 제품 페이지로 이동하세요."])},
      "order_alert_price_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가격(선택 사항)"])},
      "order_alert_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알림이 생성되었습니다."])},
      "order_alert_errored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알림을 만드는 동안 오류가 발생했습니다."])},
      "order_alert_created_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가격은 50~30000유로 또는 0유로 사이여야 합니다."])},
      "order_alert_paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 알림 일시 중지하기"])},
      "order_alert_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 알림 다시 활성화하기"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비활성화됨"])},
      "blocktrade_link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["링크를 복사하여 블록트레이드 주문을 공유하세요."])},
      "blocktrade_countdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["블록 거래는 최대 2시간 동안 가능합니다. 이 비공개 판매 주문을 공유하려면 위의 버튼을 클릭해 링크를 복사하세요."])},
      "success_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["블록트레이드 링크가 올바르게 복사되었습니다."])},
      "wrong_value_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입력 내용을 확인하세요."])},
      "bottle_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단위당 가격(50-30000)"])},
      "package_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["패키지 크기"])},
      "add_bottle_basket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["병 추가하기"])},
      "choose_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["만료일"])},
      "duplicate_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["동일한 참조를 두 번 추가할 수 없습니다."])},
      "maximum_sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단일 판매에 대해 승인된 최대 수량에 도달했습니다."])},
      "add_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소포 추가하기"])},
      "sell_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가격 목록"])},
      "min_pictures_required_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최소한 다음을 추가해야 합니다."])},
      "min_pictures_required_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 사진"])}
    },
    "price_history": {
      "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과거 가격"])},
      "compare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["병 추가하기"])},
      "responsability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["그래프는 정보 제공을 위한 것입니다. 당사는 어떠한 오류에 대해서도 책임을 지지 않습니다."])},
      "data_info_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과거 데이터 집계"])},
      "data_info_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매"])},
      "data_info_11-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매"])},
      "data_info_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["에서"])},
      "data_info_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다양한 출처."])},
      "data_info_3-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출처."])},
      "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["통화"])},
      "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수수료"])},
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평균"])},
      "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top"])},
      "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bas"])},
      "base_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본 100"])},
      "line_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["꺾은선형 차트"])},
      "candlesticks_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["캔들 차트"])},
      "chart_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["차트 옵션"])},
      "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the"])},
      "perf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["성능"])},
      "since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["에서"])},
      "delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가격은 다음과 같이 지연되어 표시됩니다."])},
      "delay_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["월"])},
      "no_recent_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과거 가격 데이터는 제공되지 않습니다. 아직 포함되지 않은 경우 지원팀에 문의하여 추가해 주시기 바랍니다."])},
      "current_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 보기"])},
      "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매일"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["월별"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연간"])},
      "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주간"])},
      "compare_dialog": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비교 대상"])},
        "no_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택한 병이 없습니다."])},
        "same_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 병은 이미 차트에 있습니다."])},
        "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["차트의 6병 제한에 도달했습니다."])}
      },
      "composite": {
        "display_composite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["종합 보기"])}
      }
    },
    "report": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오류 신고하기"])},
      "error_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오류 유형"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비고"])},
      "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택 사항"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["여기에서 문제를 더 정확하게 설명하세요..."])},
      "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.thexchangeplatform.io"])},
      "pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스크린샷 / 이미지"])},
      "pictures_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최대 이미지 2개."])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보내기"])}
    },
    "shipping_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배송 세부 정보"])},
    "success_send_error_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["귀하의 요청이 고려되었습니다."])},
    "release_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출시일"])},
    "release_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["종료 가격"])}
  },
  "matchs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매치"])},
    "match_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매자 매칭"])},
    "match_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매자 매칭"])},
    "accept_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매하기"])},
    "accept_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수락 및 판매"])},
    "show_transactions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["내 ", _interpolate(_named("num_transac")), " 현재 거래 보기"])},
    "show_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사진 보기"])},
    "buying_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내 구매 주문"])},
    "selling_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문 판매"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매자"])},
    "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["진행 중"])},
    "buying_order_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문 구매"])},
    "sold_by_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래 상대방"])},
    "selling_order_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내 판매 주문"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["표시할 일치하는 항목이 없습니다."])}
  },
  "account_verification_details": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["누구나 이용할 수 있는 계정 생성 및 기능"])},
    "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["특징"])},
    "unverified_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미감사 계정"])},
    "verified_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증된 계정"])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["뉴스피드"])},
    "full_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["무료 무제한 전체 액세스"])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과거 가격"])},
    "advanced_visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고급 시각화"])},
    "month_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15개월 연기"])},
    "month_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12개월 연기"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포트폴리오"])},
    "portfolio_month_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15개월 이연 평가"])},
    "portfolio_month_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12개월 이연 평가"])},
    "tickers_limit_50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50개의 고유 시세"])},
    "tickers_limit_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100개의 고유 시세"])},
    "database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["데이터베이스"])},
    "tickers_limit_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["하루 50개의 티커"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래"])},
    "orders_visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["활성 주문 보기"])},
    "orders_not_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래 액세스 권한 없음"])},
    "kyc_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신분증 인증은 개인에게는 무료이며, 전문가에게는 수수료가 부과됩니다. 결제 서비스 제공업체인 망고페이에서 KYC를 수행합니다."])},
    "not_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["무제한 전체 액세스"])}
  },
  "portfolio": {
    "copied_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID 복사"])},
    "folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일"])},
    "import_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV 또는 Excel 파일 가져오기"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추가하기"])},
    "bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottle"])},
    "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모두 선택"])},
    "unselect_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모두 선택 해제"])},
    "research_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["병 검색하기"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포트폴리오"])},
    "to_guide_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["여러분을 안내합니다,"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다운로드"])},
    "load_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일 업로드"])},
    "or_drag_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["또는 드래그 앤 드롭"])},
    "file_csv_and_xlsx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV 및 XLSX 파일만 가능"])},
    "an_import_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수입 모델"])},
    "columns_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["칼럼"])},
    "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추가하기"])},
    "average_holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평균 보유 기간"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포트폴리오"])},
    "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름 바꾸기"])},
    "move_in_another_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다른 폴더로 이동"])},
    "rename_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["폴더 이름 바꾸기"])},
    "empty_folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일 이름은 필수입니다."])},
    "max_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최대 글자 수는 30자를 초과할 수 없습니다."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수입업자"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매개변수"])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["통계"])},
    "column_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["테이블 열"])},
    "options_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["옵션 표"])},
    "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["옵션"])},
    "foldAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모두 접기"])},
    "unfoldAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모두 펼치기"])},
    "show_sell_stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매된 주식 표시"])},
    "bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["병"])},
    "tickers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["티커"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정보"])},
    "th_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["날짜"])},
    "th_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수량"])},
    "th_net_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["순 가격"])},
    "th_gross_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총 가격"])},
    "price_with_commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수수료"])},
    "th_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수수료"])},
    "th_storage_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장 위치"])},
    "th_holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holding"])},
    "th_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["첨부 파일"])},
    "th_buy_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매처"])},
    "add_folder_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일 추가"])},
    "add_bottle_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["병 추가하기"])},
    "name_parent_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상위 폴더 이름"])},
    "additional_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추가 칼럼"])},
    "column_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["컬럼 순서"])},
    "my_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내 병"])},
    "purchaseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매 날짜"])},
    "addPriceOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가격 옵션 추가하기"])},
    "sellDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매 날짜"])},
    "swap_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래소 날짜"])},
    "sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매"])},
    "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매하기"])},
    "no_bottles_found_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색과 일치하는 병을 찾을 수 없습니다."])},
    "select_parent_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상위 폴더 선택"])},
    "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일 이름"])},
    "create_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 만들기"])},
    "modify_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["병 변경"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설명"])},
    "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["진행 중"])},
    "bottle_on_hold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보유 병 수"])},
    "bottle_on_hold_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보유 중인 병"])},
    "unique_references_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고유 참조"])},
    "on_position_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소유한 병 수에 각 구매 가격을 곱한 금액"])},
    "AUM_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소유한 병 수에 시장 가치를 곱한 금액"])},
    "running_pnl_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["또한 시장 가치와 구매 가격의 차이에 수량을 곱한 값인 시가 총액도 확인할 수 있습니다."])},
    "running_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 실적"])},
    "running_year_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 연간 수익률"])},
    "average_price_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 병의 평균 가격"])},
    "average_holding_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매자가 포트폴리오에 보유한 평균 보유 기간"])},
    "bought_bottles_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지금까지 구매한 병"])},
    "sold_bottles_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지금까지 판매한 병"])},
    "total_spent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재까지 총 지출액"])},
    "total_sold_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재까지 총 판매량"])},
    "total_pnl_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["첫날부터 현재까지 누적 수익"])},
    "total_pnl_period_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택한 기간 동안의 실현 수익"])},
    "realized_total_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["첫날부터 계산된 수익률(판매된 병만 고려)"])},
    "realized_year_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연간 유효 수익률(판매된 병 수)"])},
    "unique_references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고유 참조"])},
    "on_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초기 가치 포지션"])},
    "running_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 실적"])},
    "running_year_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연간 수익률"])},
    "realized_year_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과거 연간 수익률"])},
    "realized_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과거 실현 수익률"])},
    "average_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평균 가격"])},
    "historical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["역사"])},
    "bought_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매한 병"])},
    "unique_ticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고유한 티커"])},
    "AUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["밸류에이션"])},
    "running_pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["잠재 수익"])},
    "sold_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["병 판매"])},
    "total_spent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총 지출"])},
    "total_pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총 실현 이익"])},
    "total_pnl_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["실현 이익(기간)"])},
    "realized_total_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["과거 실현 수익률"])},
    "total_sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총 판매량"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매/판매 날짜"])},
    "net_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["순 가격"])},
    "price_without_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가격은 세금 별도입니다."])},
    "price_with_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수수료가 포함된 가격"])},
    "price_with_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부가가치세 포함 가격"])},
    "price_net": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["순 가격"])},
    "price_gross_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총 구매 가격"])},
    "price_gross_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총 판매 가격"])},
    "storage_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["저장 위치"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수량"])},
    "boughts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매하기"])},
    "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매"])},
    "details_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["참조 세부 정보"])},
    "are_you_sure_delete_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 파일을 삭제하시겠습니까?"])},
    "xlsx_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["엑셀 파일"])},
    "file_optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["엑셀 파일을 위한 팁"])},
    "displayed_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["표시되는 열"])},
    "check_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모두 선택"])},
    "stockage_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 공간"])},
    "add_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 만들기"])},
    "add_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["병 추가하기"])},
    "import_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일 가져오기"])},
    "current_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 병"])},
    "current_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 병"])},
    "current_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 참조"])},
    "current_references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 참조"])},
    "current_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 서류"])},
    "move_to_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["폴더로 이동"])},
    "move_bottles_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["폴더의 병"])},
    "move_references_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서류 내 참고 자료"])},
    "move_bottle_to_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["폴더로 이동"])},
    "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수수료"])},
    "holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보유량(일)"])},
    "files_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["첨부 파일"])},
    "buy_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매처"])},
    "sell_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매 장소"])},
    "sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매 수량"])},
    "wab_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WAB 가격"])},
    "wasd_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WASD 어워드"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리"])},
    "sub_asset_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자산 클래스"])},
    "see_product_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제품 시트로 이동"])},
    "last_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최종 가격"])},
    "volatility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변동성"])},
    "uncheck_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모두 선택 해제"])},
    "selected_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택한 병"])},
    "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이동"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제"])},
    "delete_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["병 삭제"])},
    "tel_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포트폴리오는 현재 PC에서만 사용할 수 있지만 곧 모바일에서도 사용할 수 있게 될 예정입니다."])},
    "volatility_portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포트폴리오 변동성"])},
    "volatility_spirits_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주류의 변동성"])},
    "volatility_distillery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["증류주 변동성"])},
    "volatility_vintage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["빈티지 변동성"])},
    "volatility_age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변동성 시대"])},
    "portfolio_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포트폴리오 프리미엄 출시 예정"])},
    "see_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자세히 보기"])},
    "go_to_product_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제품 페이지로 이동"])},
    "folder_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일 생성"])},
    "folder_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일이 수정되었습니다."])},
    "folder_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일이 삭제되었습니다."])},
    "folder_created_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일 생성 중 오류 발생"])},
    "bottle_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["병이 추가되었습니다."])},
    "bottle_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["병이 수정되었습니다."])},
    "bottle_move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["병이 이동되었습니다."])},
    "file_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일을 성공적으로 가져왔습니다."])},
    "move_modal_title_origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["원본 파일 :"])},
    "move_modal_content_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 폴더와 그 안에 있는 모든 병을 옮기게 됩니다."])},
    "move_folder_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일이 이동되었습니다."])},
    "folder_contain_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 폴더에 병이 있어 삭제할 수 없습니다."])}
  },
  "bid": {
    "send_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오퍼 보내기"])}
  },
  "create_order": {
    "file_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3Mb 미만의 .jpg, .jpeg 및 .png 파일만 허용됩니다."])}
  },
  "payment_process": {
    "transaction_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래 세부 정보"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문 요약"])},
    "shipping_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배송비"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세금"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매하기"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuse"])},
    "sold_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매자 :"])},
    "delivery_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배송비"])},
    "selling_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래 수수료"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총 금액"])},
    "price_tva_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["부가가치세 포함"])},
    "shipping_ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS 배송료"])},
    "payment_type_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결제 방법"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결제 방법"])},
    "inssurance_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS 보험"])},
    "ups_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["집에서의 UPS"])},
    "ups_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS 포인트 릴리스"])},
    "relay_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인출 포인트 사용 가능"])},
    "save_relay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["향후 구매를 위해 이 주소를 저장하세요."])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래가 실패했습니다. 다시 시도해 주세요."])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래가 성공적으로 완료되었습니다."])},
    "payment_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결제가 확인 중입니다."])},
    "payment_please_wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["잠시만 기다려주세요."])},
    "add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장바구니에 담기"])},
    "remove_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["장바구니에서 제거"])}
  },
  "settings": {
    "title_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 설정"])},
    "title_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구독 관리"])},
    "my_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내 구독"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결제 방법"])},
    "invoice_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["송장 내역"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내 계정"])},
    "buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매 및 판매"])},
    "bank_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["은행 정보"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["안전 및 2단계 인증"])},
    "personnal_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정보"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회사"])},
    "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시민성"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["국적"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름"])},
    "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["생년월일"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전화번호"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소"])},
    "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["우편번호"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지역"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["국가"])},
    "other_delivery_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다른 배송 주소 추가"])},
    "delivery_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배송 주소"])},
    "delivery_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배송 주소 삭제"])},
    "billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결제 주소"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["편집"])},
    "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전송 대상"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정보 없음"])},
    "fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 소유자"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전송"])},
    "update_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RIB 변경하기"])},
    "2fa_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이중 인증"])},
    "2fa_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인할 때마다 그리고 계정이 변경될 때마다 코드를 받습니다."])},
    "2fa_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일을 통한 이중 인증"])},
    "2fa_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS를 통한 이중 인증"])},
    "login_history_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최신 연결"])},
    "login_history_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 디바이스에서 계정에 대한 최근 10개 연결 기록을 볼 수 있습니다."])},
    "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["뉴스레터 받기"])},
    "card_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["직불 카드 번호."])},
    "card_created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추가됨"])},
    "card_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["편집"])},
    "card_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["만료일"])},
    "card_update_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내 은행 카드 변경"])},
    "card_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아직 결제 카드를 등록하지 않았습니다."])},
    "delivery_choice_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 주소를 기본값으로 설정"])},
    "delivery_is_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본 배송 주소"])},
    "update_dialog": {
      "title_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 주소 변경"])},
      "actual_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 이메일 주소"])},
      "new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 이메일 주소"])},
      "title_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전화번호 변경"])},
      "actual_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 전화 번호"])},
      "new_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 전화번호"])},
      "title_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배송 주소 추가"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록하기"])},
      "err_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일이 잘못되었습니다."])},
      "err_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["잘못된 전화번호"])},
      "err_email_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 이 이메일 주소가 있습니다."])},
      "err_phone_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 이 전화번호를 알고 있습니다."])}
    },
    "subscriptions": {
      "last_month_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이번 달 지출"])},
      "sub_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구독자 수"])},
      "never_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["귀하는 구독자가 아닙니다."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["연간 구독"])},
      "next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음 수집 날짜"])},
      "subscription_term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구독 기간"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구독 취소"])},
      "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 가능한 과거 가격 구독 살펴보기"])},
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구독 취소"])},
      "cancel_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구독은 다음 날짜에 만료됩니다."])},
      "cancel_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관련 구독"])},
      "cancel_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소 확인"])},
      "reactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구독 재개"])},
      "reactive_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구독 계속하기"])},
      "reactive_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음 결제는 다음 날짜에 이루어집니다."])},
      "reactive_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관련 구독"])},
      "reactive_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결제는 매번 이루어집니다."])},
      "reactive_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가격"])},
      "reactive_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["복구 확인"])},
      "commitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["약정 기간"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["월"])},
      "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ans"])},
      "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["프리미엄 과거 가격"])}
    },
    "invoices": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["송장 내역"])},
      "download_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모두 다운로드"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["날짜"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가격"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상태"])},
      "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구독"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인보이스 다운로드"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유료"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인보이스가 없습니다."])},
      "delivery_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배송 세부 정보"])},
      "track_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["패키지 추적"])},
      "delivery_estimation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예상 배송"])},
      "transaction_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래 번호"])}
    },
    "confirm": {
      "subtitle_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일로 받은 코드를 입력하세요."])},
      "subtitle_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전화로 받은 코드를 입력하세요."])},
      "new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 이메일"])},
      "new_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 전화"])},
      "code_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일로 받은 코드를 입력합니다."])},
      "code_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전화로 받은 코드를 입력합니다."])}
    },
    "billing_address_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["송장 및 배송 주소"])},
    "preferences_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기본 설정이 성공적으로 등록되었습니다."])},
    "birth_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출생 도시"])},
    "birth_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출생 국가"])},
    "preferences_mails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알림"])},
    "preference_t_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매 주문 생성하기"])},
    "preference_t_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매도 주문 생성하기"])},
    "preference_t_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스왑 주문 생성하기"])},
    "preference_t_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["블록트레이드 주문 생성하기"])},
    "preference_t_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매자가 매칭을 거절했습니다."])},
    "preference_t_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["확인을 기다리는 판매 주문"])},
    "preference_t_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS 수신 승인(계정 인증 및/또는 2단계 인증)"])},
    "preferences": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["커뮤니케이션 기본 설정"])}
    },
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름"])},
    "relay_ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS 릴레이 포인트"])},
    "buySellPreferences": {
      "buying_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매 옵션"])},
      "selling_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매 옵션"])},
      "buying_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매 가시성"])},
      "selling_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매 가시성"])},
      "country_exclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제외할 국가"])},
      "country_inclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포함 국가"])},
      "preferences_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매에 대한 내 구매 선호도 복사"])},
      "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경 사항이 반영되었습니다."])}
    },
    "update_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 정보 수정하기"])},
    "wrong_pseudo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자 아이디를 확인해 주세요."])},
    "catch_update_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정보를 업데이트할 수 없습니다. 문제가 지속되면 지원팀에 문의하세요."])},
    "pseudo_already_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 사용자 아이디는 더 이상 사용할 수 없습니다. 다른 아이디를 선택해 주세요."])},
    "update_pseudo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내 정보 수정하기"])}
  },
  "footer": {
    "social_medias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소셜 네트워크"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지원"])},
    "legals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["법률"])},
    "help_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["도움말 센터"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자주 묻는 질문"])},
    "recruitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모집"])},
    "bug_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["버그 신고하기"])},
    "service_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 현황"])},
    "legal_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["법률 정보"])},
    "cgu_cgv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이용약관"])},
    "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["쿠키 관리"])},
    "tep_footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["프랑스에 등록된 회사"])}
  },
  "paiment_process": {
    "shipping_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배송비"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세금"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구매하기"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuse"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문 요약"])},
    "sold_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매된 부분 :"])},
    "delivery_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배송비"])},
    "selling_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매 수수료"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총 금액"])}
  },
  "transactions": {
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sens"])},
    "dispute_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["진행 중인 소송"])},
    "dispute_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["분쟁 신고하기"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래는 진행되지 않습니다."])}
  },
  "blog": {
    "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터 재설정"])},
    "published_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["에 게시되었습니다:"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["에 수정되었습니다:"])},
    "articles_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기사 필터링하기"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["항목을 찾을 수 없습니다."])}
  },
  "wallet": {
    "available_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 가능한 금액"])},
    "waiting_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보류 중"])},
    "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총 금액"])},
    "withdraw_money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출금"])},
    "money_movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["움직임"])},
    "payin_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결제하기"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["표시할 거래는 없습니다."])},
    "transfert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전송"])},
    "transfert_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수신 이체"])},
    "transfert_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["송금"])},
    "payout_seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["판매자 결제"])},
    "bank_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출금"])},
    "bank_payout_in_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내 은행 계좌로 출금하기"])},
    "payout_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 가능한 자금 인출"])},
    "payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출금 확인"])},
    "success_payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["은행 계좌로의 이체가 성공적으로 완료되었습니다."])},
    "failed_payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정으로 이체하지 못했습니다. 문제가 지속되면 고객 서비스에 문의하세요."])},
    "order_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내 지갑 (0%)"])},
    "available_after_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래 후 남은 금액 :"])},
    "payin_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대금 수령"])},
    "transactions_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내 거래 내역에 액세스하기"])},
    "send_iban_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결제 수수료를 피하기 위해 지갑으로 직접 송금하기"])},
    "wallet_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소유자 :"])},
    "wallet_iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN :"])},
    "wallet_bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC :"])},
    "wallet_await_funds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자금은 영업일 기준 2~3일 이내에 사용할 수 있습니다."])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["요소가 성공적으로 복사되었습니다."])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["복사"])},
    "wallet_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지갑 프로비저닝"])},
    "instant_payout_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["결제는 기본적으로 즉시 이체를 통해 이루어집니다."])},
    "available_amount_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출금 가능한 금액 :"])},
    "banking_account_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로 끝나는 은행 계좌"])}
  },
  "filters": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고급 필터"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필터"])},
    "filters_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["키워드가 없는 검색에는 2개의 필터가 필요합니다."])}
  },
  "ratings": {
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가"])},
    "ratings_phone_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등급 :"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["참고"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설명"])},
    "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설명은 최대 500자까지 입력할 수 있습니다."])},
    "create_error_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가는 1~100점 사이로, 설명은 500자 이내로 작성해 주세요."])},
    "create_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가 작성하기"])},
    "create_btn_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가 작성하기"])},
    "see_or_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등급 보기 또는 변경"])},
    "update_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가치 평가 변경"])},
    "delete_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등급 삭제"])},
    "rating_explain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가를 작성하려면 제품 페이지로 이동하세요."])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카테고리, 이름, 점수..."])},
    "description_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설명은 비공개입니다. 공개적으로 제공되지 않습니다."])},
    "not_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택 사항"])},
    "rating_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평균을 내기 위한 최소값에 도달하지 못했습니다."])},
    "pseudo_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5~50자(문자와 숫자 입력 가능)"])},
    "empty_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아직 이 병에 대한 평가가 없습니다."])},
    "ranking_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 사용자의 출발점입니다."])},
    "ranking_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["정기적인 기여자를 표창합니다."])},
    "ranking_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고급 헌신과 전문성을 나타냅니다."])},
    "ranking_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["진정한 헌신."])},
    "ranking_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["커뮤니티에서 리더십 역할을 인정받습니다."])},
    "ranking_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매우 활동적인 사용자만 이용할 수 있습니다."])},
    "ranking_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가장 헌신적인 기여자를 위한 최고의 등급입니다."])},
    "rewards_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["각 레벨에 대한 보상은 1월에 발표될 예정입니다!"])},
    "pseudo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["닉네임"])},
    "private_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내 댓글 비공개로 설정하기"])},
    "profil_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["프로필"])},
    "rating_unitary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가치 평가"])},
    "rating_multi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평가"])}
  }
}