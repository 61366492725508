<template>
    <div class="ranking-informations">

        <el-divider class="divier-ranking" />

        <div class="ranking-list">
            <div class="ranking-title">
                <img src="@/assets/svg/v2/starPlain_1.svg" alt="User rating review" width="20" class="img-grade-review" />
                <h4>Newcomer</h4>
                <span>(0-9 Reviews)</span>
            </div>
            <div class="ranking-explain">
                {{ $t('ratings.ranking_1') }}
            </div>
        </div>

        <div class="ranking-list">
            <div class="ranking-title">
                <img src="@/assets/svg/v2/starPlain_2.svg" alt="User rating review" width="20" class="img-grade-review" />
                <h4 class="ranking-bronze">Enthusiast</h4>
                <span>(10-49 Reviews)</span>
            </div>
            <div class="ranking-explain">
                {{ $t('ratings.ranking_2') }}
            </div>
        </div>

        <div class="ranking-list">
            <div class="ranking-title">
                <img src="@/assets/svg/v2/starPlain_3.svg" alt="User rating review" width="20" class="img-grade-review" />
                <h4 class="ranking-argent">Expert</h4>
                <span>(50-99 Reviews)</span>
            </div>
            <div class="ranking-explain">
                {{ $t('ratings.ranking_3') }}
            </div>
        </div>

        <div class="ranking-list">
            <div class="ranking-title">
                <img src="@/assets/svg/v2/starPlain_4.svg" alt="User rating review" width="20" class="img-grade-review" />
                <h4 class="ranking-gold">Connoisseur</h4>
                <span>(100-249 Reviews)</span>
            </div>
            <div class="ranking-explain">
                {{ $t('ratings.ranking_4') }}
            </div>
        </div>

        <div class="ranking-list">
            <div class="ranking-title">
                <img src="@/assets/svg/v2/starPlain_5.svg" alt="User rating review" width="20" class="img-grade-review" />
                <h4 class="ranking-green">Curator</h4>
                <span>(250-499 Reviews)</span>
            </div>
            <div class="ranking-explain">
                {{ $t('ratings.ranking_5') }}
            </div>
        </div>

        <div class="ranking-list">
            <div class="ranking-title">
                <img src="@/assets/svg/v2/starPlain_6.svg" alt="User rating review" width="20" class="img-grade-review" />
                <h4 class="ranking-red">Master Taster</h4>
                <span>(500-999 Reviews)</span>
            </div>
            <div class="ranking-explain">
                {{ $t('ratings.ranking_6') }}
            </div>
        </div>

        <div class="ranking-list">
            <div class="ranking-title">
                <img src="@/assets/svg/v2/starPlain_7.svg" alt="User rating review" width="20" class="img-grade-review" />
                <h4 class="ranking-tep">Legend</h4>
                <span>(1,000+ Reviews)</span>
            </div>
            <div class="ranking-explain">
                {{ $t('ratings.ranking_7') }}
            </div>
        </div>

        <div class="rewards-soon">
            <img src="@/assets/svg/v2/infos.svg" alt="Informations" />
            {{ $t('ratings.rewards_soon') }}
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
      return {}
    }
}
</script>
  
<style scoped lang="scss">
@import '@/assets/styles/root.scss';

.ranking-informations {
    margin-top: 20px;

    .ranking-list {
        margin-bottom: 12px;

        .ranking-title {
            display: flex;
            margin-bottom: 2px;

            h4 {
                margin-right: 8px;
            }
        }
    }
}

.ranking-bronze {
    color: #cc6633;
}

.ranking-argent {
    color: #c0c0c0;
}

.ranking-gold {
    color: #FFD700;
}

.ranking-green {
    color: #008000;
}

.ranking-red {
    color: #D22B2B;
}

.ranking-tep {
    color: $blue;
}

.rewards-soon {
    display: flex;
    align-items: center;
    margin-top: 25px;
    font-size: 14px;

    img {
        margin-right: 5px;
    }
}

.divier-ranking {
    margin-bottom: 20px;
}

@media screen and (max-width: 500px) {

}
</style>