<template>
    <div class="product-reviews">
        <h2>{{ $t('ratings.ratings') }}</h2>

        <div class="reviews-container">
            <div class="unitary-review" v-for="(review, n) in reviews" :key="n">
                <div class="review-header">
                    <div class="review-header-info">
                        <el-tooltip
                            class="tooltip-rating"
                            effect="dark"
                            :content="ranks[review.ranking_code - 1]"
                            placement="top"
                        >
                            <img :src="require(`@/assets/svg/v2/starPlain_${review.ranking_code}.svg`)" alt="User rating review" width="20" class="img-grade-review" />
                        </el-tooltip>

                        <h4 @click="openDrawerProfil(review)" :class="{ rankingBronze: review.user_rating_count > 9, rankingArgent: review.user_rating_count > 49, rankingGold: review.user_rating_count > 99, rankingGreen: review.user_rating_count > 249, rankingRed: review.user_rating_count > 499, rankingTep: review.user_rating_count > 999 }">{{ review.pseudo }} ({{ review.user_rating_count }})</h4>

                        <div class="review-scoring">
                            {{ review.score }}<span>/100</span>
                        </div>
                    </div>
                    <div class="review-header-date">
                        {{ convertDate(review.created_at) }}
                    </div>
                </div>

                <div v-if="review.description" class="review-description">
                    {{ review.description }}
                </div>

                <div v-if="review.description" class="review-like">
                    <button v-if="!review.is_like && review.description" @click="like(review.review_uuid)">
                        <img src="@/assets/svg/v2/likeWhite.svg" alt="User not like" width="20" />
                    </button>

                    <button v-else-if="review.is_like && review.description" @click="like(review.review_uuid)">
                        <img src="@/assets/svg/v2/likeBlue.svg" alt="User not like" width="20" />
                    </button>
                    
                    <p v-if="review.description">{{ review.count }}</p>
                </div>

                <el-divider v-if="reviews.length > 1 && (n+1) < reviews.length" class="divider-review" />

                <el-drawer
                    v-model="drawerProfilView"
                    :title="$t('ratings.profil_title')"
                    :size="drawerSize"
                >
                    <profil :userReview="review" />
                </el-drawer>

            </div>

            <div v-if="reviews.length === 0">
                <p>{{ $t('ratings.empty_reviews') }}</p>
            </div>
        </div>

    </div>
</template>
  
<script>
import m from 'moment';
import { mapActions, mapGetters } from 'vuex';
import profil from './profil.vue';

export default {
    props: {
        reviews: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    components: {
        profil
    },
    data() {
      return {
        drawerProfilView: false,
        ranks: ['Newcomer', 'Enthusiast', 'Expert', 'Connoisseur', 'Curator', 'Master Taster', 'Legend']
      }
    },
    computed: {
        ...mapGetters({
        }),
        drawerSize() {
            if (window.innerWidth < 500) {
                return '100%'
            }
            return '40%'
        },
    },
    methods: {
        ...mapActions({
            likeReview: 'likeReview',
            product: 'product',
            reviewProfil: 'reviewProfil'
        }),
        convertDate(date) {
            return m(date).format('DD/MM/YYYY');
        },
        async like(review_uuid) {
            const payload = {
                review_uuid: review_uuid
            }
            
            await this.likeReview(payload).then(async () => {
                const payloadProduct = {
                    isbn: this.$route.params.isbn
                }

                await this.product(payloadProduct);
            })
        },
        async openDrawerProfil(review) {
            const payload = {
            pseudo : review.pseudo
          }
          await this.reviewProfil(payload).then(() => {
            this.drawerProfilView = true;
          });
        }
    }
}
</script>
  
<style scoped lang="scss">
@import '@/assets/styles/root.scss';

.product-reviews {
    margin-bottom: 30px;

    .reviews-container {
        border-radius: 12px;
        border: 1px solid rgba(255, 255, 255, 0.2392156863);
        background: #333;
        margin-top: 22px;
        padding: 14px 20px;
    }
}

.unitary-review {

    .review-header {
        display: flex;
        justify-content: space-between;

        .review-header-info {
            display: flex;
            align-items: center;

            h4 {
                font-weight: 400;
                border-bottom: 1px dashed white;
                cursor: pointer;
            }

            .review-scoring {
                margin-left: 10px;
                font-weight: 600;

                span {
                    font-weight: 400;
                }
            }
        }

        .review-header-date {
            display: flex;
            align-items: center;
            font-size: 13px;
        }
    }

    .review-description {
        margin-top: 8px;
    }

    .review-like {
        display: flex;
        margin-top: 8px;

        button {
            background: transparent;
            border: 0;
        }

        p {
            margin-left: 7px;
        }
    }
}

.divider-review {
    margin: 15px auto;
    margin-top: 7px;
}

.img-grade-review {
    margin-right: 3px;
}

@media screen and (max-width: 500px) {
    .product-reviews {

        h2 {
            margin-left: 5px;
        }

        .reviews-container {
            border: 0;
            border-radius: 0px;
        }
    }

}
</style>