<template>
    <div v-if="getUserReviewsProfil" class="review-profil">
      <div class="review-header">
          <div class="review-header-info">
              <el-tooltip
                  class="tooltip-rating"
                  effect="dark"
                  :content="ranks[userReview.ranking_code - 1]"
                  placement="top"
              >
                  <img :src="require(`@/assets/svg/v2/starPlain_${userReview.ranking_code}.svg`)" alt="User rating review" width="20" class="img-grade-review" />
              </el-tooltip>

              <h4 :class="{ rankingBronze: userReview.user_rating_count > 9, rankingArgent: userReview.user_rating_count > 49, rankingGold: userReview.user_rating_count > 99, rankingGreen: userReview.user_rating_count > 249, rankingRed: userReview.user_rating_count > 499, rankingTep: userReview.user_rating_count > 999 }">{{ userReview.pseudo }} ({{ userReview.user_rating_count }})</h4>
          </div>
          <div class="review-header-likes">
            <img src="@/assets/svg/v2/likeBlue.svg" alt="User not like" width="20" />
            <span>{{ getUserReviewsProfil.likes }}</span>
          </div>
      </div>

      <el-divider />

      <h4>Evaluation par catégories</h4>

      <div class="spirits-types-list">
        <div class="rhum-ron-rum">
          <img
            src="@/assets/svg/v2/categories/rhum_ron_rum.svg"
            alt="Rhum, Ron & Rum"
          />
          <p>{{ getUserReviewsProfil.spirits_types.rhums }} {{ getUserReviewsProfil.spirits_types.rhums === 0 || getUserReviewsProfil.spirits_types.rhums === 1 ? $t('ratings.rating_unitary') : $t('ratings.rating_multi') }} Rhums</p>
        </div>
        <div class="whiskey-whisky">
          <img
            src="@/assets/svg/v2/categories/whiskey_whisky.svg"
            alt="Whiskey & Whisky"
          />
          <p>{{ getUserReviewsProfil.spirits_types.whiksy }} {{ getUserReviewsProfil.spirits_types.whiksy === 0 || getUserReviewsProfil.spirits_types.whiksy === 1 ? $t('ratings.rating_unitary') : $t('ratings.rating_multi') }} Whisky</p>
        </div>
        <div class="bourbon-rye">
          <img
            src="@/assets/svg/v2/categories/bourbon_rye.svg"
            alt="Bourbon & Rye"
          />
          <p>{{ getUserReviewsProfil.spirits_types.american_whisky }} {{ getUserReviewsProfil.spirits_types.american_whisky === 0 || getUserReviewsProfil.spirits_types.american_whisky === 1 ? $t('ratings.rating_unitary') : $t('ratings.rating_multi') }} American Whisky</p>
        </div>
        <div class="tequila">
          <img
            src="@/assets/svg/v2/categories/tequila.svg"
            alt="Tequila"
          />
          <p>{{ getUserReviewsProfil.spirits_types.eau_de_vie }} {{ getUserReviewsProfil.spirits_types.eau_de_vie === 0 || getUserReviewsProfil.spirits_types.eau_de_vie === 1 ? $t('ratings.rating_unitary') : $t('ratings.rating_multi') }} Eau de vie</p>
        </div>
        <div class="cognac">
          <img src="@/assets/svg/v2/categories/cognac.svg" alt="Cognac" />
          <p>{{ getUserReviewsProfil.spirits_types.various }} {{ getUserReviewsProfil.spirits_types.various === 0 || getUserReviewsProfil.spirits_types.various === 1 ? $t('ratings.rating_unitary') : $t('ratings.rating_multi') }} Agave, Distillate & Various</p>
        </div>
        <div class="liquors">
          <img
            src="@/assets/svg/v2/categories/liquors.svg"
            alt="Liquors"
          />
          <p>{{ getUserReviewsProfil.spirits_types.liquor }} {{ getUserReviewsProfil.spirits_types.liquor === 0 || getUserReviewsProfil.spirits_types.liquor === 1 ? $t('ratings.rating_unitary') : $t('ratings.rating_multi') }} Liqueur & Aperitif</p>
        </div>
      </div>
    </div>
</template>
  
<script>
import { mapGetters } from 'vuex';
  
export default {
    props: {
      userReview: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        ranks: ['Newcomer', 'Enthusiast', 'Expert', 'Connoisseur', 'Curator', 'Master Taster', 'Legend']
      };
    },
    computed: {
      ...mapGetters({
        getUserReviewsProfil: 'getUserReviewsProfil'
      })
    }
  }
</script>
  
<style lang="scss">
@import '@/assets/styles/auth.scss';

.review-header {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  .review-header-info {
      display: flex;
      align-items: center;

      h4 {
          font-weight: 400;
          cursor: pointer;
      }
  }

  .review-header-likes {
      display: flex;
      align-items: center;
      font-size: 13px;

      img {
        margin-right: 5px;
      }
  }
}

.spirits-types-list {
  margin-top: 10px;
  
  div {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    img {
      margin-right: 8px;
    }
  }
}
</style>